import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
  name: 'displayAmount',
})
export class AmountPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string,
              private decimalPipe: DecimalPipe) {}

  transform(amount: number | undefined): string | undefined {
    if (amount == undefined) {
      return undefined;
    }
    if (amount == 0) {
      return amount.toString();
    }
    if (Math.abs(amount) >= 0.001 && Math.abs(amount) < 1000) {
      return amount.toPrecision(3);
    }
    if (Math.abs(amount) >= 1000 &&Math.abs(amount) < 100000) {
      return this.decimalPipe.transform(amount, '1.0-0', this.locale) || undefined;
    }
    return amount.toExponential(2);
  }
}
