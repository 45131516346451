import { createAction, props } from '@ngrx/store';
import {
  DatasetSearchFiltersOptions,
  DatasetSearchForm,
  DatasetSearchResult,
  Facet,
  SearchFilterOptionMap,
  Suggestion,
} from '../../models/dataset-search.model';

export const loadDatasetFilters = createAction(
  '[Search datasets] Load filters'
);

export const loadDatasetFiltersSuccess = createAction(
  '[Search datasets] Load filters success',
  props<{
    filterOptions: DatasetSearchFiltersOptions;
    filterOptionMap: SearchFilterOptionMap;
  }>()
);

export const loadDatasetFiltersError = createAction(
  '[Search datasets] Load filters error',
  props<{ message: string; error: unknown }>()
);

export const searchDatasets = createAction(
  '[Search datasets] Search',
  props<{ searchForm: DatasetSearchForm }>()
);

export const searchDatasetsSuccess = createAction(
  '[Search datasets] Search success',
  props<{
    form: DatasetSearchForm;
    results: DatasetSearchResult[];
    facets: Facet[];
    suggestions: Suggestion[];
    totalDocCount: number;
    maxDocCount: number;
  }>()
);

export const searchDatasetsError = createAction(
  '[Search datasets] Search error',
  props<{ message: string; error: unknown }>()
);

export const updateFiltersSuccess = createAction(
  '[Search datasets] Update filters success',
  props<{
    filters: DatasetSearchFiltersOptions;
  }>()
);

export const updateFiltersError = createAction(
  '[Search datasets] Update filters error',
  props<{ message: string; error: unknown }>()
);
