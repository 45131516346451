import { Injectable } from '@angular/core';
import { filter, from, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthService } from '@auth0/auth0-angular';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class Auth0ApiService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}
  changePwd(): Observable<string> {
    return from(this.authService.user$).pipe(
      map((user) => user?.email),
      filter((email): email is string => email !== undefined),
      take(1),
      switchMap((email) => this.sendChangePasswordRequest(email))
    );
  }
  sendChangePasswordRequest(email: string): Observable<string> {
    return this.httpClient.post(
      'https://' + environment.auth0.domain + '/dbconnections/change_password',
      {
        client_id: environment.auth0.clientId,
        email: email,
        connection: 'External',
      },
      { responseType: 'text' }
    );
  }
}
