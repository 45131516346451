<h2>Embedded Product</h2>
<div>
  <div>
    <mat-form-field>
      <mat-select
        placeholder="Embedded Product"
        [(ngModel)]="selectedProductType"
      >
        <mat-option *ngFor="let ep of embeddedProductTypes" [value]="ep">
          {{ ep | embeddedTypeLabel }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-datasets-toggles
    [withAllocationModeSelection]="!!this.dataset.activity"
    [withPercentageToggle]="selectedProductType !== undefined"

    [referenceAmount]=" dataset.referenceProduct.exchanges.toProduct.amount "
    [referenceUnit]=" dataset.referenceProduct.exchanges.toProduct.unit "

    [(allocationMode)]="allocationMode"
    [(showPercentages)]="showPercentages"
  ></app-datasets-toggles>
</div>

<app-embedded-product-exchanges
  class="exchanges"
  *ngIf="selectedProduct"
  [dataset]="dataset"
  [selectedProduct]="selectedProduct"
  [showPercentages]="showPercentages"
  [showActivity]="allocationMode === AllocationMode.WHOLE_ACTIVITY"
></app-embedded-product-exchanges>
