import { Pipe, PipeTransform } from '@angular/core';
import { HistoryStatus } from '../models/history.models';

@Pipe({
  name: 'historyStatusLabelForChild',
})
export class HistoryStatusLabelForChildPipe implements PipeTransform {
  transform(status: HistoryStatus): string {
    switch (status) {
      case HistoryStatus.INHERIT:
        return 'To';
      case HistoryStatus.MERGE:
        return 'Merged to';
      case HistoryStatus.SPLIT:
        return 'Split to';
      default:
        return '';
    }
  }
}
