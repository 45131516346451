import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';

@Component({
  selector: 'app-indicator-filter[options][value]',
  templateUrl: './indicator-filter.component.html',
})
export class IndicatorFilterComponent {
  @Input()
  options!: Array<MethodIndicator> | null;

  @Input()
  set value(newValue: MethodIndicator | null) {
    if(newValue != null) {
      this.indicatorFilterCtrl.setValue(newValue?.id)
      this.valueChange.emit(newValue);
    }
  }

  @Output()
  valueChange = new EventEmitter<MethodIndicator | null>();
  indicatorFilterCtrl = new FormControl<string | null>(null);

  onValueChange(): void {
    const indicator = this.options?.find(
      (option) => option.id === this.indicatorFilterCtrl.value
    );
    this.valueChange.emit(indicator);
  }
}
