import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';
import {Dataset, hasEmbeddedProducts} from '@app/modules/dataset/models/dataset.model';
import {map, takeUntil} from 'rxjs/operators';
import {
  ContributionSelection
} from '@app/modules/dataset/modules/contribution-selection/model/contribution-selection.model';
import {DatasetService} from '@app/modules/dataset/services/dataset.service';
import {PageComponent} from '@app/modules/core/components/page-component';
import {RumService} from '@app/modules/rum/rum.service';
import {MethodIndicator} from '@app/modules/reference-data/modules/method/models/method.model';
import {MatTabChangeEvent} from "@angular/material/tabs";

@Component({
  templateUrl: './dataset-detail.component.html',
  styleUrl: './dataset-detail.component.scss',
})
export class DatasetDetailComponent
  implements PageComponent, OnInit, OnDestroy {
  readonly pageName = 'dataset-detail';

  dataset$: Observable<Dataset> = EMPTY;

  private indicator$ = new BehaviorSubject<MethodIndicator | null>(null);
  private onDestroy$ = new Subject<void>();
  protected activeTab$ = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private datasetService: DatasetService,
    private rumService: RumService,
  ) {
  }

  ngOnInit(): void {
    const datasetId$ = this.route.paramMap.pipe(
      map((params) => params.get('datasetId')),
      filter((datasetId): datasetId is string => datasetId !== null),
    );

    this.dataset$ = combineLatest([
      datasetId$.pipe(distinctUntilChanged()),
      this.indicator$.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([datasetId, indicator]) => {
        return indicator !== null
          ? this.datasetService.getDatasetWithContribution(
            datasetId,
            indicator.id,
          )
          : this.datasetService.getDataset(datasetId);
      }),
    );

    this.dataset$.pipe(takeUntil(this.onDestroy$),
      distinctUntilChanged((ds1: Dataset, ds2: Dataset) => ds1.id === ds2.id))
      .subscribe(dataset => this.rumService.triggerDatasetViewEvent(dataset)
      );
  }

  onContributionSelectionChange(selection: ContributionSelection): void {
    this.indicator$.next(selection.indicator ?? null);
  }

  onTabChange(event: MatTabChangeEvent, dataset: Dataset): void {
    this.activeTab$.next(event.tab.textLabel);
    this.rumService.triggerTabChangeEvent(dataset, event.tab.textLabel);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  hasEmbeddedProducts(dataset: Dataset): boolean {
    return hasEmbeddedProducts(dataset);
  }
}
