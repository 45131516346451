import {Component, Input} from '@angular/core';
import {defaultSectionOptions, SectionOptions, Subtotal} from "@app/modules/dataset/models/dataset.model";
import {Assessment} from "@app/modules/dataset/models/exchange.model";

@Component({
  selector:
    '[exchangeSubtotal][subTotal][options]',
  templateUrl: './exchange-details-subtotal.component.html',
  styleUrl: './exchange-details-subtotal.component.scss',
})
export class ExchangeDetailsSubtotalComponent {
  constructor() {
  }

  @Input() subTotal: Subtotal | undefined;
  @Input() options: SectionOptions = defaultSectionOptions;

  getDisplayedAssessment(): Assessment[] {
    if (!this.subTotal) return [];
    return this.subTotal.assessments.filter(a => a.key == this.options.displayedKey);
  }

}
