import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { DatabaseService } from '@app/modules/reference-data/modules/database/services/database.service';
import { FilterOption } from '@app/modules/landing-page/components/landing-page-search-section/filter/filter.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-database-filter',
  templateUrl: './database-filter.component.html',
})
export class DatabaseFilterComponent implements OnInit {
  @Input()
  value: Array<string> = [];

  @Output()
  valueChange = new EventEmitter<Array<string>>();

  options$: Observable<Array<FilterOption>> = EMPTY;

  constructor(private databaseService: DatabaseService) {}

  ngOnInit(): void {
    this.options$ = this.databaseService
      .findLiveNonObsoleteDatabasesAndSort()
      .pipe(
        map((databases) =>
          databases.map((database) => ({
            value: database.key,
            label: database.displayName,
          }))
        )
      );
  }

  onValueChange(value: Array<string>): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }
}
