import { APP_INITIALIZER, NgModule } from '@angular/core';
import { IconService } from '@app/modules/core/services/icon.service';

@NgModule({
  providers: [
    IconService,
    {
      provide: APP_INITIALIZER,
      useFactory: (iconService: IconService) => () =>
        iconService.registerIcons(),
      deps: [IconService],
      multi: true,
    },
  ],
})
export class CoreModule {}
