<app-dataset-warning
  [datasetDescription]="dataset.description"
></app-dataset-warning>
<ng-container *ngIf="dataset.referenceProduct.assessmentGroups.length > 0; else noAssessment">
  <h2>Reference</h2>
  <app-select-search
    class="filter"
    size="wide"
    [multiple]="true"
    [control]="groupFilterCtrl"
    [list]="this.indicatorGroupsOptions"
    label="Indicator group"
    displayedField="value"
    valueField="value"
  ></app-select-search>
  <table>
    <tbody>
      <tr>
        <td class="label">Unit</td>
        <td>1 {{ dataset.referenceProduct.unit | formatUnit }}</td>
      </tr>
      <tr>
        <td class="label">Database</td>
        <td>
          {{ dataset.description.database.displayName }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="impact-factor">
    <h2>Impact factors</h2>
    <app-impact-factors-table
      [contributions]="contributions$ | async"
    ></app-impact-factors-table>
  </div>
</ng-container>
<ng-template #noAssessment>
  <div class="no-impact-factors">No impact factors</div>
</ng-template>
