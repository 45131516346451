import {
  Contribution,
  ContributionFamily,
} from '@app/modules/dataset/modules/contribution/model/contribution.model';

export function expandTree(
  contributions: Array<Contribution>,
  globalAmount: number | undefined
): Array<ContributionNode> {
  return contributions.map((c) => ({
    indicatorName: c.indicator.name,
    displayName: c.displayName,
    unit: c.indicator.unit,
    method: c.indicator.method,
    amount: c.amount ?? 0,
    type:
      globalAmount === undefined
        ? ContributionNodeType.ROOT
        : ContributionNodeType.ROW,
    globalAmount: globalAmount ?? c.amount ?? 0,
    children: expandContribution(c, globalAmount ?? c.amount ?? 0),
  }));
}

function expandContribution(
  contribution: Contribution,
  globalAmount: number
): Array<ContributionNode> {
  const inner = expandInnerContributions(
    contribution.innerContributions,
    globalAmount
  );
  const inF = expandInnerFamilies(contribution.innerFamilies, globalAmount);
  return [...inner, ...inF];
}

function expandInnerContributions(
  innerContributions: Array<Contribution>,
  globalAmount: number
): Array<ContributionNode> {
  return innerContributions.map((contribution) => ({
    indicatorName: contribution.displayName,
    displayName: contribution.displayName,
    amount: contribution.amount ?? 0,
    globalAmount: globalAmount,
    type: ContributionNodeType.ROW,
    children: expandContribution(
      contribution,
      globalAmount ?? contribution.amount ?? 0
    ),
  }));
}

function expandInnerFamilies(
  innerFamilies: Array<ContributionFamily>,
  globalAmount: number
): Array<ContributionNode> {
  return innerFamilies.map((family) => ({
    indicatorName: family.type,
    displayName: family.type,
    globalAmount: globalAmount,
    type: ContributionNodeType.KEY,
    children: expandTree(family.innerContributions, globalAmount),
  }));
}

export interface ContributionNodeRel extends ContributionNode {
  expandable: boolean;
  level: number;
}

export interface ContributionNode {
  indicatorName: string;
  displayName: string;
  type: ContributionNodeType;
  unit?: string;
  amount?: number;
  globalAmount: number;
  method?: string;
  children: Array<ContributionNode>;
}

export enum ContributionNodeType {
  ROOT,
  ROW,
  KEY,
}
