import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TreeDto } from '@app/api/__generated__/model/treeDto';
import { DatasetService } from '@app/modules/dataset/services/dataset.service';
import { BehaviorSubject, EMPTY, merge, Observable, of, switchMap } from 'rxjs';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { hasData } from '../../utils/sankey.utils';
import {ContributionType} from "@app/modules/dataset/modules/contribution/model/contribution.model";

@Component({
  selector:
    'app-sankey[datasetId][referenceProduct][indicator][contributionType]',
  templateUrl: './sankey.component.html',
  styleUrl: './sankey.component.scss',
})
export class SankeyComponent implements OnInit, OnChanges {
  @Input()
  datasetId!: string;

  @Input()
  referenceProduct!: string;

  @Input()
  indicator!: MethodIndicator;

  @Input()
  contributionType!: ContributionType;

  tree$: Observable<TreeDto | undefined> = EMPTY;
  hasData = hasData;

  private onChanges$ = new BehaviorSubject<void>(undefined);

  constructor(private datasetService: DatasetService) {}

  ngOnInit(): void {
    this.tree$ = this.onChanges$.pipe(
      switchMap(() =>
        merge(
          of(undefined),
          this.datasetService.getContributionTree(
            this.datasetId,
            this.contributionType.name,
            this.indicator.id,
          ),
        ),
      ),
    );
  }

  ngOnChanges(): void {
    this.onChanges$.next();
  }

  formatNodeName = (name: string, maxCharacters: number): string => {
    const geography = this.extractGeography(name);
    const maxNameCharacters = Math.max(
      maxCharacters - geography.countryCode.length - 1,
      0,
    );
    return (
      geography.textBefore.substring(0, maxNameCharacters) +
      ' ' +
      geography.countryCode
    );
  };

  private extractGeography(name: string): {
    textBefore: string;
    countryCode: string;
  } {
    const matches = name.match(/^(.*)\{(.*)\}.*/);
    return matches !== null
      ? {
          textBefore: matches[1] ?? '',
          countryCode: matches[2] ?? '',
        }
      : {
          textBefore: name,
          countryCode: '',
        };
  }
}
