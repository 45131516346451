<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>

<div mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial (click)="onDismiss()">Cancel</button>
  <button mat-button mat-raised-button (click)="onConfirm()">Confirm</button>
</div>
