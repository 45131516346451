import { Pipe, PipeTransform } from '@angular/core';
import { TechnologyLevel } from '../../../models/dataset.model';

@Pipe({
  name: 'labelTechnologyLevel',
})
export class LabelTechnologyLevelPipe implements PipeTransform {
  transform(level: TechnologyLevel | undefined): unknown {
    switch (level) {
      case TechnologyLevel.UNDEFINED:
        return 'Undefined';
      case TechnologyLevel.NEW:
        return 'New';
      case TechnologyLevel.MODERN:
        return 'Modern';
      case TechnologyLevel.CURRENT:
        return 'Current';
      case TechnologyLevel.OLD:
        return 'Old';
      case TechnologyLevel.OUTDATED:
        return 'Outdated';
      default:
        return '';
    }
  }
}
