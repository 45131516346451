import {Injectable} from '@angular/core';
import {
  biosphereHeaderTemplate,
  ExchangeHeader,
  productHeaderTemplate,
  technosphereHeaderTemplate
} from "@app/modules/dataset/models/exchange.model";
import {Exchange} from "@app/modules/dataset/modules/exchange/models/exchange.model";
import {EmbeddedProduct} from "@app/modules/dataset/models/embedded.product.model";

@Injectable({
  providedIn: 'root',
})
export class ExchangeHeaderMapperService {
  constructor() {
  }

  public getContributionNames(exchange: Exchange,
                              methodIndicatorId: string | undefined): string[] {
    if (!methodIndicatorId) return [];
    return exchange.contributions.filter(c => c.indicator.id == methodIndicatorId).map(c => c.indicator.name);
  }

  public getReferenceProductHeader(contributionNames: string[], embeddedProducts: EmbeddedProduct[]): ExchangeHeader {
    return {
      ...productHeaderTemplate,
      title: "Reference Product",
      assessments: contributionNames,
      embedded: embeddedProducts.map(ep => ep.type),
    };
  }

  public getCoProductHeader(contributionNames: string[], embeddedProducts: EmbeddedProduct[]): ExchangeHeader {
    return {
      ...productHeaderTemplate,
      title: "Co-products",
      assessments: contributionNames,
      embedded: embeddedProducts.map(ep => ep.type),
    };
  }

  public getFromTechnosphereHeader(contributionNames: string[], embeddedProducts: EmbeddedProduct[]): ExchangeHeader {
    return {
      ...technosphereHeaderTemplate,
      title: "From technosphere",
      assessments: contributionNames,
      embedded: embeddedProducts.map(ep => ep.type)
    };
  }

  public getToTechnosphereHeader(contributionNames: string[]): ExchangeHeader {
    return {
      ...technosphereHeaderTemplate,
      title: "To technosphere",
      assessments: contributionNames,
      embedded:[],
    };
  }

  public getFromBiosphereHeader(contributionNames: string[]): ExchangeHeader {
    return {
      ...biosphereHeaderTemplate,
      title: "From biosphere",
      assessments: contributionNames,
      embedded: [],
    };
  }

  public getToBiosphereHeader(contributionNames: string[]): ExchangeHeader {
    return {
      ...biosphereHeaderTemplate,
      title: "To biosphere",
      assessments: contributionNames,
      embedded: [],
    };
  }
}
