<div fxFlex [ngClass]="pageName">
  <section fxFill fxLayout="column">
    <header>
      <app-toolbar></app-toolbar>
    </header>
    <main fxFlex>
      <div class="main-overlay">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
    </main>
    <footer>
      <app-footer></app-footer>
    </footer>
  </section>
</div>
