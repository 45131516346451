import {Injectable} from '@angular/core';
import {DatasetDto} from '@app/api/__generated__/model/datasetDto';
import {HistoryDto} from '@app/api/__generated__/model/historyDto';
import {AbstractMapper} from '@app/modules/core/mappers/abstract-mapper';
import {IndicatorMapperService} from '@app/modules/dataset/mappers/indicator/indicator-mapper.service';
import {ExchangeMapperService} from '@app/modules/dataset/modules/exchange/mappers/exchange-mapper.service';
import {ParentMapperService} from '@app/modules/dataset/modules/parent/components/mappers/parent-mapper.service';
import {Activity, Dataset, ExchangeTable, ReferenceProduct} from '../../models/dataset.model';
import {History, HistoryDetail, HistoryStatus} from '../../modules/history/models/history.models';
import {AssessmentMapperService} from '../assessment/assessment-mapper.service';
import {DatasetDescriptionMapperService} from '../dataset-description/dataset-description-mapper.service';
import {ContributionMapperService} from "@app/modules/dataset/modules/contribution/mappers/contribution-mapper.service";
import {HistoryDetailDto} from "@app/api/__generated__/model/historyDetailDto";
import {
  ExchangeRecordMapperService
} from "@app/modules/dataset/modules/exchange/mappers/exchange-record-mapper.service";
import {
  ExchangeHeaderMapperService
} from "@app/modules/dataset/modules/exchange/mappers/exchange-header-mapper.service";
import {Exchanges} from "@app/modules/dataset/modules/exchange/models/exchange.model";
import {
  ExchangeSectionMapperService
} from "@app/modules/dataset/modules/exchange/mappers/exchange-section-mapper.service";

@Injectable({
  providedIn: 'root',
})
export class DatasetMapperService extends AbstractMapper<DatasetDto, Dataset> {
  constructor(
    private readonly ddMapper: DatasetDescriptionMapperService,
    private readonly indMapper: IndicatorMapperService,
    private readonly exchangeMapper: ExchangeMapperService,
    private readonly parentMapper: ParentMapperService,
    private readonly contributionMapper: ContributionMapperService,
    private readonly assessmentGroupMapper: AssessmentMapperService,
    private readonly exchangeRecordMapperService: ExchangeRecordMapperService,
    private readonly exchangeHeaderMapperService: ExchangeHeaderMapperService,
    private readonly exchangeSectionMapperService: ExchangeSectionMapperService,
  ) {
    super();
  }


  from(dto: DatasetDto): Dataset {
    return this.fromWithContributions(dto, undefined);
  }

  private mapHistory(dto: HistoryDto): History {
    return new History(
      dto.parentDatabase,
      dto.parents.map(d => this.mapHistoryDeTails(d)),
      dto.childDatabase,
      dto.children.map(d => this.mapHistoryDeTails(d)),
      dto.equivalentVersions.map(d => this.mapHistoryDeTails(d)),
    );
  }

  private mapHistoryDeTails(dto: HistoryDetailDto): HistoryDetail {
    return {
      database: dto.database,
      product: dto.product,
      productId: dto.productId,
      impactIndicatorSamples: dto.impactIndicatorSamples,
      status: dto.status as HistoryStatus,
    }
  }

  fromWithContributions(dto: DatasetDto, indicatorId: string | undefined): Dataset {
    const {
      id,
      description,
      impactIndicatorSamples,
    } = dto;
    const productExchanges = this.exchangeMapper.toReferenceProductExchanges(dto.referenceProduct.allocatedQuantities, dto.referenceProduct, dto.coProducts, dto.id);
    const contributionNames = this.exchangeHeaderMapperService.getContributionNames(productExchanges.toProduct, indicatorId);
    const referenceProductRecord = this.exchangeRecordMapperService.getReferenceProductRecord(productExchanges.toProduct, indicatorId, productExchanges.embeddedProducts);
    const referenceProduct: ReferenceProduct = {
      ...this.getExchangeTable(contributionNames, productExchanges, indicatorId),
      name: dto.referenceProduct.name,
      unit: dto.referenceProduct.unit,
      referenceProductSection: {
        header: this.exchangeHeaderMapperService.getReferenceProductHeader(contributionNames, productExchanges.embeddedProducts),
        records: [referenceProductRecord],
      },
      coProductSection: {
        header: this.exchangeHeaderMapperService.getCoProductHeader([], []),
        records: this.exchangeRecordMapperService.getCoproducts(productExchanges.toProduct, indicatorId, []),
      },
      assessmentGroups: this.assessmentGroupMapper.asGroups(dto.referenceProduct.allocatedQuantities.contributions),
      exchanges: productExchanges
    };

    let activity: Activity | undefined = undefined;
    // Show activity only if not ecoinvent and it will show something different from the referenceProduct
    const hideActivity = (dto.description.database.name === 'ecoinvent') || (dto.referenceProduct.amount === 1 && dto.coProducts.length <= 0);
    if (dto.activity?.allocatedQuantities && !hideActivity) {
      const contributionsDto = dto.activity.allocatedQuantities.contributions;
      const activityExchanges = this.exchangeMapper.toActivityExchanges(dto.activity.allocatedQuantities, dto.referenceProduct, dto.coProducts, dto.id);
      activity = {
        ...this.getExchangeTable(contributionNames, activityExchanges, indicatorId),
        assessmentGroups: this.assessmentGroupMapper.asGroups(dto.activity.allocatedQuantities.contributions),
        exchanges: activityExchanges,
        contributions: contributionsDto.map(c => this.contributionMapper.from(c)),
        flattenContributions: this.contributionMapper.flattenFromList(
          contributionsDto
        ),
        coProductSection: this.exchangeSectionMapperService
          .getActivityCoProductSection(contributionNames, indicatorId, activityExchanges),
      };
    }

    return {
      id,
      description: this.ddMapper.from(description),
      impactIndicatorSamples: impactIndicatorSamples?.map((sample) =>
        this.indMapper.mapIndicatorSample(sample)
      ),
      referenceProduct: referenceProduct,
      activity: activity,
      parents: this.parentMapper.from(dto.parents),
      flattenContributions:
        this.contributionMapper.flattenFromList(dto.referenceProduct.allocatedQuantities.contributions),
      history: this.mapHistory(dto.history),
      isFlag: dto.isFlag,
      isLuc: dto.isLuc,
    };
  }

  private getExchangeTable(contributionNames: string[], exchanges: Exchanges, indicatorId: string | undefined): ExchangeTable {
    return {
      toTechnosphereSection: this.exchangeSectionMapperService
        .getToTechnosphereSection(contributionNames, indicatorId, exchanges.toTechnosphere),
      fromTechnosphereSection: this.exchangeSectionMapperService
        .getFromTechnosphereSection(contributionNames, indicatorId, exchanges.fromTechnosphere, exchanges.embeddedProducts),
      fromBiosphereSection: this.exchangeSectionMapperService
        .getFromBiosphereSection(contributionNames, indicatorId, exchanges.fromBiosphere),
      toBiosphereSection: this.exchangeSectionMapperService
        .getToBiosphereSection(contributionNames, indicatorId, exchanges.toBiosphere),
    };
  }
}
