<ng-container *ngIf="useDummy">
  <app-warn
    toolTip="Connected to dummy - the upstream supply chain shall be taken into account separately"
    [text]="
      displayText
        ? 'Connected to a dummy dataset - the upstream supply chain shall be taken into account separately'
        : ''
    "
  >
  </app-warn>
</ng-container>
<ng-container *ngIf="isDummy">
  <app-warn
    toolTip="Dummy dataset - to be taken into account separately"
    [text]="
      displayText ? 'Dummy dataset - to be taken into account separately' : ''
    "
  >
  </app-warn>
</ng-container>
<ng-container *ngIf="isAcs">
  <app-warn
    toolTip="Do not use for corporate footprints. Contains carbon sequestration - not compatible with GHG protocol"
    [text]="
      displayText
        ? 'Do not use for corporate footprints. This dataset takes into account agroforestry carbon sequestration as part of the climate change emissions, it is not compatible with the GHG protocol.'
        : ''
    "
  >
  </app-warn>
</ng-container>
<ng-container *ngIf="useAcs">
  <app-warn
    toolTip="Do not use for corporate footprints. Carbon sequestration taken into account - not compatible with GHG protocol"
    [text]="
      displayText
        ? 'Do not use for corporate footprints. This dataset contains a dataset (Cocoa beans, sun-dried, agroforestry, at farm, for any geography) taking into account agroforestry carbon sequestration as part of the climate change emissions. It is not compatible with the GHG protocol.'
        : ''
    "
  >
  </app-warn>
</ng-container>
