/* eslint-disable
 @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { SuggestionSelection } from '@app/modules/dataset/models/dataset-search.model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchSuggestionMapperService {
  toQueryParams(
    suggestionSelection: SuggestionSelection,
    queryParams: Params
  ): Params {
    return {
      ...queryParams,
      q:
        queryParams.q
          ?.toLowerCase()
          .replace(
            suggestionSelection.originalText.toLowerCase(),
            suggestionSelection.suggestion
          ) ?? '',
    };
  }
}
