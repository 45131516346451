import {
  Component,
  EventEmitter, Input, OnInit,
  Output,
} from '@angular/core';
import {
  ContributionSelection,
  emptySelection, resetContributionType, resetGroup, resetIndicator, selectContribution
} from "@app/modules/dataset/modules/contribution-selection/model/contribution-selection.model";
import {
  IndicatorGroupService
} from "@app/modules/reference-data/modules/indicator-group/services/indicator-group-service";
import {IndicatorGroup} from "@app/modules/reference-data/modules/indicator-group/models/indicator-group.model";
import {Subject, take} from "rxjs";
import {MethodIndicator} from "@app/modules/reference-data/modules/method/models/method.model";
import {
  ContributionType,
  ContributionTypeName, DIRECT_TYPE,
} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {Dataset} from "@app/modules/dataset/models/dataset.model";
import {
  exactMethodIndicatorMatching, extractContributionTypeMatchingIndicator,
  extractIndicatorGroupsMatchingAssessmentGroups
} from "@app/modules/dataset/utils/dataset.utils";

@Component({
  selector: 'app-contribution-filters[dataset]',
  templateUrl: './contribution-filters.component.html',
  styleUrl: './contribution-filters.component.scss',
})
export class ContributionFiltersComponent implements OnInit {
  selection: ContributionSelection = emptySelection();
  optionGroups: Array<IndicatorGroup> | undefined;
  filteredOptionGroups : Array<IndicatorGroup> = [];
  optionIndicators$: Subject<Array<MethodIndicator>> = new Subject<MethodIndicator[]>();
  optionContributionsType$: Subject<Array<ContributionTypeName>> = new Subject<ContributionTypeName[]>();
  optionContributions$: Subject<Array<ContributionType>> = new Subject<ContributionType[]>();

  _dataset! : Dataset;
  @Input()
  set dataset(ds: Dataset) {
    if(this._dataset?.id !== ds.id) {
      this._dataset = ds;
      if(this.optionGroups !== undefined) {
        this.resetOptions(this.optionGroups);
      }
    }
  }

  @Output()
  contributionChanged = new EventEmitter<ContributionSelection>();

  constructor(private indicatorGroupService: IndicatorGroupService) {
  }

  ngOnInit(): void {
      this.indicatorGroupService.getIndicatorGroups()
      .pipe(take(1))
      .subscribe(optionGroups => {
        this.optionGroups = optionGroups;
        this.resetOptions(this.optionGroups);
      });
  }

  onIndicatorGroupChange(indicatorGroup: IndicatorGroup | null): void {
    const matchingIndicators = indicatorGroup ? exactMethodIndicatorMatching(indicatorGroup.name, this._dataset.referenceProduct.assessmentGroups) : null;
    if (matchingIndicators != null) {
      this.optionIndicators$.next(matchingIndicators);
    }
    this.selection = resetGroup(indicatorGroup);
    this.contributionChanged.emit(this.selection);

    if (matchingIndicators != null) {
      const currentIndicator = this.selection.indicator;
      if(matchingIndicators.length > 0) {
        if(currentIndicator === null || matchingIndicators.find(i => i.id === currentIndicator?.id) === undefined) {
          this.onIndicatorChange(matchingIndicators[0]);
        }
      }
    }
  }


  onIndicatorChange(methodIndicator: MethodIndicator | null): void {
    if (methodIndicator !== null) {
      this.optionContributionsType$.next(extractContributionTypeMatchingIndicator(methodIndicator, this._dataset.flattenContributions))
    }
    this.selection = resetIndicator(this.selection, methodIndicator);
    this.onContributionTypeChange('DIRECT');
  }

  onContributionTypeChange(type: ContributionTypeName | null): void {

    if (type !== null && this.selection.indicator !== null) {
      this.optionContributions$.next([DIRECT_TYPE, ...this.selection.indicator.findContributionTypesByFamily(type)]);
    }
    this.selection = resetContributionType(this.selection, type);
    this.onContributionNameChange(DIRECT_TYPE);
  }

  onContributionNameChange(contribution: ContributionType | null): void {
    this.selection = selectContribution(this.selection, contribution);
    this.contributionChanged.emit(this.selection);
  }

  private resetOptions(optionGroups : Array<IndicatorGroup>): void {
    this.filteredOptionGroups = extractIndicatorGroupsMatchingAssessmentGroups(optionGroups, this._dataset.referenceProduct.assessmentGroups);
    const contributionTypes : Array<ContributionTypeName> = extractContributionTypeMatchingIndicator(this.selection.indicator, this._dataset.flattenContributions);
    if(this.filteredOptionGroups.find(opt => opt.name === this.selection.group?.name) === undefined ||
      contributionTypes.find(cont => cont === this.selection.contributionType) === undefined) {
      this.onIndicatorGroupChange(this.filteredOptionGroups[0]);
    }
  }
}
