<div *ngIf="warnings.includes('DUMMY')">
  <app-warn
    class="warn-icon"
    toolTip="Dummy dataset - to be taken into account separately"
  >
  </app-warn>
</div>
<div *ngIf="warnings.includes('USE_DUMMY')">
  <app-warn
    class="warn-icon"
    toolTip="Connected to a dummy dataset - the upstream supply chain shall be taken into account separately"
  >
  </app-warn>
</div>
<div *ngIf="warnings.includes('AGROFORESTRY_CARBON_SEQUESTRATION')">
  <app-warn
    class="warn-icon"
    toolTip="Do not use for corporate footprints.
        This dataset takes into account agroforestry carbon sequestration as part of the climate change emissions, it is not compatible with the GHG protocol."
  >
  </app-warn>
</div>
<div *ngIf="warnings.includes('USE_AGROFORESTRY_CARBON_SEQUESTRATION')">
  <app-warn
    class="warn-icon"
    toolTip="Do not use for corporate footprints.
        This dataset contains a dataset (Cocoa beans, sun-dried, agroforestry, at farm, for any geography) taking into account agroforestry carbon sequestration as part of the climate change emissions.
        It is not compatible with the GHG protocol."
  >
  </app-warn>
</div>
