import { Component } from '@angular/core';
import { PageComponent } from '@app/modules/core/components/page-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  pageName = '';

  onActivate($event: PageComponent): void {
    this.pageName = $event.pageName;
  }
}
