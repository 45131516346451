/**
 * Data Platform Api Documentation
 * Data Platform Api Documentation
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FieldDto = 'DESCRIPTION' | 'EXCHANGES' | 'HISTORY' | 'IMPACTS' | 'COPRODUCTS' | 'PARENTS';

export const FieldDto = {
    Description: 'DESCRIPTION' as FieldDto,
    Exchanges: 'EXCHANGES' as FieldDto,
    History: 'HISTORY' as FieldDto,
    Impacts: 'IMPACTS' as FieldDto,
    Coproducts: 'COPRODUCTS' as FieldDto,
    Parents: 'PARENTS' as FieldDto
};

