import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatabasesDto } from '@app/api/__generated__/model/databasesDto';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { DatabasesServiceInterface } from '@app/api/__generated__/api/databases.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseApiService implements DatabasesServiceInterface {
  private readonly databasesUrl = environment.apiUrl + '/databases';

  constructor(private httpClient: HttpClient) {}

  getDatabases(): Observable<DatabasesDto> {
    return this.httpClient.get<DatabasesDto>(this.databasesUrl);
  }
}
