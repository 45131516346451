import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-exchange-warning[warnings]',
  templateUrl: './exchange-warning.component.html',
})
export class ExchangeWarningComponent {
  @Input()
  warnings!: string[];
}
