import {Injectable} from '@angular/core';
import {FieldDto} from '@app/api/__generated__/model/fieldDto';
import {TreeDto} from '@app/api/__generated__/model/treeDto';
import {DatasetApiService} from '@app/modules/dataset/api/dataset-api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DatasetSearchResultMapperService} from '../mappers/dataset-search-result/dataset-search-result-mapper.service';
import {DatasetMapperService} from '../mappers/dataset/dataset-mapper.service';
import {DatasetSearchResult} from '../models/dataset-search.model';
import {Dataset} from '../models/dataset.model';

@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  constructor(
    private readonly datasetApiService: DatasetApiService,
    private readonly datasetMapper: DatasetMapperService,
    private readonly datasetSearchResultMapperService: DatasetSearchResultMapperService,
  ) {}

  getDataset(id: string): Observable<Dataset> {
    return this.datasetApiService
      .getDataset(id)
      .pipe(map((dataset) => this.datasetMapper.from(dataset)));
  }

  getDatasetWithContribution(
    id: string,
    indicatorId: string,
  ): Observable<Dataset> {
    return this.datasetApiService
      .getDatasetWithContribution(id, [indicatorId])
      .pipe(map((dataset) => this.datasetMapper.fromWithContributions(dataset, indicatorId)));
  }

  getContributionTree(
    id: string,
    contributionName: string,
    indicatorKeyId?: string,
  ): Observable<TreeDto> {
    return this.datasetApiService.getContributionTree(
      id,
      contributionName,
      indicatorKeyId,
    );
  }

  getDatasets(
    ids?: string[] | undefined,
    names?: string[] | undefined,
    database?: string | undefined,
    fields?: FieldDto[] | undefined,
  ): Observable<DatasetSearchResult[]> {
    return this.datasetApiService
      .getDatasets({ ids, names, database, fields })
      .pipe(
        map((dto) => dto.datasets),
        map((datasets) =>
          this.datasetSearchResultMapperService.fromDatasetDtoList(datasets),
        ),
      );
  }
}
