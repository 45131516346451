import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';

export const SUB_COMPARTMENTS = new Map<SubCompartmentDto, string>([
  ['AGRICULTURAL', 'agricultural'],
  ['BIOTIC', 'biotic'],
  ['FORESTRY', 'forestry'],
  ['GROUNDWATER', 'groundwater'],
  ['GROUNDWATER_LONG_TERM', 'groundwater, long-term'],
  ['HIGH_POP', 'high. pop.'],
  ['INDOOR', 'indoor'],
  ['IN_AIR', 'in air'],
  ['IN_GROUND', 'in ground'],
  ['IN_WATER', 'in water'],
  ['INDUSTRIAL', 'industrial'],
  ['LAKE', 'lake'],
  ['LAND', 'land'],
  ['LOW_POP', 'low. pop.'],
  ['LOW_POP_LONG_TERM', 'low. pop., long-term'],
  ['OCEAN', 'ocean'],
  ['RIVER', 'river'],
  ['RIVER_LONG_TERM', 'river, long-term'],
  ['STRATOSPHERE_TROPOSPHERE', 'stratosphere + troposphere'],
  ['UNSPECIFIED', '(unspecified)'],
]);
