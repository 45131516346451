import { Component, Input } from '@angular/core';
import { Contribution } from '@app/modules/dataset/modules/contribution/model/contribution.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  ContributionNode,
  ContributionNodeRel,
  ContributionNodeType,
  expandTree,
} from './contribution-tree.utils';

@Component({
  selector: 'app-impact-factors-table[contributions]',
  templateUrl: './impact-factors-table.component.html',
  styleUrl: './impact-factors-table.component.scss',
})
export class ImpactFactorsTableComponent {
  displayedColumns: string[] = [
    'Impact category',
    'Emission factor',
    'Unit',
    'Method',
    'warning',
  ];

  hasInnerContributions = false;

  @Input('contributions')
  set setContributions(contributions: Array<Contribution> | null) {
    this.dataSource.data = expandTree(contributions ?? [], undefined);
    this.hasInnerContributions =
      this.dataSource.data.filter((c) => c.children.length > 0).length > 0;
  }

  private transformer = (
    contribution: ContributionNode,
    level: number,
  ): ContributionNodeRel => {
    return {
      ...contribution,
      expandable: contribution.children.length > 0,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ContributionNodeRel>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  protected isWaterUse(contribution: ContributionNodeRel): boolean {
    return (
      contribution.indicatorName === 'Water use' &&
      (contribution.method?.startsWith('EF') ?? false)
    );
  }

  percentage(row: ContributionNodeRel): number {
    return ((row?.amount ?? 0) / row.globalAmount) * 100;
  }

  isChild(row: ContributionNodeRel): boolean {
    return row.level > 0 && row.amount !== undefined;
  }

  isKey(row: ContributionNodeRel): boolean {
    return row.type === ContributionNodeType.KEY;
  }

  isRootWithChildren(row: ContributionNodeRel): boolean {
    return row.level === ContributionNodeType.ROOT.valueOf() && row.expandable;
  }

  tooltip(row: ContributionNodeRel): string {
    switch (row.type) {
      case ContributionNodeType.KEY:
      case ContributionNodeType.ROOT:
        return '100%';
      case ContributionNodeType.ROW:
        return this.percentage(row).toFixed(2) + '%';
    }
  }
}
