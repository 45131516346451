import { Injectable } from '@angular/core';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { EmbeddedProductDto } from '@app/api/__generated__/model/embeddedProductDto';
import { EmbeddedProduct } from '@app/modules/dataset/models/embedded.product.model';

@Injectable({
  providedIn: 'root',
})
export class EmbeddedProductMapperService extends AbstractMapper<
  EmbeddedProductDto,
  EmbeddedProduct
> {
  constructor() {
    super();
  }

  from(dto: EmbeddedProductDto): EmbeddedProduct {
    return {
      type: dto.type,
      unit: dto.unit,
      amount: dto.amount,
    };
  }
}
