import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isBlank } from '@app/modules/core/utils/string-utils';
import { defaultDatasetSearchFilters } from '@app/modules/dataset/models/dataset-search.model';

@Component({
  selector: 'app-landing-page-search-section',
  templateUrl: './landing-page-search-section.component.html',
  styleUrl: './landing-page-search-section.component.scss',
})
export class LandingPageSearchSectionComponent {
  databases = defaultDatasetSearchFilters().databases;
  geographies = defaultDatasetSearchFilters().geographies;
  suggestions = [
    'cotton',
    'wheat',
    'cocoa',
    'electricity',
    'heat',
    'transport',
    'milk',
    'silk',
  ];

  constructor(private router: Router) {}

  onSearch(q: string): void {
    if (isBlank(q)) {
      return;
    }
    void this.router.navigate(['datasets'], {
      queryParams: {
        q,
        database: this.databases,
        geography: this.geographies,
      },
    });
  }
}
