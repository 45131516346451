<app-landing-page-section>
  <app-landing-page-section-content>
    <header class="mat-headline-5">What's new</header>
    <app-landing-page-section-content-two-columns>
      <ng-container col1>
        <img
          ngSrc="assets/images/whats-new.png"
          alt=""
          height="400"
          width="400"
        />
      </ng-container>
      <ng-container col2>
        <p class="mat-headline-6">
          Explore our release notes to stay informed about the latest
          developments
        </p>
        <p>
          Discover insights into key features, practical tips to enhance your
          experience, and updates on our roadmap.
        </p>
        <p>
          See how we're continuously evolving to help you meet industry
          standards and achieve sustainability goals.
        </p>
        <a
          mat-raised-button
          color="primary"
          href="https://page.quantis.com/eqosphere-updates"
          target="_blank"
          ><strong>Read release notes</strong></a
        >
      </ng-container>
    </app-landing-page-section-content-two-columns>
  </app-landing-page-section-content>
</app-landing-page-section>
