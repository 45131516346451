import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ParentTabComponent } from '@app/modules/dataset/modules/parent/components/parent-tab/parent-tab.component';
import { ParentMapperService } from '@app/modules/dataset/modules/parent/components/mappers/parent-mapper.service';

@NgModule({
  declarations: [ParentTabComponent],
  imports: [CommonModule, RouterModule],
  providers: [ParentMapperService],
  exports: [ParentTabComponent],
})
export class ParentModule {}
