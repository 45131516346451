<h2>
  Previous database version
  <span class="database-header">({{ history.parentDatabase }})</span>
</h2>
<table>
  <tbody>
    <tr *ngFor="let parent of history.parents">
      <td class="label">
        {{ parent.status | historyStatusLabelForParent }}
      </td>
      <td>
        <a [routerLink]="['/datasets', parent.productId]"
          >{{ parent.product }}
        </a>
        <app-impact-factor-sample-list
          [factorSamples]="parent.impactIndicatorSamples"
        ></app-impact-factor-sample-list>
      </td>
    </tr>
  </tbody>
</table>
