import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasketExportDialogComponent } from '@app/modules/basket/components/basket-export-dialog/basket-export-dialog.component';
import { BasketImportDialogComponent } from '@app/modules/basket/components/basket-import-dialog/basket-import-dialog.component';
import { UpgradeDatasetsDialogComponent } from '@app/modules/basket/components/update-datasets-dialog/upgrade-datasets-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BasketDialogService {
  constructor(private dialog: MatDialog) {}

  openImportDialog(): MatDialogRef<unknown> {
    return this.dialog.open(BasketImportDialogComponent, {
      width: '50%',
    });
  }

  openUpgradeDialog(): MatDialogRef<unknown> {
    return this.dialog.open(UpgradeDatasetsDialogComponent, { width: '50%' });
  }

  openExportDialog(): MatDialogRef<unknown> {
    return this.dialog.open(BasketExportDialogComponent);
  }
}
