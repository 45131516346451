import {
  DatasetSearchResult,
  defaultDatasetSearchFilters,
  Facet,
  SearchFilterOptionMap,
  Suggestion,
} from '@app/modules/dataset/models/dataset-search.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  DatasetSearchFiltersOptions,
  DatasetSearchForm,
} from '../../models/dataset-search.model';
import {
  loadDatasetFiltersSuccess,
  searchDatasets,
  searchDatasetsError,
  searchDatasetsSuccess,
  updateFiltersError,
  updateFiltersSuccess,
} from './dataset-search.actions';

export const datasetSearchFeatureKey = 'datasetSearch';

export interface DatasetSearchState {
  filtersLoaded: boolean;
  filtersOptions: DatasetSearchFiltersOptions;
  filterOptionMap: SearchFilterOptionMap;
  form: DatasetSearchForm;
  results: DatasetSearchResult[];
  facets: Facet[];
  suggestions: Suggestion[];
  totalDocCount: number;
  maxDocCount: number;
}

export const initialState: DatasetSearchState = {
  filtersLoaded: false,
  filterOptionMap: {},
  filtersOptions: {
    databases: [],
    geographies: [],
    activitiesTypes: [],
    units: [],
    isics: [],
  },
  form: {
    searchTerm: '',
    filters: defaultDatasetSearchFilters(),
  },
  results: [],
  facets: [],
  suggestions: [],
  totalDocCount: 0,
  maxDocCount: 0,
};

const reducer = createReducer(
  initialState,
  on(loadDatasetFiltersSuccess, (state, action) => ({
    ...state,
    filtersLoaded: true,
    filtersOptions: action.filterOptions,
    filterOptionMap: action.filterOptionMap,
  })),
  on(searchDatasets, (state, action) => ({
    ...state,
    form: action.searchForm,
  })),
  on(searchDatasetsSuccess, (state, action) => ({
    ...state,
    form: action.form,
    results: action.results,
    facets: action.facets,
    suggestions: action.suggestions,
    totalDocCount: action.totalDocCount,
    maxDocCount: action.maxDocCount,
  })),
  on(searchDatasetsError, (state, action) => {
    console.error(action.message, action.error);
    return state;
  }),
  on(updateFiltersSuccess, (state, action) => ({
    ...state,
    filtersOptions: action.filters,
  })),
  on(updateFiltersError, (state, action) => {
    console.error(action.message, action.error);
    return state;
  })
);

export function datasetSearchReducer(
  state: DatasetSearchState | undefined,
  action: Action
): DatasetSearchState {
  return reducer(state, action);
}
