import { createAction, props } from '@ngrx/store';
import {
  CharacterizationFactorSearchQuery,
  CharacterizationFactorSearchResult,
  CharacterizationFactorsSearchFiltersOptions,
} from '@app/modules/characterization-factor/models/characterization-factor-search.model';
import { HierarchicCompartment } from '@app/modules/reference-data/modules/substance/substance/models/hierarchic-compartment.model';
import { IndicatorGroup } from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';

export const loadCharacterizationFactorsFilters = createAction(
  '[Search characterization factors] Load filters'
);

export const loadCharacterizationFactorsFiltersSuccess = createAction(
  '[Search characterization factors] Load filters success',
  props<{
    filters: CharacterizationFactorsSearchFiltersOptions;
    allIndicatorGroups: IndicatorGroup[];
    allCompartments: HierarchicCompartment[];
  }>()
);

export const loadCharacterizationFactorsFiltersError = createAction(
  '[Search characterization factors] Load filters error',
  props<{ message: string; error: unknown }>()
);

export const updateCharacterizationFactorsSearchQuery = createAction(
  '[Search characterization factors] Update search query',
  props<{ searchForm: CharacterizationFactorSearchQuery }>()
);

export const searchCharacterizationFactorsSuccess = createAction(
  '[Search characterization factors] Search success',
  props<{ results: CharacterizationFactorSearchResult[] }>()
);

export const searchCharacterizationFactorsError = createAction(
  '[Search characterization factors] Search error',
  props<{ message: string; error: unknown }>()
);

export const updateCharacterizationFactorsFilterOptionsSuccess = createAction(
  '[Search characterization factors] Update filter options',
  props<{ filterOptions: CharacterizationFactorsSearchFiltersOptions }>()
);
