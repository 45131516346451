<tr>
  <td>{{ subTotal?.title }}</td>
  <td colspan="4">&nbsp;</td>
  <td
    *ngFor="let totalContribution of getDisplayedAssessment()"
    [ngClass]="['contribution']"
  >
    <mat-divider class="subtotal-bar"></mat-divider>
    <app-contribution-value
      [amount]="totalContribution.amount"
      [unit]="options.unit"
      [referenceAmount]="options.totalAssessment"
      [showPercentages]="options.showPercentages"
      [showBar]="!options.unit"
    ></app-contribution-value>
  </td>
  <td colspan="2"></td>
</tr>
