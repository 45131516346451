<table class="simple-table">
  <ng-container *ngIf="!showActivity">
    <tbody
      exchange-line-header
      [header]="dataset.referenceProduct.referenceProductSection.header"
      [quantityHeader]="selectedProduct.type | embeddedTypeLabel"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="dataset.referenceProduct.referenceProductSection.records"
      [options]="optionsWithUnit"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <ng-container *ngIf="hasCoProducts">
      <tbody
        exchange-line-header
        [header]="dataset.referenceProduct.coProductSection.header"
      ></tbody>
      <tbody
        exchange-line-record
        [records]="dataset.referenceProduct.coProductSection.records"
        [options]="optionsWithPercentageAndGreyed"
        (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
      ></tbody>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showActivity && dataset.activity">
    <tbody
      exchange-line-header
      [header]="dataset.activity.coProductSection.header"
      [quantityHeader]="selectedProduct.type | embeddedTypeLabel"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="dataset.activity.coProductSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      exchangeSubtotal
      [subTotal]="dataset.activity.coProductSection.subtotal"
      [options]="optionsWithUnit"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="activeProduct.fromTechnosphereSection.records.length>0">
    <tbody
      exchange-line-header
      [header]="dataset.referenceProduct.fromTechnosphereSection.header"
      [quantityHeader]="selectedProduct.type | embeddedTypeLabel"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="activeProduct.fromTechnosphereSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
  </ng-container>

</table>
