<div>
  <span class="warn-color" *ngIf="warnings.includes('DUMMY')">
    - Dummy dataset
  </span>
  <span class="warn-color" *ngIf="warnings.includes('USE_DUMMY')">
    - Connected to a dummy dataset
  </span>
  <span
    class="warn-color"
    *ngIf="warnings.includes('AGROFORESTRY_CARBON_SEQUESTRATION')"
  >
    - Do not use for corporate footprints. Carbon sequestration taken into
    account - Not compatible with GHG protocol
  </span>
  <span
    class="warn-color"
    *ngIf="warnings.includes('USE_AGROFORESTRY_CARBON_SEQUESTRATION')"
  >
    - Do not use for corporate footprints. Carbon sequestration taken into
    account - Not compatible with GHG protocol
  </span>
</div>
