import { DatasetSearchResult } from '@app/modules/dataset/models/dataset-search.model';
import { AppState } from '@app/store';
import { Dictionary } from '@ngrx/entity';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  adapter,
  datasetBasketFeatureKey,
  DatasetBasketState,
} from './basket-dataset.reducer';

export const selectBasketState = createFeatureSelector<
  AppState,
  DatasetBasketState
>(datasetBasketFeatureKey);

export const { selectAll, selectTotal, selectEntities, selectIds } =
  adapter.getSelectors(selectBasketState);

export const selectDatasetById = (
  id: string
): MemoizedSelector<AppState, DatasetSearchResult | undefined> =>
  createSelector(
    selectEntities,
    (datasets: Dictionary<DatasetSearchResult>) => datasets[id]
  );

export const selectDatasetsIds = (): MemoizedSelector<AppState, string[]> =>
  createSelector(selectIds, (ids) => ids as string[]);

export const isInBasket = (id: string): MemoizedSelector<AppState, boolean> =>
  createSelector(
    selectEntities,
    (entities: Dictionary<DatasetSearchResult>) => !!entities[id]
  );
