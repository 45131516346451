import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpactFactorSampleListComponent } from './components/impact-factor-sample-list/impact-factor-sample-list.component';
import { ReferenceDataModule } from '@app/modules/reference-data/reference-data.module';

@NgModule({
  declarations: [ImpactFactorSampleListComponent],
  imports: [CommonModule, ReferenceDataModule],
  exports: [ImpactFactorSampleListComponent],
})
export class ImpactFactorModule {}
