<mat-form-field class="select-autocomplete" [class]="size">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [formControl]="control"
    [multiple]="multiple"
    (openedChange)="onOpenedChange()"
    panelClass="select-panel-width-fix"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="listFilter"
        placeholderLabel="Search"
        noEntriesFoundLabel="No results"
      ></ngx-mat-select-search>
    </mat-option>
    <ng-container *ngIf="isGrouped; else flat">
      <mat-optgroup
        *ngFor="let group of filteredGroupedList$ | async"
        [label]="group.groupName"
      >
        <mat-option
          *ngFor="let item of group.items"
          [value]="item[valueField]"
          [style.font-weight]="
            docCountField === undefined || item[docCountField] === undefined
              ? 'normal'
              : 'bold'
          "
          >{{ displayedValue(item) }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <ng-template #flat>
      <mat-option
        *ngFor="let item of filteredList$ | async"
        [value]="item[valueField]"
        [style.font-weight]="
          docCountField === undefined || item[docCountField] === undefined
            ? 'normal'
            : 'bold'
        "
        >{{ displayedValue(item) }}
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
