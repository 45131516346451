<section class="mat-elevation-z24">
  <mat-icon svgIcon="custom-access-level"></mat-icon>
  <h1>eQosphere custom coming soon</h1>
  <p>This feature is currently in development and will be available soon.</p>
  <p>
    If you are interested in testing this feature before its official release,
    please reach out to us at
    <a href="mailto:eqosphere.support@quantis.com"
      >eqosphere.support&#64;quantis.com</a
    >
  </p>
  <p>We'd love to hear from you!</p>
  <button mat-raised-button color="primary" (click)="onBack()">
    <strong>Go back</strong>
  </button>
  <mat-divider></mat-divider>
  <footer>
    If you believe this is an error, please try to
    <a (click)="onLoginAgain()">log in</a> again.
  </footer>
</section>
