import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import { BasketExportOptions } from '@app/modules/basket/models/basket.model';
import { combineLatest, concat, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  selectAll,
  selectDatasetsIds,
} from '@app/modules/basket/store/dataset/basket-dataset.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { getFilterOption } from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';
import { IndicatorGroupService } from '@app/modules/reference-data/modules/indicator-group/services/indicator-group-service';
import { exportBasket } from '@app/modules/basket/store/dataset/basket-dataset.actions';
import { BasketExportDialogService } from '@app/modules/basket/components/basket-export-dialog/basket-export-dialog.service';

@Component({
  selector: 'app-basket-export-dialog',
  templateUrl: './basket-export-dialog.component.html',
  providers: [BasketExportDialogService],
  styleUrl: './basket-export-dialog.component.scss',
})
export class BasketExportDialogComponent implements OnInit, OnDestroy {
  options: BasketExportOptions = {
    datasetIds: [],
    groups: [],
    withExchangeContribution: true,
    withObsoleteFlagContribution: false,
    withSplitGasContribution: true,
    withSplitActivityContribution: true,
    withEmbeddedProducts: true,
  };

  groupFilterCtrl = new FormControl<string[]>(
    { value: [], disabled: true },
    { nonNullable: true },
  );

  datasetIds$: Observable<string[]> = EMPTY;
  groupDefinitions$: Observable<FilterOption[]> = EMPTY;
  loading$: Observable<boolean> = EMPTY;

  private onDestroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<BasketExportDialogComponent>,
    private store: Store<AppState>,
    private indicatorGroupService: IndicatorGroupService,
    private basketExportDialogService: BasketExportDialogService,
  ) {}

  ngOnInit(): void {
    this.datasetIds$ = this.store.select(selectDatasetsIds());

    this.datasetIds$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((datasetIds) => {
        this.options.datasetIds = datasetIds;
      });

    this.groupDefinitions$ = combineLatest([
      this.store.select(selectAll),
      this.indicatorGroupService.getIndicatorGroups(),
    ]).pipe(
      map(([datasets, indicatorGroups]) =>
        this.basketExportDialogService.findExistingGroupDefinitions(
          datasets,
          indicatorGroups,
        ),
      ),
      map((groups) => groups.map((g) => getFilterOption(g))),
    );

    this.groupDefinitions$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((groups) => {
        const defaultSelection = groups[0]?.key;
        if (defaultSelection !== undefined) {
          this.groupFilterCtrl.setValue([defaultSelection]);
        }
        this.groupFilterCtrl.enable();
      });

    this.loading$ = concat(
      of(true),
      combineLatest([this.datasetIds$, this.groupDefinitions$]).pipe(
        map(() => false),
      ),
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onMethodChange(): void {
    this.options.groups = this.groupFilterCtrl.value;
  }

  onExchangeContributionChange(): void {
    this.options.withExchangeContribution =
      !this.options.withExchangeContribution;
  }

  onFlagContributionChange(): void {
    this.options.withObsoleteFlagContribution =
      !this.options.withObsoleteFlagContribution;
  }

  onSplitGasContributionChange(): void {
    this.options.withSplitGasContribution =
      !this.options.withSplitGasContribution;
  }

  onSplitActivityContributionChange(): void {
    this.options.withSplitActivityContribution =
      !this.options.withSplitActivityContribution;
  }

  onEmbeddedProductChange(): void {
    this.options.withEmbeddedProducts = !this.options.withEmbeddedProducts;
  }

  onExport(): void {
    this.store.dispatch(exportBasket({ options: this.options }));
    this.dialogRef.close();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
