import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectSearchItem } from '@app/modules/ui/modules/select/select-search/select-search.model';
import { assert } from '@app/modules/core/utils/assert-utils';

@Component({
  selector:
    'app-select-search[label][control][list][displayedField][valueField]',
  templateUrl: './select-search.component.html',
  styleUrl: './select-search.component.scss',
})
export class SelectSearchComponent<T extends SelectSearchItem>
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  control!: UntypedFormControl;

  @Input()
  label!: string;

  @Input()
  list: Array<T> = [];
  groupedList: GroupedList<T> = [];

  filteredList$ = new ReplaySubject<Array<T>>();
  filteredGroupedList$: ReplaySubject<GroupedList<T>> = new ReplaySubject<
    GroupedList<T>
  >();

  isGrouped = false;

  @Input()
  displayedField!: keyof T;

  @Input()
  valueField!: keyof T;

  @Input()
  filteredField: keyof T | [keyof T] | undefined;

  @Input()
  docCountField: keyof T | undefined;

  @Input()
  multiple = true;

  @Input()
  size = '';

  @Output()
  valueChange = new EventEmitter<void>();

  @Output()
  openedChange = new EventEmitter<void>();

  listFilter = new UntypedFormControl();

  onDestroy$ = new Subject<void>();

  displayedValue(item: T): string {
    const displayedValue = this.displayedField
      ? (item[this.displayedField] as string)
      : String(item);
    if (this.docCountField !== undefined) {
      const docCount = item[this.docCountField] as number | undefined;
      if (docCount !== undefined) {
        return `${displayedValue} (${docCount.toString()})`;
      }
    }
    return displayedValue;
  }

  ngOnInit(): void {
    this.listFilter.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() =>
        this.isGrouped ? this.filterListGrouped() : this.filterList(),
      );
    this.control.valueChanges.subscribe(() => this.valueChange.emit());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('list' in changes) {
      this.isGrouped = this.list.some((item) => item.group !== undefined);
      if (this.isGrouped) {
        this.groupedList = this.toGroupedList(this.list);
        this.filteredGroupedList$.next(this.groupedList);
      } else {
        this.filteredList$.next(this.list);
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private filterListGrouped(): void {
    assert(this.isGrouped);
    if (this.groupedList.length === 0) {
      return;
    }
    const search = (this.listFilter.value as string)?.toLowerCase();
    if (search === undefined) {
      this.filteredGroupedList$.next(this.groupedList.slice());
      return;
    }
    const filteredField = this.filteredField ?? this.displayedField;
    const filteredGroupedList = this.groupedList.reduce(
      (groupedList, group) => {
        const items = group.items.filter((item) =>
          this.matches(search, item, filteredField),
        );
        if (items.length !== 0) {
          groupedList.push({ groupName: group.groupName, items });
        }
        return groupedList;
      },
      [] as GroupedList<T>,
    );
    this.filteredGroupedList$.next(filteredGroupedList);
  }

  private filterList(): void {
    assert(!this.isGrouped);
    if (this.list.length === 0) {
      return;
    }
    const search = (this.listFilter.value as string)?.toLowerCase();
    if (search === undefined) {
      this.filteredList$.next(this.list.slice());
      return;
    }
    const filteredField = this.filteredField ?? this.displayedField;
    const filteredList = this.list.filter((item) =>
      this.matches(search, item, filteredField),
    );
    this.filteredList$.next(filteredList);
  }

  private matches(
    search: string,
    item: T,
    filteredFields: keyof T | [keyof T],
  ): boolean {
    if (!filteredFields) {
      return searchTermIsInField(item);
    }

    if (!Array.isArray(filteredFields)) {
      return searchTermIsInField(item[filteredFields]);
    }

    return filteredFields.some((field: keyof T) => searchTermIsInField(field));

    function searchTermIsInField<T>(field: T): boolean {
      return String(field).toLowerCase().indexOf(search) > -1;
    }
  }

  private toGroupedList(list: Array<T>): GroupedList<T> {
    const groupedList = list.reduce(
      (groupedList, item) => {
        const groupName = item.group !== undefined ? item.group : '';
        const items =
          groupedList.find((group) => group.groupName === groupName)?.items ??
          [];
        if (items.length === 0) {
          groupedList.push({ groupName, items });
        }
        items.push(item);
        return groupedList;
      },
      [] as Array<{ groupName: string; items: Array<T> }>,
    );
    return groupedList;
  }

  onOpenedChange(): void {
    this.openedChange.emit();
  }
}

type GroupedList<T> = Array<{ groupName: string; items: Array<T> }>;
