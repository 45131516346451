/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubstancesServiceInterface } from '@app/api/__generated__/api/substances.service';
import { HierarchicCompartmentsDto } from '@app/api/__generated__/model/hierarchicCompartmentsDto';
import { SubstanceDto } from '@app/api/__generated__/model/substanceDto';
import { SubstancesDto } from '@app/api/__generated__/model/substancesDto';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ListSubstanceRequestDto } from '@app/api/__generated__/model/listSubstanceRequestDto';

@Injectable({
  providedIn: 'root',
})
export class SubstancesApiService implements SubstancesServiceInterface {
  constructor(private httpClient: HttpClient) {}

  private readonly compartmentsUrl =
    environment.apiUrl + '/substances-compartments';

  getCompartments(): Observable<HierarchicCompartmentsDto> {
    return this.httpClient.get<HierarchicCompartmentsDto>(this.compartmentsUrl);
  }

  deprecatedListSubstances(
    ids?: string[] | undefined,
    names?: string[] | undefined,
  ): Observable<SubstancesDto> {
    throw new Error('Method not implemented.');
  }

  getSubstance(uuid: string): Observable<SubstanceDto> {
    throw new Error('Method not implemented.');
  }

  listSubstances(
    listSubstanceRequestDto: ListSubstanceRequestDto,
  ): Observable<SubstancesDto> {
    throw new Error('Method not implemented.');
  }
}
