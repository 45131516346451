import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, filter, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { DatasetSearchService } from '@app/modules/dataset/services/dataset-search.service';
import { ClassificationService } from '@app/modules/reference-data/modules/classification/services/classification.service';
import { DatabaseService } from '@app/modules/reference-data/modules/database/services/database.service';
import { GeographyService } from '@app/modules/reference-data/modules/geography/services/geography.service';
import { UnitService } from '@app/modules/reference-data/modules/unit/services/unit.service';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { DatasetSearchMapperService } from '../../services/dataset-search-mapper.service';
import {
  loadDatasetFilters,
  loadDatasetFiltersError,
  loadDatasetFiltersSuccess,
  searchDatasets,
  searchDatasetsError,
  searchDatasetsSuccess,
  updateFiltersError,
  updateFiltersSuccess,
} from './dataset-search.actions';
import {
  selectFilterOptionMap,
  selectFiltersLoaded,
} from './dataset-search.selectors';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchEffects {
  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDatasetFilters),
      switchMap(() => {
        return combineLatest([
          this.databaseService.findLiveDatabasesAndSort(),
          this.geographyService.findGeographiesWithDisplayNameAndSort(),
          this.unitService.findUnitsAndSort(),
          this.classificationService.findIsicClassifications(),
        ]).pipe(
          map(([databases, geographies, units, classifications]) => ({
            filters: this.searchMapperService.mapToSearchFilterOptions(
              databases,
              geographies,
              units,
              classifications
            ),
            filterOptionMap:
              this.searchMapperService.mapToSearchFilterOptionsMap(
                databases,
                geographies,
                units,
                classifications
              ),
          })),
          map(({ filters, filterOptionMap }) => {
            return loadDatasetFiltersSuccess({
              filterOptions: filters,
              filterOptionMap,
            });
          }),
          catchError((err) => {
            return of(
              loadDatasetFiltersError({
                message: 'An error occurred when loading the datasets filters',
                error: err,
              })
            );
          })
        );
      })
    )
  );

  searchDatasets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchDatasets),
      map((action) => action.searchForm),
      switchMap((form) => {
        return this.datasetSearchService
          .searchDatasets(
            form.searchTerm,
            form.filters.geographies,
            form.filters.databases,
            form.filters.isics,
            [],
            form.filters.activitiesTypes,
            form.filters.units
          )
          .pipe(
            map((searchResults) =>
              searchDatasetsSuccess({
                form,
                results: searchResults.results,
                facets: searchResults.facets,
                suggestions: searchResults.suggestions,
                totalDocCount: searchResults.totalDocCount,
                maxDocCount: searchResults.maxDocCount,
              })
            ),
            catchError((err) => {
              return of(
                searchDatasetsError({
                  message:
                    'An error occurred when executing the datasets search query',
                  error: err,
                })
              );
            })
          );
      })
    )
  );

  updateFilters$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(searchDatasetsSuccess)),
      this.store
        .select(selectFiltersLoaded)
        .pipe(filter((selectFilterLoaded) => selectFilterLoaded)),
    ]).pipe(
      map(([action]) => action.facets),
      withLatestFrom(this.store.select(selectFilterOptionMap)),
      switchMap(([facets, filterOptionMap]) => {
        const filters = this.searchMapperService.mapFacetsToFilterOptions(
          facets,
          filterOptionMap
        );
        return of(updateFiltersSuccess({ filters }));
      }),
      catchError((err) => {
        return of(
          updateFiltersError({
            message: 'An error occurred when updating the search filters',
            error: err,
          })
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly datasetSearchService: DatasetSearchService,
    private readonly geographyService: GeographyService,
    private readonly databaseService: DatabaseService,
    private readonly unitService: UnitService,
    private readonly classificationService: ClassificationService,
    private readonly searchMapperService: DatasetSearchMapperService
  ) {}
}
