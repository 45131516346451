import { Injectable } from '@angular/core';
import { GeographyWithDisplayName } from '@app/modules/dataset/models/dataset-search.model';
import { GeographyApiService } from '@app/modules/reference-data/modules/geography/api/geography-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeographyMapperService } from '../mappers/geography-mapper.service';
import { Geography } from '../models/geography.model';

@Injectable({
  providedIn: 'root',
})
export class GeographyService {
  constructor(
    private geographyApiService: GeographyApiService,
    private mapper: GeographyMapperService
  ) {}

  findGeographies(query?: string): Observable<Geography[]> {
    return this.geographyApiService
      .getGeographies(query)
      .pipe(map((geographies) => this.mapper.fromList(geographies.results)));
  }

  findGeographiesWithDisplayNameAndSort(
    query?: string
  ): Observable<GeographyWithDisplayName[]> {
    return this.findGeographies(query).pipe(
      map((geographies) =>
        this.mapper.toGeographiesWithDisplayName(geographies)
      ),
      map((geographies) =>
        geographies.sort((a, b) => a.displayName.localeCompare(b.displayName))
      )
    );
  }
}
