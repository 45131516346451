import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { isInternal } from '@app/modules/auth/models/user.model';
import { AuthUserService } from '@app/modules/auth/services/auth-user.service';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-search-tips-dialog',
  templateUrl: './search-tips-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTipsDialogComponent implements OnInit {
  isInternal$: Observable<boolean> = EMPTY;

  constructor(private userService: AuthUserService) {}

  ngOnInit(): void {
    this.isInternal$ = this.userService
      .getUser()
      .pipe(map((user) => isInternal(user)));
  }
}
