import { Pipe, PipeTransform } from '@angular/core';
import { HistoryStatus } from '../models/history.models';

@Pipe({
  name: 'historyStatusLabelForParent',
})
export class HistoryStatusLabelForParentPipe implements PipeTransform {
  transform(status: HistoryStatus): string {
    switch (status) {
      case HistoryStatus.INHERIT:
        return 'From';
      case HistoryStatus.MERGE:
        return 'Merged from';
      case HistoryStatus.SPLIT:
        return 'Split from';
      default:
        return '';
    }
  }
}
