import { Injectable } from '@angular/core';
import { GeographyDto } from '@app/api/__generated__/model/geographyDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { GeographyWithDisplayName } from '@app/modules/dataset/models/dataset-search.model';
import { DisplayGeographyPipe } from '@app/modules/reference-data/modules/geography/pipes/display-geography.pipe';
import { Geography } from '../models/geography.model';

@Injectable({
  providedIn: 'root',
})
export class GeographyMapperService extends AbstractMapper<
  GeographyDto,
  Geography
> {
  constructor(private displayGeographyPipe: DisplayGeographyPipe) {
    super();
  }

  from(dto: GeographyDto | undefined): Geography {
    if (dto === undefined || dto === null) {
      return {} as Geography;
    }
    const { name, shortName } = dto;
    return { name, shortName } as Geography;
  }

  toGeographiesWithDisplayName(
    geographies: Geography[]
  ): GeographyWithDisplayName[] {
    return geographies.map((geography) => {
      return {
        ...geography,
        displayName: this.displayGeographyPipe.transform(geography),
      };
    });
  }
}
