import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatasetsUpgradeServiceInterface } from '@app/api/__generated__/api/datasetsUpgrade.service';
import { DatasetsDto } from '@app/api/__generated__/model/datasetsDto';
import { UpgradeDatasetsRequestDto } from '@app/api/__generated__/model/upgradeDatasetsRequestDto';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatasetUpgradeApiService
  implements DatasetsUpgradeServiceInterface
{
  constructor(private httpClient: HttpClient) {}

  private readonly datasetsUrl = environment.apiUrl + '/datasets-upgrade';

  upgradeDatasets(
    updateDatasetsRequestDto: UpgradeDatasetsRequestDto
  ): Observable<DatasetsDto> {
    return this.httpClient.post<DatasetsDto>(
      this.datasetsUrl,
      updateDatasetsRequestDto
    );
  }
}
