<app-group-filter
  class="filter"
  [options]="filteredOptionGroups"
  [value]="selection.group"
  (valueChange)="onIndicatorGroupChange($event)"
></app-group-filter>
<app-indicator-filter
  class="filter"
  [options]="optionIndicators$ | async"
  [value]="selection.indicator"
  (valueChange)="onIndicatorChange($event)"
></app-indicator-filter>
