import {Component, Input, OnChanges, ViewEncapsulation} from '@angular/core';

import {Dataset, defaultSectionOptions, ExchangeTable, SectionOptions} from '@app/modules/dataset/models/dataset.model';
import {MethodIndicator} from '@app/modules/reference-data/modules/method/models/method.model';
import {ContributionTypeName} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {ContributionService} from "@app/modules/dataset/modules/contribution/services/contribution.service";
import {Exchanges} from "@app/modules/dataset/modules/exchange/models/exchange.model";

@Component({
  selector: 'app-exchange-table',
  templateUrl: './exchange-table.component.html',
  styleUrls: [
    './exchange-table.component.scss',
    '../../../../../common.table.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ExchangeTableComponent implements OnChanges {
  readonly SPLIT_GAS: ContributionTypeName = 'SPLIT_GAS';

  @Input()
  dataset!: Dataset;

  @Input()
  exchanges!: Exchanges;

  @Input()
  methodIndicator: MethodIndicator | undefined;

  @Input()
  contributionType: ContributionTypeName | undefined;

  @Input()
  showPercentages = false;

  @Input()
  showActivity = false;

  totalAssessment: number | undefined;

  options: SectionOptions = defaultSectionOptions;

  constructor(private contributionService: ContributionService) {
  }

  get hasCoProducts(): boolean {
    return this.exchanges.toProduct.coProducts.length > 0;
  }

  get activeProduct(): ExchangeTable {
    if (this.showActivity && this.dataset.activity) return this.dataset.activity;
    return this.dataset.referenceProduct;
  }

  get hasFromTechnosphere(): boolean {
    return this.exchanges.fromTechnosphere.length > 0;
  }

  get hasToTechnosphere(): boolean {
    return this.exchanges.toTechnosphere.length > 0;
  }

  get hasFromBiosphere(): boolean {
    return this.exchanges.fromBiosphere.length > 0;
  }

  get hasToBiosphere(): boolean {
    return this.exchanges.toBiosphere.length > 0;
  }

  get showContribution(): boolean {
    return (
      this.methodIndicator !== undefined && this.contributionType !== undefined
    );
  }

  get optionsWithPercentage() : SectionOptions{
    return {
      ...this.options,
      showPercentages: this.showPercentages,
    };
  }

  get optionsWithPercentageAndGreyed() : SectionOptions{
    return {
      ...this.options,
      showPercentages: this.showPercentages,
      isGreyed: true,
    };
  }

  get optionsWithUnit() : SectionOptions{
    return {
      ...this.options,
      unit: this.methodIndicator?.unit,
    };
  }


  ngOnChanges(): void {
    if (this.methodIndicator && this.contributionType) {
      this.options.displayedKey = 'contribution-' + this.methodIndicator.id;
    }
    if (this.showContribution) {

      if (this.showActivity) {
        this.totalAssessment = this.contributionService.findContribution(
          this.dataset.activity?.flattenContributions || [],
          this.methodIndicator!,
          'DIRECT',
        )?.amount;
      } else {
        this.totalAssessment = this.contributionService.findContribution(
          this.exchanges.toProduct.flattenContributions,
          this.methodIndicator!,
          'DIRECT',
        )?.amount;
      }
      this.options.totalAssessment = this.totalAssessment;

    }
  }

  onExpandedCommentIdChange(expandedCommentId: string): void {
    this.options.expandedCommentId = expandedCommentId;
  }

}
