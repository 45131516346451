import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-section-content-two-columns',
  template: `
    <div>
      <ng-content select="[col1]"></ng-content>
    </div>
    <div>
      <ng-content select="[col2]"></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        gap: 10%;
      }

      div {
        flex: 1;
      }
    `,
  ],
})
export class LandingPageSectionContentTwoColumnsComponent {}
