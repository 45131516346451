export type EmbeddedProductType = 'ELECTRICITY' | 'SOY' | 'PALM';
export interface EmbeddedProduct {
  type: EmbeddedProductType;
  amount: number;
  unit: string;
}

export function toLabel(productType: EmbeddedProductType): string {
  const embeddedProductTypeToLabel = {
    ELECTRICITY: 'Electricity',
    SOY: 'Soy',
    PALM: 'Palm',
  };
  return embeddedProductTypeToLabel[productType];
}
