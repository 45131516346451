import { Injectable } from '@angular/core';
import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { getDistinctSortedIndicatorsFromGroups } from '@app/modules/reference-data/modules/method/utils/method.utils';
import {
  getDistinctSubCompartmentsFromHierarchicCompartments,
  HierarchicCompartment,
} from '@app/modules/reference-data/modules/substance/substance/models/hierarchic-compartment.model';
import { mapSubCompartmentToFilterOption } from '@app/modules/reference-data/modules/substance/substance/utils/substance.utils';
import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import {
  CharacterizationFactorsSearchFilters,
  CharacterizationFactorsSearchFiltersOptions,
} from '../models/characterization-factor-search.model';
import {
  getFilterOption,
  IndicatorGroup,
} from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';

@Injectable({
  providedIn: 'root',
})
export class FilterOptionsUpdateService {
  updateAndMapFilterOptions(
    filters: CharacterizationFactorsSearchFilters,
    allIndicatorGroups: IndicatorGroup[],
    allCompartments: HierarchicCompartment[]
  ): CharacterizationFactorsSearchFiltersOptions {
    return {
      indicatorGroups: allIndicatorGroups.map((group) => {
        return getFilterOption(group);
      }),

      indicators: this.filterIndicatorsByIndicatorGroups(
        allIndicatorGroups,
        filters.indicatorGroups
      )
        .map((m) => m.getFilterOption)
        .sort(this.sortFunction),

      compartments: this.filterCompartmentsBySubCompartments(
        allCompartments,
        filters.subCompartments
      )
        .map((hc) => hc.getFilterOption)
        .sort(this.sortFunction),

      subCompartments: this.filterSubCompartmentsByCompartments(
        allCompartments,
        filters.compartments
      )
        .map((sc) => mapSubCompartmentToFilterOption(sc))
        .sort(this.sortFunction),
    };
  }

  private filterIndicatorsByIndicatorGroups = (
    allGroups: IndicatorGroup[],
    selectedGroups: string[]
  ): MethodIndicator[] => {
    return getDistinctSortedIndicatorsFromGroups(
      selectedGroups.length > 0
        ? allGroups.filter((group) => selectedGroups.includes(group.name))
        : allGroups
    );
  };

  private filterCompartmentsBySubCompartments = (
    allCompartments: HierarchicCompartment[],
    subCompartments: SubCompartmentDto[]
  ): HierarchicCompartment[] => {
    return subCompartments.length > 0
      ? allCompartments.filter((c) =>
          subCompartments.some((sc) => c.subCompartments.includes(sc))
        )
      : allCompartments;
  };

  private filterSubCompartmentsByCompartments = (
    allCompartments: HierarchicCompartment[],
    selectedCompartments: CompartmentDto[]
  ): SubCompartmentDto[] => {
    return getDistinctSubCompartmentsFromHierarchicCompartments(
      selectedCompartments.length > 0
        ? allCompartments.filter((c) =>
            selectedCompartments.includes(c.compartment)
          )
        : allCompartments
    );
  };

  private sortFunction = (a: FilterOption, b: FilterOption): number =>
    a.value.localeCompare(b.value);
}
