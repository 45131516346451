import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharacterizationFactorsSearchComponent } from '@app/modules/characterization-factor/pages/characterization-factors-search/characterization-factors-search.component';
import { CharacterizationFactorRoutingModule } from '@app/modules/characterization-factor/characterization-factor-routing.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@app/modules/ui/ui.module';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SubstanceModule } from '@app/modules/reference-data/modules/substance/substance/substance.module';
import { UnitModule } from '@app/modules/reference-data/modules/unit/unit.module';

@NgModule({
  declarations: [CharacterizationFactorsSearchComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    CharacterizationFactorRoutingModule,
    UiModule,
    FlexLayoutModule,
    SubstanceModule,
    UnitModule,
  ],
})
export class CharacterizationFactorModule {}
