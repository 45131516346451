<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [multiple]="true"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    panelClass="select-panel-width-fix"
  >
    <mat-option>
      <ngx-mat-select-search
        ngModel
        (ngModelChange)="onFilterChange($event)"
        placeholderLabel="Search"
        noEntriesFoundLabel="No results"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of options$ | async" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
