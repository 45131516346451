import {Injectable} from '@angular/core';
import {BioExchange, Exchanges, TechnoExchange} from "@app/modules/dataset/modules/exchange/models/exchange.model";
import {
  ExchangeRecordMapperService
} from "@app/modules/dataset/modules/exchange/mappers/exchange-record-mapper.service";
import {
  ExchangeHeaderMapperService
} from "@app/modules/dataset/modules/exchange/mappers/exchange-header-mapper.service";
import {ExchangeSection} from "@app/modules/dataset/models/dataset.model";
import {Assessment, ExchangeHeader, ExchangeRecord} from "@app/modules/dataset/models/exchange.model";
import {EmbeddedProduct} from "@app/modules/dataset/models/embedded.product.model";

@Injectable({
  providedIn: 'root',
})
export class ExchangeSectionMapperService {
  constructor(private readonly exchangeRecordMapperService: ExchangeRecordMapperService,
              private readonly exchangeHeaderMapperService: ExchangeHeaderMapperService,) {

  }

  public getToTechnosphereSection(contributionNames: string[], indicatorId: string | undefined, toTechnoExchanges: TechnoExchange[]): ExchangeSection {
    const header = this.exchangeHeaderMapperService.getToTechnosphereHeader(contributionNames);
    return this.getTechnosphereSection(indicatorId, toTechnoExchanges, header, "To technosphere contribution sub-total");
  }

  public getFromTechnosphereSection(contributionNames: string[], indicatorId: string | undefined, fromTechnoExchanges: TechnoExchange[], embeddedProducts: EmbeddedProduct[]): ExchangeSection {
    const header = this.exchangeHeaderMapperService.getFromTechnosphereHeader(contributionNames, embeddedProducts);
    return this.getTechnosphereSection(indicatorId, fromTechnoExchanges, header, "From technosphere contribution sub-total");
  }

  private getTechnosphereSection(indicatorId: string | undefined, toTechnoExchanges: TechnoExchange[], header: ExchangeHeader, subTotalTitle: string): ExchangeSection {
    const records = this.exchangeRecordMapperService.getTechnosphereRecords(toTechnoExchanges, indicatorId);
    return {
      header: header,
      records: records,
      subtotal: {title: subTotalTitle, assessments: this.getSubtotal(records), showBar: true}
    };
  }

  public getToBiosphereSection(contributionNames: string[], indicatorId: string | undefined, toBioExchanges: BioExchange[]): ExchangeSection {
    const header = this.exchangeHeaderMapperService.getToBiosphereHeader(contributionNames);
    return this.getBiosphereSection(indicatorId, toBioExchanges, header, "To biosphere contribution sub-total");
  }

  public getFromBiosphereSection(contributionNames: string[], indicatorId: string | undefined, fromBioExchanges: BioExchange[]): ExchangeSection {
    const header = this.exchangeHeaderMapperService.getFromBiosphereHeader(contributionNames);
    return this.getBiosphereSection(indicatorId, fromBioExchanges, header, "From biosphere contribution sub-total");
  }

  getBiosphereSection(indicatorId: string | undefined, toBioExchanges: BioExchange[], header: ExchangeHeader, subTotalTitle: string): ExchangeSection {
    const records = this.exchangeRecordMapperService.getBiosphereRecords(toBioExchanges, indicatorId);
    return {
      header: header,
      records: records,
      subtotal: {title: subTotalTitle, assessments: this.getSubtotal(records), showBar:true}
    };
  }

  public getActivityCoProductSection(contributionNames: string[], indicatorId: string | undefined, activityExchanges: Exchanges): ExchangeSection {
    const header = this.exchangeHeaderMapperService.getCoProductHeader(contributionNames, activityExchanges.embeddedProducts);
    const records = this.exchangeRecordMapperService.getActivityCoProductRecords(activityExchanges.toProduct, indicatorId, activityExchanges.embeddedProducts);
    return {
      header: header,
      records: records,
      subtotal: {title: "Total activity", assessments: this.getSubtotal(records), showBar: false}
    };
  }

  getSubtotal(records: Array<ExchangeRecord>): Array<Assessment> {
    const assessments: Array<Assessment> = records.flatMap(er => er.assessments)
    if (!assessments) return [];
    return this.sumByKey(assessments);
  }

  sumByKey(assessments: Array<Assessment>): Array<Assessment> {
    const map = new Map<string, Assessment>();
    for (const assessment of assessments) {
      const currSum = map.get(assessment.key)?.amount || 0;
      map.set(assessment.key, {
        key: assessment.key,
        displayName: assessment.displayName,
        amount: currSum + (assessment.amount || 0)
      });
    }
    return Array.from(map.values());
  }
}
