import { AppState } from '@app/store';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  DatasetSearchFiltersOptions,
  DatasetSearchForm,
  Facet,
  SearchFilterOptionMap,
  Suggestion,
} from '../../models/dataset-search.model';

import { DatasetSearchResult } from '@app/modules/dataset/models/dataset-search.model';
import {
  datasetSearchFeatureKey,
  DatasetSearchState,
} from './dataset-search.reducer';

export const selectSearchState = createFeatureSelector<
  AppState,
  DatasetSearchState
>(datasetSearchFeatureKey);

export const selectFiltersLoaded: MemoizedSelector<AppState, boolean> =
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.filtersLoaded
  );

export const selectFiltersOptions = (): MemoizedSelector<
  AppState,
  DatasetSearchFiltersOptions
> =>
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.filtersOptions
  );

export const selectFilterOptionMap: MemoizedSelector<
  AppState,
  SearchFilterOptionMap
> = createSelector(
  selectSearchState,
  (state: DatasetSearchState) => state.filterOptionMap
);

export const selectForm = (): MemoizedSelector<AppState, DatasetSearchForm> =>
  createSelector(selectSearchState, (state: DatasetSearchState) => state.form);

export const selectResults = (): MemoizedSelector<
  AppState,
  DatasetSearchResult[]
> =>
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.results
  );

export const selectFacets = (): MemoizedSelector<AppState, Facet[]> =>
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.facets
  );

export const selectTotalDocCount = (): MemoizedSelector<AppState, number> =>
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.totalDocCount
  );

export const selectMaxDocCount = (): MemoizedSelector<AppState, number> =>
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.maxDocCount
  );
export const selectSuggestions = (): MemoizedSelector<AppState, Suggestion[]> =>
  createSelector(
    selectSearchState,
    (state: DatasetSearchState) => state.suggestions
  );
