import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CharacterizationFactorsSearchServiceInterface } from '@app/api/__generated__/api/characterizationFactorsSearch.service';
import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { CharacterizationFactorsSearchResultsDto } from '@app/api/__generated__/model/characterizationFactorsSearchResultsDto';
import { CharacterizationFactorExportRequestDto } from '@app/api/__generated__/model/characterizationFactorExportRequestDto';

@Injectable({
  providedIn: 'root',
})
export class CharacterizationFactorSearchApiService
  implements CharacterizationFactorsSearchServiceInterface
{
  private readonly characterizationFactorsSearchUrl =
    environment.apiUrl + '/characterization-factors-search';

  constructor(private httpClient: HttpClient) {}

  searchCharacterizationFactors(
    q?: string,
    method?: Array<string>,
    indicatorGroup?: Array<string>,
    indicator?: Array<string>,
    compartment?: Array<CompartmentDto>,
    subCompartment?: Array<SubCompartmentDto>,
  ): Observable<CharacterizationFactorsSearchResultsDto> {
    let queryParams = new HttpParams();
    if (q !== undefined) {
      queryParams = queryParams.set('q', q);
    }
    if (indicatorGroup !== undefined && indicatorGroup.length > 0) {
      indicatorGroup.forEach(
        (m) => (queryParams = queryParams.append('indicatorGroup', m)),
      );
    }
    if (indicator !== undefined && indicator.length > 0) {
      indicator.forEach(
        (i) => (queryParams = queryParams.append('indicator', i)),
      );
    }
    if (compartment !== undefined && compartment.length > 0) {
      compartment.forEach(
        (c) => (queryParams = queryParams.append('compartment', c)),
      );
    }
    if (subCompartment !== undefined && subCompartment.length > 0) {
      subCompartment.forEach(
        (s) => (queryParams = queryParams.append('subCompartment', s)),
      );
    }
    const httpOptions = { params: queryParams };
    return this.httpClient.get<CharacterizationFactorsSearchResultsDto>(
      this.characterizationFactorsSearchUrl,
      httpOptions,
    );
  }

  exportCharacterizationFactors(
    exportRequestDto: CharacterizationFactorExportRequestDto,
  ): Observable<string> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.post<string>(
      this.characterizationFactorsSearchUrl + '/export',
      exportRequestDto,
      httpOptions,
    );
  }
}
