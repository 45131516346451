<div [formGroup]="form">
  <app-select-search
    class="filter"
    [control]="control"
    [list]="filter.options$.value"
    [label]="filter.label"
    displayedField="value"
    [filteredField]="
      filter.filteredAttributes ? 'filteredAttributes' : undefined
    "
    valueField="key"
    docCountField="docCount"
    (valueChange)="onValueChange()"
    (openedChange)="onOpenedChange()"
  >
  </app-select-search>
</div>
