import { Injectable } from '@angular/core';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { DatabaseDto } from '@app/api/__generated__/model/databaseDto';
import { Database } from '../models/database.model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseMapperService extends AbstractMapper<
  DatabaseDto,
  Database
> {
  constructor() {
    super();
  }

  from(dto: DatabaseDto | undefined): Database {
    if (dto === undefined) {
      throw new Error('database dto is undefined');
    }
    const { name, version, background, live, obsolete } = dto;

    return new Database(
      name,
      version,
      this.fromList((background as DatabaseDto[]) || []),
      live,
      obsolete
    );
  }
}
