<table class="simple-table">
  <ng-container *ngIf="!showActivity">
    <tbody
      exchange-line-header
      [header]="dataset.referenceProduct.referenceProductSection.header"
    ></tbody>
    <tbody
      *ngIf="methodIndicator"
      exchange-line-record
      [records]="dataset.referenceProduct.referenceProductSection.records"
      [options]="optionsWithUnit"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <ng-container *ngIf="hasCoProducts">
      <tbody
        exchange-line-header
        [header]="dataset.referenceProduct.coProductSection.header"
      ></tbody>
      <tbody
        exchange-line-record
        [records]="dataset.referenceProduct.coProductSection.records"
        [options]="optionsWithPercentageAndGreyed"
        (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
      ></tbody>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showActivity  && dataset.activity">
    <tbody
      exchange-line-header
      [header]="dataset.activity.coProductSection.header"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="dataset.activity.coProductSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      [subTotal]="dataset.activity.coProductSection.subtotal"
      [options]="optionsWithUnit"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasFromTechnosphere">
    <tbody
      exchange-line-header
      [header]="activeProduct.fromTechnosphereSection.header"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="activeProduct.fromTechnosphereSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      [subTotal]="activeProduct.fromTechnosphereSection.subtotal"
      [options]="optionsWithPercentage"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasToTechnosphere">
    <tbody
      exchange-line-header
      [header]="activeProduct.toTechnosphereSection.header"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="activeProduct.toTechnosphereSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      [subTotal]="activeProduct.toTechnosphereSection.subtotal"
      [options]="optionsWithPercentage"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasFromBiosphere">
    <tbody
      exchange-line-header
      [header]="activeProduct.fromBiosphereSection.header"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="activeProduct.fromBiosphereSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      [subTotal]="activeProduct.fromBiosphereSection.subtotal"
      [options]="optionsWithPercentage"
    ></tbody>
  </ng-container>
  <ng-container *ngIf="hasToBiosphere">
    <tbody
      exchange-line-header
      [header]="activeProduct.toBiosphereSection.header"
    ></tbody>
    <tbody
      exchange-line-record
      [records]="activeProduct.toBiosphereSection.records"
      [options]="optionsWithPercentage"
      (expandedCommentIdChange)="onExpandedCommentIdChange($event)"
    ></tbody>
    <tbody
      *ngIf="showContribution"
      exchangeSubtotal
      [subTotal]="activeProduct.toBiosphereSection.subtotal"
      [options]="optionsWithPercentage"
    ></tbody>
  </ng-container>
</table>
