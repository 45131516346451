<app-select-search
  size="wide"
  [multiple]="false"
  [control]="indicatorGroupFilterCtrl"
  [list]="this.filterOptions"
  label="Indicator group"
  displayedField="value"
  valueField="key"
  (valueChange)="onValueChange()"
></app-select-search>
