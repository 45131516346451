import { Component, Input } from '@angular/core';
import { DatasetSearchResult } from '@app/modules/dataset/models/dataset-search.model';

@Component({
  selector: 'app-dataset-summary',
  templateUrl: './dataset-summary.component.html',
  styleUrl: './dataset-summary.component.scss',
})
export class DatasetSummaryComponent {
  @Input()
  dataset!: DatasetSearchResult;
  @Input()
  showDetails = true;
  @Input()
  showImpactIndicator = true;
  @Input()
  showGeneralComment = true;
}
