<section
  fxLayout="row"
  fxLayoutAlign="start center"
  class="basket-item"
  *ngFor="let dataset of datasets"
>
  <app-dataset-summary
    [dataset]="dataset"
    [showGeneralComment]="false"
  ></app-dataset-summary>
  <span>
    <button (click)="removeDataset(dataset)" mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </span>
</section>
