/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ExportRequestDto } from '@app/api/__generated__/model/exportRequestDto';
import { BasketsServiceInterface } from '@app/api/__generated__/api/baskets.service';

@Injectable({
  providedIn: 'root',
})
export class BasketApiService implements BasketsServiceInterface {
  private readonly basketsUrl = environment.apiUrl + '/baskets/current';

  constructor(private httpClient: HttpClient) {}

  exportBasketAsXlsx(exportRequestDto: ExportRequestDto): Observable<string> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.post<string>(
      this.basketsUrl + '/export',
      exportRequestDto,
      httpOptions,
    );
  }
}
