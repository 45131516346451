import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { PageComponent } from '@app/modules/core/components/page-component';

@Component({
  selector: 'access-denied',
  template: `
    <div>
      <h1>Access Denied</h1>
      <p>
        It seems your current license does not include access to eQosphere. If
        you are interested in upgrading or have any questions about your
        license, please contact our support team.
      </p>
      <p>If you believe this is an error, please try to log in again.</p>
      <button (click)="loginAgain()">Log in</button>
    </div>
  `,
  styles: ['div { padding: 2em; }'],
})
export class AccessDeniedComponent implements PageComponent {
  readonly pageName = 'access-denied';

  constructor(private authService: AuthService) {}
  loginAgain(): void {
    this.authService.logout({
      logoutParams: { returnTo: environment.logoutUrl },
    });
  }
}
