export const UNIT_SEARCH_REPLACE_DICTIONARY: Array<{
  search: string | RegExp;
  replace: string;
}> = [
  {
    search: '^2',
    replace: '\u00B2',
  },
  {
    search: 'CO2',
    replace: 'CO\u2082',
  },
  {
    search: 'H+',
    replace: 'H\u207A',
  },
  {
    search: 'm2',
    replace: 'm\u00B2',
  },
  {
    search: 'm3',
    replace: 'm\u00B3',
  },
  {
    search: 'N2O',
    replace: 'N\u2082O',
  },
  {
    search: 'NH3',
    replace: 'NH\u2083',
  },
  {
    search: 'NO3',
    replace: 'NO\u2083',
  },
  {
    search: 'NOx',
    replace: 'NO\u2093',
  },
  {
    search: 'P2O5',
    replace: 'P\u2082O\u2085',
  },
  {
    search: 'PO4',
    replace: 'PO\u2084',
  },
  {
    search: /{([^}]*)}/g,
    replace: ' $1',
  },
];
