/**
 * Returns whether the given string is blank.
 *
 * A string is considered blank if either:
 * - It's lengths is equal to zero
 * - It only contains whitespace characters (space, tab, new line, etc.)
 *
 * @param str - the string to test
 * @returns whether the given string is blank or not
 */
export function isBlank(str: string | undefined): boolean {
  return str === undefined || str.trim().length === 0;
}

/**
 * Returns a list of all elements common to every array in the 2d-array input
 * @param list the 2-dimensional array in which to look for common elements
 * @returns the list of common elements in the input
 */
export function intersection(list: string[][]): string[] {
  return list
    .slice(1)
    .reduce(
      (acc, curr) => curr.filter((group) => acc.includes(group)),
      list[0]
    );
}
