import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BasketRoutingModule } from '@app/modules/basket/basket-routing.module';
import { DatasetModule } from '@app/modules/dataset/dataset.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { UiModule } from '@app/modules/ui/ui.module';
import { BasketDatasetTabComponent } from './components/basket-dataset-tab/basket-dataset-tab.component';
import { BasketExportDialogComponent } from './components/basket-export-dialog/basket-export-dialog.component';
import { BasketImportDialogComponent } from './components/basket-import-dialog/basket-import-dialog.component';
import { UpgradeDatasetsDialogComponent } from './components/update-datasets-dialog/upgrade-datasets-dialog.component';
import { ViewBasketComponent } from './pages/view-basket/view-basket.component';

@NgModule({
  declarations: [
    ViewBasketComponent,
    BasketExportDialogComponent,
    BasketDatasetTabComponent,
    BasketImportDialogComponent,
    UpgradeDatasetsDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DatasetModule,
    UiModule,
    ReactiveFormsModule,
    BasketRoutingModule, // must be imported as the last module
  ],
  exports: [ViewBasketComponent],
})
export class BasketModule {}
