import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClassificationsDto } from '@app/api/__generated__/model/classificationsDto';
import { ClassificationsServiceInterface } from '@app/api/__generated__/api/classifications.service';

@Injectable({
  providedIn: 'root',
})
export class ClassificationApiService
  implements ClassificationsServiceInterface
{
  private readonly classificationsUrl = environment.apiUrl + '/classifications';

  constructor(private httpClient: HttpClient) {}

  getClassifications(system?: string): Observable<ClassificationsDto> {
    let queryParams = new HttpParams();
    if (system !== undefined) queryParams = queryParams.set('system', system);
    const httpOptions = { params: queryParams };
    return this.httpClient.get<ClassificationsDto>(
      this.classificationsUrl,
      httpOptions
    );
  }
}
