import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-section-content',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        box-sizing: content-box;
        display: block;
        margin: 0 auto;
        width: 75%;
        max-width: 1200px;
        padding: max(2vw, 25px) max(4vw, 50px);
      }
    `,
  ],
})
export class LandingPageSectionContentComponent {}
