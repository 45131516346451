import {Injectable} from '@angular/core';
import {
  Contribution,
  contributionsTreeLeaves
} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {ContributionService} from "@app/modules/dataset/modules/contribution/services/contribution.service";


@Injectable({
  providedIn: 'root',
})
export class ExchangeWithContributionMapperService {
  constructor(private readonly contributionService: ContributionService) {}

  public mapToDisplayedDirectContributions(
    contributions: Array<Contribution> | undefined,
    methodIndicatorId: string | undefined
  ): Array<Contribution> {
    if (
      contributions === undefined ||
      methodIndicatorId === undefined
    ) {
      return [];
    }
    const filteredContributions = this.contributionService.findContributions(
      contributions,
      methodIndicatorId,
      {type : "DIRECT"}
    );
    return contributionsTreeLeaves("DIRECT").map((leaf) => {
      return {
        name: leaf.name,
        displayName: leaf.displayName,
        type: leaf.type,
        indicator: leaf.indicator,
        amount: filteredContributions.find((c) => c.name === leaf.name)?.amount,
        innerContributions: [],
        innerFamilies: [],
      };
    });
  }
}
