import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import * as fromSearch from '@app/modules/dataset/store/dataset-search';
import { selectDatasetsIds } from '@app/modules/basket/store/dataset/basket-dataset.selectors';
import { skip, take, takeUntil } from 'rxjs/operators';
import {
  DatasetSearchResult,
  Suggestion,
  SuggestionSelection,
} from '@app/modules/dataset/models/dataset-search.model';
import {
  addAllDatasets,
  addDataset,
  removeAllDatasets,
  removeDataset,
} from '@app/modules/basket/store/dataset/basket-dataset.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';

@Component({
  selector: 'app-datasets-search-results',
  templateUrl: './datasets-search-results.component.html',
  styleUrl: './datasets-search-results.component.scss',
})
export class DatasetsSearchResultsComponent implements OnInit {
  @Input()
  showResultsDetails = true;

  @Output()
  suggestionSelection = new EventEmitter<SuggestionSelection>();

  datasetsSearchResults: DatasetSearchResult[] = [];
  totalDocCount = 0;
  suggestion: Suggestion | undefined;
  suggestionOptions: string[] = [];
  maxDocCount = 30;
  isAllDatasetsChecked = false;
  firstQuerySent = false;

  get resultAmountDisplay(): string {
    if (this.totalDocCount === 1) {
      return '1 result found';
    } else {
      if (this.totalDocCount > this.maxDocCount) {
        return `${this.totalDocCount} results found (${this.maxDocCount} displayed)`;
      } else {
        return `${this.totalDocCount} results found`;
      }
    }
  }

  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(fromSearch.selectResults()),
      this.store.select(selectDatasetsIds()),
      this.store.select(fromSearch.selectTotalDocCount()),
      this.store.select(fromSearch.selectMaxDocCount()),
      this.store.select(fromSearch.selectSuggestions()),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([datasets, ids, totalDocCount, maxDocCount, suggestions]) => {
        this.datasetsSearchResults = datasets.map(
          (dataset: DatasetSearchResult) =>
            dataset.copyWith({
              isInBasket: ids.includes(dataset.id),
            }),
        );
        this.totalDocCount = totalDocCount;
        this.maxDocCount = maxDocCount;
        this.suggestion = suggestions.find((s) => s.suggestions.length > 0);
        this.suggestionOptions = this.suggestion
          ? this.suggestion.suggestions
          : [];
        this.isAllDatasetsChecked =
          this.datasetsSearchResults.length > 0 &&
          this.datasetsSearchResults.every((d) => d.isInBasket);
      });

    this.store
      .select(fromSearch.selectResults())
      .pipe(skip(1), take(1))
      .subscribe(() => (this.firstQuerySent = true));

    this.isAllDatasetsChecked =
      this.datasetsSearchResults.length > 0 &&
      this.datasetsSearchResults.every((d) => d.isInBasket);
  }

  onDatasetToggled(dataset: DatasetSearchResult): void {
    if (!dataset.isInBasket) {
      this.addToBasket(dataset);
    } else {
      this.removeFromBasket(dataset);
    }
  }

  onAllDatasetsToggled(): void {
    if (this.isAllDatasetsChecked) {
      this.addAllToBasket(this.datasetsSearchResults);
    } else {
      this.removeAllFromBasket(this.datasetsSearchResults);
    }
  }

  private addToBasket(dataset: DatasetSearchResult): void {
    this.store.dispatch(addDataset({ dataset }));
  }

  private addAllToBasket(datasets: DatasetSearchResult[]): void {
    this.store.dispatch(addAllDatasets({ datasets }));
  }

  private removeFromBasket(dataset: DatasetSearchResult): void {
    this.store.dispatch(removeDataset({ dataset }));
  }

  private removeAllFromBasket(datasets: DatasetSearchResult[]): void {
    this.store.dispatch(removeAllDatasets({ datasets }));
  }

  onSuggestionSelection(suggestionSelection: SuggestionSelection): void {
    this.suggestionSelection.emit(suggestionSelection);
  }
}
