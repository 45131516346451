import { Action, createReducer, on } from '@ngrx/store';
import {
  CharacterizationFactorSearchQuery,
  CharacterizationFactorSearchResult,
  CharacterizationFactorsSearchFiltersOptions,
  CHARACTERIZATION_FACTOR_SEARCH_INITIAL_FILTERS,
} from '@app/modules/characterization-factor/models/characterization-factor-search.model';
import {
  loadCharacterizationFactorsFiltersSuccess,
  searchCharacterizationFactorsError,
  searchCharacterizationFactorsSuccess,
  updateCharacterizationFactorsFilterOptionsSuccess,
  updateCharacterizationFactorsSearchQuery,
} from '@app/modules/characterization-factor/store/characterization-factor-search.actions';
import { HierarchicCompartment } from '@app/modules/reference-data/modules/substance/substance/models/hierarchic-compartment.model';
import { IndicatorGroup } from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';

export const searchFeatureKey = 'characterizationFactorSearch';

export interface CharacterizationFactorSearchState {
  filtersOptions: CharacterizationFactorsSearchFiltersOptions;
  form: CharacterizationFactorSearchQuery;
  results: CharacterizationFactorSearchResult[];
  indicatorGroups: IndicatorGroup[];
  compartments: HierarchicCompartment[];
}

export const initialState: CharacterizationFactorSearchState = {
  filtersOptions: {
    indicatorGroups: [],
    indicators: [],
    compartments: [],
    subCompartments: [],
  },
  form: {
    searchTerm: '',
    filters: CHARACTERIZATION_FACTOR_SEARCH_INITIAL_FILTERS,
  },
  results: [],
  indicatorGroups: [],
  compartments: [],
};

const reducer = createReducer(
  initialState,
  on(loadCharacterizationFactorsFiltersSuccess, (state, action) => ({
    ...state,
    filtersOptions: action.filters,
    indicatorGroups: action.allIndicatorGroups,
    compartments: action.allCompartments,
  })),
  on(updateCharacterizationFactorsSearchQuery, (state, action) => ({
    ...state,
    form: action.searchForm,
  })),
  on(searchCharacterizationFactorsSuccess, (state, action) => ({
    ...state,
    results: action.results,
  })),
  on(searchCharacterizationFactorsError, (state, action) => {
    console.error(action.message, action.error);
    return state;
  }),
  on(updateCharacterizationFactorsFilterOptionsSuccess, (state, action) => ({
    ...state,
    filtersOptions: action.filterOptions,
  }))
);

export function characterizationFactorSearchReducer(
  state: CharacterizationFactorSearchState | undefined,
  action: Action
): CharacterizationFactorSearchState {
  return reducer(state, action);
}
