<h1 mat-dialog-title>Search tips</h1>
<mat-dialog-content>
  <b
    >We've improved the search engine! Here's some tips on how to get the most
    out of it.</b
  >

  <h2>Plurals &amp; derived words</h2>
  <p>
    The search engine can now handle plurals, derived verbs, nouns, etc. This
    means you can now search for <i>potato</i> and get results for datasets
    containing the keyword <i>potatoes</i>, plural. The opposite also works,
    searching for the plural of a word will yield results for the singular
    equivalent.
  </p>
  <p>
    The engine's ability to handle derived words also means you can now type
    <i>irrigate</i>, and it will return datasets like <i>irrigation</i>,
    <i>irrigating</i>, <i>irrigated</i>, ... No need to worry about the exact
    phrasing of the dataset you're looking for anymore.
  </p>

  <h2>Synonyms</h2>
  <p>
    The search engine now supports synonyms, based on our own dictionary we plan
    to improve over time. For example, you can now type <i>truck</i> into the
    search and the engine will now return results as if you had searched for
    <i>lorry</i>.
  </p>
  <p *ngIf="isInternal$ | async">
    To suggest synonyms you think would be relevant, use the file we set up for
    this purpose! You'll find a button linking to it on the top right of your
    screen.
  </p>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
