import {CompartmentDto} from '@app/api/__generated__/model/compartmentDto';
import {SubCompartmentDto} from '@app/api/__generated__/model/subCompartmentDto';
import {EmbeddedProduct} from '@app/modules/dataset/models/embedded.product.model';
import {Contribution} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {CoProduct} from "@app/modules/dataset/models/dataset.model";

export interface ExchangesWithoutProduct {
  fromBiosphere: BioExchange[];
  toBiosphere: BioExchange[];
  fromTechnosphere: TechnoExchange[];
  toTechnosphere: TechnoExchange[];
  embeddedProducts: EmbeddedProduct[];
}
export interface Exchanges extends ExchangesWithoutProduct{
  toProduct: ProductExchange;
}

export interface Exchange {
  displayId: string;
  name: string;
  unit: string;
  amount: number;
  comment?: string;
  contributions: Contribution[];
  flattenContributions: Contribution[];
  displayedContributions: Array<Contribution>;
}

export interface ProductExchange extends Exchange {
  id: string;
  allocation: number;
  coProducts: CoProduct[];
}

export interface TechnoExchange extends Exchange {
  id?: string;
  type?: string;
  navigable?: boolean;
  embeddedProducts: EmbeddedProduct[];
  warnings: string[];
}

export interface BioExchange extends Exchange {
  id?: string;
  type?: string;
  compartment: CompartmentDto;
  subCompartment: SubCompartmentDto;
}

export enum AllocationMode {
  WHOLE_ACTIVITY = 'WHOLE_ACTIVITY',
  ONE_PRODUCT_UNIT = 'ONE_UNIT_PRODUCT'
}
