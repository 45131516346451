<h1 mat-dialog-title>Import a basket</h1>
<div mat-dialog-content class="basket-import-dialog">
  <form
    [formGroup]="dialogDataFormGroup"
    fxLayout="column"
    fxLayoutGap="5px"
    fxLayoutAlign="start center"
  >
    <p>Import datasets into my basket using a list of :</p>
    <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-radio-group formControlName="listType" color="primary">
        <mat-radio-button value="ID">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
            <span>UUID</span>
            <mat-icon
              class="help-icon"
              matTooltip="Every dataset has a UUID (universally unique identifier) that you can find in the first column of an eQosphere export"
              >help_outline
            </mat-icon>
          </div>
        </mat-radio-button>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
          <mat-radio-button value="NAME"
            >Reference names from database
          </mat-radio-button>
          <mat-form-field class="database-form-field" subscriptSizing="dynamic">
            <mat-label>Pick database</mat-label>
            <mat-select
              formControlName="database"
              [disabled]="dialogDataFormGroup.value.listType !== 'NAME'"
              panelClass="select-panel-width-fix"
            >
              <mat-option
                *ngFor="let database of databases"
                [value]="database.key"
                >{{ database.simpleDisplayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-radio-group>
    </div>
    <mat-form-field class="textarea-form">
      <mat-label>Paste your list here (one element per line)</mat-label>
      <textarea
        formControlName="list"
        matInput
        class="textarea"
        wrap="off"
        placeholder="Tip : you can copy-paste the column (UUID or reference name) from Excel, without the header"
      ></textarea>
      <mat-error
        *ngIf="dialogDataFormGroup.controls.list.hasError('wrongInput')"
      >
        Bad input, check that you have one
        {{
          dialogDataFormGroup.value.listType === "ID" ? "valid UUID" : "name"
        }}
        per line and no commas or other characters at the end of lines
      </mat-error>
      <mat-error
        *ngIf="dialogDataFormGroup.controls.list.hasError('otherError')"
      >
        An unhandled error occurred, please contact the Digital Team at
        <a href="mailto:digital@quantis-intl.com"
          >digital&#64;quantis-intl.com</a
        >
      </mat-error>
      <mat-error *ngIf="dialogDataFormGroup.controls.list.hasError('noResult')">
        No datasets found to match your input
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button
    mat-raised-button
    (click)="onImport()"
    color="primary"
    cdkFocusInitial
    [disabled]="!dialogDataFormGroup.valid"
  >
    Import
    <mat-icon *ngIf="isFetchingDatasets">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  </button>
</div>
