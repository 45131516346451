import { DatasetSearchResult } from '@app/modules/dataset/models/dataset-search.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addAllDatasets,
  addDataset,
  emptyBasket,
  removeDataset,
  removeAllDatasets,
} from './basket-dataset.actions';

export const datasetBasketFeatureKey = 'datasetBasket';

export type DatasetBasketState = EntityState<DatasetSearchResult>;

export const adapter: EntityAdapter<DatasetSearchResult> =
  createEntityAdapter();

export const initialState: DatasetBasketState = adapter.getInitialState({});

const reducer = createReducer(
  initialState,
  on(addDataset, (state, action) => {
    const dataset = action.dataset;
    return adapter.addOne(dataset, state);
  }),
  on(addAllDatasets, (state, action) => {
    return adapter.upsertMany(action.datasets, state);
  }),
  on(removeDataset, (state, action) => {
    return adapter.removeOne(action.dataset.id, state);
  }),
  on(removeAllDatasets, (state, action) => {
    return adapter.removeMany(
      action.datasets.map((d) => d.id),
      state
    );
  }),
  on(emptyBasket, (state) => {
    return adapter.removeAll(state);
  })
);

export function datasetBasketReducer(
  state: DatasetBasketState | undefined,
  action: Action
): DatasetBasketState {
  return reducer(state, action);
}
