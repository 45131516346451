import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SankeyComponent } from '@app/modules/dataset/modules/exchange/modules/sankey/components/sankey/sankey.component';
import { SankeyDirective } from '@app/modules/dataset/modules/exchange/modules/sankey/directives/sankey.directive';

@NgModule({
  declarations: [SankeyComponent, SankeyDirective],
  imports: [CommonModule],
  exports: [SankeyComponent],
})
export class SankeyModule {}
