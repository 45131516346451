import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isError } from '@app/modules/core/utils/error-utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly UNEXPECTED_EVENT_MESSAGE =
    'Sorry, an unexpected event occurred, please try again later or contact support';

  constructor(private readonly snackBar: MatSnackBar) {}

  success(message: string): void {
    this.openSnackBar(message);
  }

  error(message?: string, error?: unknown): void {
    const errorMessage = this.buildErrorMessage(message, error);
    console.error(error ?? errorMessage);
    this.openSnackBar(errorMessage);
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'Dismiss', {
      data: message,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      duration: 5000,
    });
  }

  private buildErrorMessage(message?: string, error?: unknown): string {
    return (
      message ??
      (isError(error) ? error.message : this.UNEXPECTED_EVENT_MESSAGE)
    );
  }
}
