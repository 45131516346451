import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Auth0ApiService } from '@app/modules/auth/api/auth0-api.service';
import { NotificationService } from '@app/modules/core/services/notification.service';
import { PageComponent } from '@app/modules/core/components/page-component';

@Component({
  selector: 'login-page',
  template: `
    <section>
      <h1>Welcome to eQosphere!</h1>
      <p>
        Your account has been created successfully, and you're just one step
        away from accessing all the features of eQosphere.
      </p>
      <p>
        To finalize your registration, please enter your email address below to
        set up a password. You will then receive an email to reset your
        password. Please note that this email may take a few minutes to arrive
        in your inbox.
      </p>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="email">Email:</label>
          <input type="email" id="email" formControlName="email" required />
          <div
            *ngIf="
              resetPasswordForm.controls.email.errors &&
              resetPasswordForm.controls.email.errors.required
            "
          >
            Email is required.
          </div>
        </div>
        <button color="primary" mat-raised-button type="submit">
          Set your Password
        </button>
      </form>
      <p class="login-text">
        Once your password is set, you can use the login button below to access
        all the incredible resources and tools on the eQosphere platform. We're
        looking forward to seeing you there!
      </p>
      <button
        class="login-button"
        color="secondary"
        mat-raised-button
        (click)="auth.loginWithRedirect()"
      >
        Log in
      </button>
    </section>
  `,
  styleUrl: 'first-login-page.component.scss',
})
export class FirstLoginPageComponent implements PageComponent {
  readonly pageName = 'first-login-page';

  resetPasswordForm: FormGroup;
  constructor(
    public auth: AuthService,
    private formBuilder: FormBuilder,
    private auth0Api: Auth0ApiService,
    private notificationService: NotificationService,
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  onSubmit(): void {
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.auth0Api
        .sendChangePasswordRequest(
          this.resetPasswordForm.get('email')?.value as string,
        )
        .subscribe((msg) => this.notificationService.success(msg));
    }
  }
}
