import { FacetBucket } from '@app/modules/dataset/models/dataset-search.model';

export class Database {
  constructor(
    public name: string,
    public version: string,
    public background: Database[],
    public live: boolean,
    public obsolete: boolean
  ) {}

  get key(): string {
    return this.name + ':' + this.version;
  }

  get displayName(): string {
    const generateBackgroundDisplayName = (bg: Database) =>
      bg.name + (bg.version ? ' ' + bg.version : '');

    return (
      this.simpleDisplayName +
      (this.background.length > 0
        ? ` - ${this.background
            .map((bg) => generateBackgroundDisplayName(bg))
            .join(', ')}`
        : '')
    );
  }

  get simpleDisplayName(): string {
    return `${this.name} ${this.version}`;
  }
}

export function getDatabaseKey(bucket: FacetBucket): string {
  const arr = bucket.bucketName.split(' ');
  const databaseName = arr.slice(0, -1).join();
  const databaseVersion = arr[arr.length - 1];
  return `${databaseName}:${databaseVersion}`;
}
