import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { PageComponent } from '@app/modules/core/components/page-component';
import { AuthUserService } from '@app/modules/auth/services/auth-user.service';

@Component({
  templateUrl: './custom-coming-soon.component.html',
  styleUrl: './custom-coming-soon.component.scss',
})
export class CustomComingSoonComponent implements PageComponent {
  readonly pageName = 'custom-coming-soon';

  constructor(
    private userService: AuthUserService,
    private location: Location,
  ) {}

  onLoginAgain(): void {
    this.userService.logout();
  }

  onBack(): void {
    this.location.back();
  }
}
