import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { isBlank } from '@app/modules/core/utils/string-utils';

@Component({
  selector: '[exchangeCommentToggle][id][comment][isExpanded]',
  templateUrl: './exchange-comment-toggle.component.html',
})
export class ExchangeCommentToggleComponent {
  @Input()
  id!: string;

  @Input()
  comment!: string | undefined;

  @Input()
  isExpanded!: boolean;

  @Output()
  expandedCommentIdChange = new EventEmitter<string>();

  get hasComment(): boolean {
    return !isBlank(this.comment);
  }

  @HostListener('click')
  onClick(): void {
    if (this.hasComment) {
      this.expandedCommentIdChange.emit(this.isExpanded ? undefined : this.id);
    }
  }
}
