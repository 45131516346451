<table cdk-table recycleRows [dataSource]="dataSource">
  <ng-container cdkColumnDef="Impact category">
    <th cdk-header-cell *cdkHeaderCellDef>Name</th>
    <td
      cdk-cell
      *cdkCellDef="let row"
      [class.subtotal]="isKey(row)"
      [class.total]="isRootWithChildren(row)"
    >
      <button
        mat-icon-button
        mat-small-icon-button
        [style.display]="hasInnerContributions ? 'inline-block' : 'none'"
        [style.visibility]="!row.expandable ? 'hidden' : ''"
        [style.marginLeft.px]="row.level * 32"
        (click)="treeControl.toggle(row)"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(row) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <span *ngIf="isKey(row)" class="name">{{
        row.displayName | contributionTypeLabel
      }}</span>
      <span *ngIf="!isKey(row)" class="name">{{
        row.level === 0 ? row.indicatorName : row.displayName
      }}</span>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="Unit">
    <th cdk-header-cell *cdkHeaderCellDef>Unit</th>
    <td
      cdk-cell
      *cdkCellDef="let row"
      [class.subtotal]="isKey(row)"
      [class.total]="isRootWithChildren(row)"
    >
      <span *ngIf="!isChild(row)">{{ row.unit | formatUnit }} </span>
    </td>
  </ng-container>

  <!-- Contribution Definition -->
  <ng-container cdkColumnDef="Emission factor">
    <th cdk-header-cell *cdkHeaderCellDef>Emission factor</th>
    <td
      cdk-cell
      *cdkCellDef="let row"
      [matTooltip]="tooltip(row)"
      matTooltipPosition="after"
      [class.subtotal]="isKey(row)"
      [class.total]="isRootWithChildren(row)"
    >
      <div *ngIf="!isKey(row) && !isChild(row)">
        {{ row.amount | displayAmount }}
      </div>

      <app-contribution-value
        *ngIf="!isKey(row) && isChild(row)"
        width="100%"
        [amount]="row.amount"
        [referenceAmount]="row.globalAmount"
        [showPercentages]="false"
      ></app-contribution-value>

      <div *ngIf="isKey(row)" class="bar" [style.width]="'100%'">
        <b>{{ row.globalAmount | displayAmount }}</b>
      </div>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="Method">
    <th cdk-header-cell *cdkHeaderCellDef>Method</th>
    <td
      cdk-cell
      *cdkCellDef="let row"
      [class.subtotal]="isKey(row)"
      [class.total]="isRootWithChildren(row)"
    >
      <span *ngIf="!isChild(row)">{{ row.method }} </span>
    </td>
  </ng-container>

  <ng-container cdkColumnDef="warning">
    <th cdk-header-cell *cdkHeaderCellDef></th>
    <td cdk-cell *cdkCellDef="let row">
      <app-warn
        *ngIf="isWaterUse(row)"
        class="warn-icon"
        toolTip="This indicator is calculated as the difference of freshwater withdrawals and releases multiplied by the respective regional AWARE factors. These flows are not systematically regionalized, leading to errors. Quantis does not recommend using this indicator.
        To overcome this limitation, Quantis has created an additional indicator “Water use (water scarcity footprint)”. It is calculated by multiplying the amount of water evaporated into the air by the regional AWARE factor. This new indicator is available for ecoinvent 3.10 and will be applied to WFLDB 3.10 and WALDB 2.6."
      >
      </app-warn>
    </td>
  </ng-container>

  <!-- Header and Row Declarations -->
  <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: displayedColumns"></tr>
</table>
