<mat-chip-listbox>
  <mat-chip-option
    *ngFor="let item of items"
    [removable]="true"
    [selectable]="false"
    (removed)="onRemoved(item)"
    (click)="onRemoved(item)"
  >
    <div fxLayout="row" fxLayoutAlign="start center">
      <div>
        <div class="group-name">{{ groupName }}</div>
        <div>{{ item[displayField] }}</div>
      </div>
      <mat-icon matChipRemove>cancel</mat-icon>
    </div>
  </mat-chip-option>
</mat-chip-listbox>
