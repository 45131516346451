import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CharacterizationFactorSearchApiService } from '@app/modules/characterization-factor/api/characterization-factor-search-api.service';
import { CharacterizationFactorSearchResultMapperService } from '@app/modules/characterization-factor/mappers/characterization-factor-search-result-mapper.service';
import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { CharacterizationFactorSearchResult } from '@app/modules/characterization-factor/models/characterization-factor-search.model';
import { CharacterizationFactorExportRequestDto } from '@app/api/__generated__/model/characterizationFactorExportRequestDto';

@Injectable({
  providedIn: 'root',
})
export class CharacterizationFactorSearchService {
  constructor(
    private characterizationFactorSearchApiService: CharacterizationFactorSearchApiService,
    private characterizationFactorSearchResultMapper: CharacterizationFactorSearchResultMapperService,
  ) {}

  searchCharacterizationFactors(
    q?: string,
    method?: Array<string>,
    group?: Array<string>,
    indicator?: Array<string>,
    compartment?: Array<CompartmentDto>,
    subCompartment?: Array<SubCompartmentDto>,
  ): Observable<CharacterizationFactorSearchResult[]> {
    return this.characterizationFactorSearchApiService
      .searchCharacterizationFactors(
        q,
        method,
        group,
        indicator,
        compartment,
        subCompartment,
      )
      .pipe(
        map((characterizationFactors) =>
          this.characterizationFactorSearchResultMapper.fromList(
            characterizationFactors.results,
          ),
        ),
      );
  }

  exportSearchResults(
    q: string,
    methods: Array<string>,
    indicatorGroups: Array<string>,
    indicators: Array<string>,
    compartments: Array<CompartmentDto>,
    subCompartments: Array<SubCompartmentDto>,
  ): Observable<string> {
    const dto: CharacterizationFactorExportRequestDto = {
      q,
      methods,
      indicatorGroups,
      indicators,
      compartments,
      subCompartments,
    };
    return this.characterizationFactorSearchApiService.exportCharacterizationFactors(
      dto,
    );
  }
}
