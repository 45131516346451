import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectMultipleComponent } from '@app/modules/ui/modules/select/select-multiple/select-multiple.component';
import { SelectSearchComponent } from '@app/modules/ui/modules/select/select-search/select-search.component';
import { MaterialModule } from '@app/modules/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelectMultipleComponent, SelectSearchComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  exports: [SelectSearchComponent, SelectMultipleComponent],
})
export class SelectModule {}
