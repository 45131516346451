import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompartmentLabelPipe } from '@app/modules/reference-data/modules/substance/substance/pipes/compartment-label.pipe';
import { SubCompartmentLabelPipe } from '@app/modules/reference-data/modules/substance/substance/pipes/sub-compartment-label.pipe';

@NgModule({
  declarations: [CompartmentLabelPipe, SubCompartmentLabelPipe],
  imports: [CommonModule],
  exports: [CompartmentLabelPipe, SubCompartmentLabelPipe],
})
export class SubstanceModule {}
