import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-contribution-value[amount][referenceAmount][showPercentages]',
  templateUrl: './contribution-value.component.html',
  styleUrl: './contribution-value.component.scss',
})
export class ContributionValueComponent {
  @Input()
  amount: number | undefined;

  @Input()
  referenceAmount: number | undefined;

  @Input()
  showPercentages!: boolean;

  @Input()
  showBar = true;

  @Input()
  unit: string | undefined;

  get contributionAmount(): number {
    if (this.amount === undefined || this.referenceAmount === undefined) {
      return Number.NaN;
    }
    if (this.referenceAmount == 0) {
      return this.amount;
    }
    return this.amount / this.referenceAmount;
  }

  protected readonly Math = Math;
}
