export interface User {
  initials: string;
  connection: UserConnection;
  company: string;
}

export enum UserConnection {
  BCG = 'bcg-sso',
  KLEIS = 'kleis-sso',
  EXTERNAL = 'External',
}

export function isInternal(user: User): boolean {
  return [UserConnection.BCG, UserConnection.KLEIS].includes(user.connection);
}

export function isExternal(user: User): boolean {
  return !isInternal(user);
}
