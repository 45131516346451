import { Component, Input } from '@angular/core';
import { History } from '../../models/history.models';

@Component({
  selector: 'app-history-children',
  templateUrl: './history-children.component.html',
  styleUrl: './history-children.component.scss',
})
export class HistoryChildrenComponent {
  @Input()
  history!: History;
}
