import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CharacterizationFactorModule } from '@app/modules/characterization-factor/characterization-factor.module';
import { CharacterizationFactorSearchStoreModule } from '@app/modules/characterization-factor/store/characterization-factor-search-store.module';
import { LandingPageModule } from '@app/modules/landing-page/landing-page.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { UiModule } from '@app/modules/ui/ui.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { buildSpecificModules } from '../builds/build';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { BasketModule } from './modules/basket/basket.module';
import { BasketStoreModule } from './modules/basket/store/basket.module';
import { CoreModule } from './modules/core/core.module';
import { DatasetModule } from './modules/dataset/dataset.module';
import { DatasetSearchStoreModule } from './modules/dataset/store/dataset-search/dataset-search-store.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FooterComponent } from '@app/components/footer/footer.component';

@NgModule({
  declarations: [AppComponent, ToolbarComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule,
    UiModule,
    AuthModule.forRoot({
      errorPath: '/access-denied',
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        scope: 'openid profile email',
        audience: environment.auth0.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiUrl}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0.audience,
              },
            },
          },
        ],
      },
    }),
    LandingPageModule,
    DatasetModule,
    BasketModule,
    CharacterizationFactorModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    DatasetSearchStoreModule,
    BasketStoreModule,
    CharacterizationFactorSearchStoreModule,
    ...buildSpecificModules,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { position: 'above' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
