import { Component, OnInit } from '@angular/core';
import { isBlank } from '@app/modules/core/utils/string-utils';
import {
  DatasetSearchForm,
  SuggestionSelection,
} from '@app/modules/dataset/models/dataset-search.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchDatasets } from '../../store/dataset-search';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetSearchFormMapperService } from '@app/modules/dataset/mappers/dataset-search-form/dataset-search-form-mapper.service';
import { DatasetSearchSuggestionMapperService } from '@app/modules/dataset/mappers/dataset-search-suggestion/dataset-search-suggestion-mapper.service';
import { PageComponent } from '@app/modules/core/components/page-component';
import { RumService } from '@app/modules/rum/rum.service';

@Component({
  templateUrl: './datasets-search.component.html',
})
export class DatasetsSearchComponent implements PageComponent, OnInit {
  readonly pageName = 'datasets-search';

  showResultsDetails = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private datasetSearchFormMapperService: DatasetSearchFormMapperService,
    private datasetSearchSuggestionMapperService: DatasetSearchSuggestionMapperService,
    private rumService: RumService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        map(
          (params): DatasetSearchForm =>
            this.datasetSearchFormMapperService.fromQueryParams(params),
        ),
        filter((searchForm) => !isBlank(searchForm.searchTerm)),
      )
      .subscribe((searchForm) => {
        this.store.dispatch(searchDatasets({ searchForm }));
        this.rumService.triggerSearchEvent(searchForm);
      });
  }

  onFormSubmit(form: DatasetSearchForm): void {
    void this.router.navigate(['datasets'], {
      queryParams: this.datasetSearchFormMapperService.toQueryParams(form),
    });
  }

  onSuggestionSelection(suggestionSelection: SuggestionSelection): void {
    void this.router.navigate(['datasets'], {
      queryParams: this.datasetSearchSuggestionMapperService.toQueryParams(
        suggestionSelection,
        this.activatedRoute.snapshot.queryParams,
      ),
    });
  }

  onShowResultsDetailsChange(showResultsDetails: boolean): void {
    this.showResultsDetails = showResultsDetails;
  }
}
