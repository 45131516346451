import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitApiService } from '../api/unit-api.service';
import { UnitMapperService } from '../mappers/unit-mapper.service';
import { Unit } from '../models/unit.model';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(
    private unitApiService: UnitApiService,
    private mapper: UnitMapperService
  ) {}

  findUnitsAndSort(): Observable<Unit[]> {
    return this.unitApiService.getUnits().pipe(
      map((units) => this.mapper.fromList(units.results)),
      map((units) => units.sort((a, b) => a.name.localeCompare(b.name)))
    );
  }
}
