import { BehaviorSubject } from 'rxjs';

export class Filter<K extends string> {
  key: K;
  label: string;
  filteredAttributes?: string | string[];
  options$: BehaviorSubject<Array<FilterOption>>;
  value$: BehaviorSubject<Array<FilterOption>>;

  constructor(
    key: K,
    label: string,
    filteredAttributes?: string | string[],
    options?: BehaviorSubject<Array<FilterOption>>,
    value?: BehaviorSubject<Array<FilterOption>>
  ) {
    this.key = key;
    this.label = label;
    this.filteredAttributes = filteredAttributes;
    this.options$ = options ?? new BehaviorSubject([] as Array<FilterOption>);
    this.value$ = value ?? new BehaviorSubject([] as Array<FilterOption>);
  }
}

export interface FilterOption {
  key: string;
  value: string;
  filteredAttributes?: string | string[];
  group?: string;
  docCount?: number;
}
