import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Filter,
  FilterOption,
} from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { FiltersGroupService } from '@app/modules/ui/modules/filters-group/services/filters-group.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-filters-group',
  templateUrl: './filters-group.component.html',
  styleUrl: './filters-group.component.scss',
})
export class FiltersGroupComponent<K extends string>
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  filters: Array<Filter<K>> = [];
  @Input()
  refreshOnValueChange = true;

  @Output()
  filterChange = new EventEmitter<void>();

  form = new UntypedFormGroup({});

  get hasMultipleFilters(): boolean {
    return (
      this.filters.reduce(
        (count, filter) => count + filter.value$.value.length,
        0,
      ) >= 2
    );
  }

  private filterOptionsSubscriptions = new Map<K, Subscription>();

  private onDestroy$ = new Subject<void>();

  constructor(private filtersGroupService: FiltersGroupService<K>) {}

  ngOnInit(): void {
    Object.entries(
      this.filtersGroupService.toFormGroup(this.filters).controls,
    ).forEach(([name, control]) => {
      this.form.addControl(name, control);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('filters' in changes) {
      this.updateFiltersValueFromForm();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getForm(): FormGroup {
    return this.form;
  }

  onFilterChange(): void {
    this.filterChange.emit();
  }

  removeFilter(filter: Filter<K>, filterValueToRemove: FilterOption): void {
    const values = filter.value$.value.filter(
      (value) => value.key !== filterValueToRemove.key,
    );
    this.form.patchValue({
      [filter.key]: values.map((value) => value.key),
    });
    this.filterChange.emit();
  }

  removeAllFilters(): void {
    for (const filter of this.filters) {
      this.form.patchValue({ [filter.key]: [] });
    }
    this.filterChange.emit();
  }

  private updateFiltersValueFromForm() {
    for (const [, subscription] of this.filterOptionsSubscriptions) {
      subscription.unsubscribe();
    }
    this.filterOptionsSubscriptions.clear();
    for (const filter of this.filters) {
      const subscription = filter.options$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((options) => {
          const formValues = this.form.get(filter.key)
            ?.value as Array<string> | null;
          if (formValues) {
            const formValue = options.filter((f) => formValues.includes(f.key));
            filter.value$.next(formValue);
          }
        });
      this.filterOptionsSubscriptions.set(filter.key, subscription);
    }
  }
}
