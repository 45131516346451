import { Injectable } from '@angular/core';
import { ClassificationDto } from '@app/api/__generated__/model/classificationDto';
import { DatasetDescriptionDto } from '@app/api/__generated__/model/datasetDescriptionDto';
import { TimePeriodDto } from '@app/api/__generated__/model/timePeriodDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { DatabaseMapperService } from '@app/modules/reference-data/modules/database/mappers/database-mapper.service';
import { GeographyMapperService } from '@app/modules/reference-data/modules/geography/mappers/geography-mapper.service';
import {
  DatasetDescription,
  TechnologyLevel,
  TimePeriod,
} from '../../models/dataset.model';
import { ClassificationMapperService } from '@app/modules/reference-data/modules/classification/mappers/classification-mapper.service';
import {groupClassifications} from "@app/modules/reference-data/modules/classification/models/classification.model";

@Injectable({
  providedIn: 'root',
})
export class DatasetDescriptionMapperService extends AbstractMapper<
  DatasetDescriptionDto,
  DatasetDescription
> {
  constructor(
    private readonly databaseMapper: DatabaseMapperService,
    private readonly geographyMapper: GeographyMapperService,
    private readonly classificationMapper: ClassificationMapperService
  ) {
    super();
  }

  from(dto: DatasetDescriptionDto): DatasetDescription {
    const {
      activityName,
      activityType,
      includedActivitiesStart,
      includedActivitiesEnd,
      synonyms,
      technologyLevel,
      productionVolume,
      casNumber,
      modelling,
      author,
      database,
      generalComment,
      geography,
      timePeriod,
      classifications,
    } = dto;

    return {
      activityName,
      activityType,
      includedActivitiesStart: this.mapMultiLineText(includedActivitiesStart),
      includedActivitiesEnd: this.mapMultiLineText(includedActivitiesEnd),
      synonyms,
      technologyLevel: this.mapTechnologyLevel(technologyLevel),
      productionVolume,
      casNumber,
      modelling,
      author,
      database: this.databaseMapper.from(database),
      generalComment: this.mapMultiLineText(generalComment),
      geography: this.geographyMapper.from(geography),
      timePeriod: timePeriod && this.mapTimePeriod(timePeriod),
      classifications: groupClassifications(this.classificationMapper.fromList(classifications)),
      isic: this.mapIsic(classifications),
    };
  }

  private mapTimePeriod(timePeriodDto: TimePeriodDto): TimePeriod {
    const { startDate, endDate } = timePeriodDto;
    return {
      startDate,
      endDate,
    };
  }

  private mapTechnologyLevel(
    level: string | undefined
  ): TechnologyLevel | undefined {
    return level === undefined ? undefined : (level as TechnologyLevel);
  }

  private mapMultiLineText(text: string | undefined): string | undefined {
    return text ? text.replace(/\n/g, '<br />') : undefined;
  }

  private mapIsic(
    classifications: Array<ClassificationDto> | undefined
  ): string | undefined {
    return classifications
      ?.filter((c) => c.system == 'ISIC')
      .map((c) => c.code + ' - ' + c.description)
      .find(() => true);
  }
}
