import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';
import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { MethodIndicator } from '@app/modules/reference-data/modules/method/models/method.model';
import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import { SubstanceRefDto } from '@app/api/__generated__/model/substanceRefDto';

export type CharacterizationFactorSearchQuery = {
  searchTerm: string;
  filters: CharacterizationFactorsSearchFilters;
};

export type CharacterizationFactorsSearchFilters = {
  indicatorGroups: string[];
  indicators: string[];
  compartments: CompartmentDto[];
  subCompartments: SubCompartmentDto[];
};

export type CharacterizationFactorsSearchFiltersOptions = {
  indicatorGroups: FilterOption[];
  indicators: FilterOption[];
  compartments: FilterOption[];
  subCompartments: FilterOption[];
};

export const CHARACTERIZATION_FACTOR_SEARCH_INITIAL_FILTERS: CharacterizationFactorsSearchFilters =
  {
    indicatorGroups: [],
    indicators: [],
    compartments: [],
    subCompartments: [],
  };

export interface CharacterizationFactorSearchResult {
  id: string;
  indicator: MethodIndicator;
  substance: SubstanceRefDto;
  compartment: CompartmentDto;
  subCompartment: SubCompartmentDto;
  characterisationFactor: number;
  unit: string;
}
