import { CompartmentDto } from '@app/api/__generated__/model/compartmentDto';

export const COMPARTMENTS = new Map<CompartmentDto, string>([
  ['AIR', 'air'],
  ['DIRECT_HUMAN_UPTAKE', 'direct human uptake'],
  ['ECONOMIC', 'economic'],
  ['NATURAL_RESOURCE', 'natural resource'],
  ['SOCIAL', 'social'],
  ['SOIL', 'soil'],
  ['WASTE', 'waste'],
  ['WATER', 'water'],
]);
