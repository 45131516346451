import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { isInternal } from '@app/modules/auth/models/user.model';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthUserService } from '@app/modules/auth/services/auth-user.service';

@Component({
  selector: 'app-landing-page-model-section',
  templateUrl: './landing-page-model-section.component.html',
  styleUrl: './landing-page-model-section.component.scss',
})
export class LandingPageModelSectionComponent {
  constructor(
    private userService: AuthUserService,
    private router: Router,
  ) {}

  onModelActivities(): void {
    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        if (isInternal(user)) {
          window.open(`${environment.eQoterraUrl}/activity-modeling`);
        } else {
          void this.router.navigate(['custom-coming-soon']);
        }
      });
  }
}
