<app-history-current [dataset]="dataset"></app-history-current>
<div *ngIf="hasParents">
  <app-history-parents [history]="dataset.history"></app-history-parents>
</div>
<div *ngIf="hasChildren">
  <app-history-children [history]="dataset.history"></app-history-children>
</div>
<div *ngIf="hasEquivalentVersions">
  <app-history-equivalent-versions
    [dataset]="dataset"
  ></app-history-equivalent-versions>
</div>
