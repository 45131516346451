/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatasetSearchResultsDto } from '@app/api/__generated__/model/datasetSearchResultsDto';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { DatasetsSearchServiceInterface } from '@app/api/__generated__/api/datasetsSearch.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchApiService implements DatasetsSearchServiceInterface {
  private readonly datasetsSearchUrl = environment.apiUrl + '/datasets-search';

  constructor(private httpClient: HttpClient) {}

  searchDatasets(
    q?: string,
    geography?: Array<string>,
    database?: Array<string>,
    isic?: Array<string>,
    cpc?: Array<string>,
    activityType?: Array<string>,
    unit?: Array<string>,
  ): Observable<DatasetSearchResultsDto> {
    let queryParams = new HttpParams();

    if (q !== undefined) queryParams = queryParams.set('q', q);

    if (geography !== undefined && geography.length > 0)
      queryParams = queryParams.set('geography', geography.join(','));

    if (database !== undefined && database.length > 0)
      queryParams = queryParams.set('database', database.join(','));

    if (isic !== undefined && isic.length > 0)
      queryParams = queryParams.set('isic', isic.join(','));

    if (cpc !== undefined && cpc.length > 0)
      queryParams = queryParams.set('cpc', cpc.join(','));

    if (activityType !== undefined && activityType.length > 0)
      queryParams = queryParams.set('activityType', activityType.join(','));

    if (unit !== undefined && unit.length > 0)
      queryParams = queryParams.set('unit', unit.join(','));

    const httpOptions = { params: queryParams };

    return this.httpClient.get<DatasetSearchResultsDto>(
      this.datasetsSearchUrl,
      httpOptions,
    );
  }
}
