<h2>Equivalent versions</h2>
<table>
  <tbody>
    <tr *ngFor="let detail of dataset.history.equivalentVersions">
      <td [class.current-version]="isCurrent(detail)">
        {{ detail.database }}
      </td>
      <td [class.current-version]="isCurrent(detail)">
        <a [routerLink]="['/datasets', detail.productId]"
          >{{ detail.product }}
        </a>
      </td>
      <td>
        <app-impact-factor-sample-list
          [factorSamples]="detail.impactIndicatorSamples"
        ></app-impact-factor-sample-list>
      </td>
    </tr>
  </tbody>
</table>
