<app-dataset-warning
  [datasetDescription]="dataset.description"
></app-dataset-warning>
<h2>Contribution analysis</h2>
<div>
  <div>
    <app-contribution-filters [dataset]="dataset" (contributionChanged)="onContributionChange($event)"/>
  </div>

  <app-datasets-toggles
    [withAllocationModeSelection]="!!this.dataset.activity"
    [withPercentageToggle]="hasContribution"

    [referenceAmount]="dataset.referenceProduct.exchanges.toProduct.amount"
    [referenceUnit]="dataset.referenceProduct.exchanges.toProduct.unit"

    [(allocationMode)]="allocationMode"
    [(showPercentages)]="showPercentages"
  ></app-datasets-toggles>
</div>
<app-exchange-table class="exchanges"
  [dataset]="dataset"
  [methodIndicator]="selection.indicator ?? undefined"
  [contributionType]="selection.contributionType ?? undefined"
  [showPercentages]="showPercentages"
  [exchanges]="exchanges"
  [showActivity]="allocationMode === AllocationMode.WHOLE_ACTIVITY"
></app-exchange-table>
<ng-container
  *ngIf="
    selection.indicator !== null &&
    selection.contribution !== null &&
    hasContribution
  "
>
  <h2>Main contribution flows (above 1%)</h2>
  <app-sankey
    [datasetId]="dataset.id"
    [referenceProduct]="dataset.referenceProduct.name"
    [indicator]="selection.indicator"
    [contributionType]="selection.contribution"
  ></app-sankey>
</ng-container>
