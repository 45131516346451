/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { UnitsDto } from '@app/api/__generated__/model/unitsDto';
import { UnitsServiceInterface } from '@app/api/__generated__/api/units.service';

@Injectable({
  providedIn: 'root',
})
export class UnitApiService implements UnitsServiceInterface {
  private readonly unitsUrl = environment.apiUrl + '/units';

  constructor(private httpClient: HttpClient) {}

  getUnits(): Observable<UnitsDto> {
    return this.httpClient.get<UnitsDto>(this.unitsUrl);
  }
}
