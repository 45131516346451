import { ImpactAssessmentSample } from '../../../models/dataset.model';

export class History {
  constructor(
    public parentDatabase: string,
    public parents: Array<HistoryDetail>,
    public childDatabase: string,
    public children: Array<HistoryDetail>,
    public equivalentVersions: Array<HistoryDetail>
  ) {}

  get hasHistory(): boolean {
    return this.parentDatabase !== null || this.childDatabase !== null;
  }
}

export interface HistoryDetail {
  database: string;
  product: string;
  productId: string;
  impactIndicatorSamples: Array<ImpactAssessmentSample>;
  status: HistoryStatus;
}

export enum HistoryStatus {
  INHERIT = 'INHERIT',
  MERGE = 'MERGE',
  SPLIT = 'SPLIT',
}
