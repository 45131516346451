import {CompartmentDto} from "@app/api/__generated__/model/compartmentDto";
import {SubCompartmentDto} from "@app/api/__generated__/model/subCompartmentDto";

export interface ExchangeRecord {
  notDataset?: boolean;
  id: string;
  navigable: boolean;
  name: string;
  compartment?:CompartmentDto;
  subCompartment?:SubCompartmentDto;
  allocation?: number;
  amount: number;
  unit: string;
  comment?: string;
  warnings: string[];
  assessments: Assessment[];
  showBar: boolean;
}

export interface Assessment {
  displayName : string;
  key: string;
  amount: number | undefined;
}

export interface ExchangeHeader {
  title: string;
  detail1?:string;
  detail2?:string;
  hasQuantity: boolean;
  assessments: string[];
  embedded: string[];
}

export interface ProductHeader {
  detail2:string;
  hasQuantity: boolean;
}

export interface TechnosphereHeader {
  hasQuantity: boolean;
}
export interface BiosphereHeader {
  detail1: string;
  detail2: string;
  hasQuantity: boolean;
}

export const productHeaderTemplate: ProductHeader = {
  detail2: "Allocation",
  hasQuantity: true,
};

export const technosphereHeaderTemplate: TechnosphereHeader = {
  hasQuantity: true,
};

export const biosphereHeaderTemplate: BiosphereHeader = {
  detail1:"Compartment",
  detail2:"Subcompartment",
  hasQuantity: true,
};
