import { Pipe, PipeTransform } from '@angular/core';
import { ACTIVITY_TYPES } from '@app/modules/reference-data/modules/activity-type/models/activity-types.model';

@Pipe({
  name: 'activityTypeEnumToName',
})
export class ActivityTypeEnumToNamePipe implements PipeTransform {
  static readonly ACTIVITY_TYPES_MAP = ACTIVITY_TYPES.reduce(
    (map, activityType) => {
      map.set(activityType.value, activityType.name);
      return map;
    },
    new Map<string, string>()
  );

  transform(value: string | undefined): string | undefined {
    return ActivityTypeEnumToNamePipe.ACTIVITY_TYPES_MAP.get(value ?? '');
  }
}
