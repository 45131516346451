/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatasetsServiceInterface } from '@app/api/__generated__/api/datasets.service';
import { DatasetDto } from '@app/api/__generated__/model/datasetDto';
import { DatasetsDto } from '@app/api/__generated__/model/datasetsDto';
import { ExportRequestDto } from '@app/api/__generated__/model/exportRequestDto';
import { ListDatasetRequestDto } from '@app/api/__generated__/model/listDatasetRequestDto';
import { TreeDto } from '@app/api/__generated__/model/treeDto';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatasetApiService implements DatasetsServiceInterface {
  private readonly datasetsUrl = environment.apiUrl + '/datasets';

  constructor(private readonly httpClient: HttpClient) {}

  exportDatasetsAsXlsx(exportRequestDto: ExportRequestDto): Observable<string> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.post<string>(
      this.datasetsUrl + '/export',
      exportRequestDto,
      httpOptions
    );
  }

  getDataset(id: string): Observable<DatasetDto> {
    return this.httpClient.get<DatasetDto>(this.datasetUrl(id));
  }

  getDatasetWithContribution(
    id: string,
    indicators: Array<string>
  ): Observable<DatasetDto> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('indicatorKeyIds', indicators.join(','));
    return this.httpClient.get<DatasetDto>(this.datasetUrl(id), {
      params: queryParams,
    });
  }

  getDatasetWithEmbeddedProduct(
    id: string,
    indicators: Array<string>
  ): Observable<DatasetDto> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('indicatorKeyIds', indicators.join(','));
    return this.httpClient.get<DatasetDto>(this.datasetUrl(id), {
      params: queryParams,
    });
  }

  getContributionTree(
    keyId: string,
    name: string,
    indicatorKeyId?: string
  ): Observable<TreeDto> {
    if (name === 'DIRECT' && indicatorKeyId === undefined)
      return of({ nodes: [], links: [] } as TreeDto);

    let queryParams = new HttpParams();

    if (name !== undefined) queryParams = queryParams.set('type', name);
    if (indicatorKeyId !== undefined)
      queryParams = queryParams.set('indicatorKeyId', indicatorKeyId);

    const httpOptions = { params: queryParams };
    const url = `${this.datasetUrl(keyId)}/contribution-tree`;
    return this.httpClient.get<TreeDto>(url, httpOptions);
  }

  getDatasets(
    listDatasetRequestDto?: ListDatasetRequestDto | undefined
  ): Observable<DatasetsDto> {
    return this.httpClient.post<DatasetsDto>(
      this.datasetsUrl,
      listDatasetRequestDto
    );
  }

  private datasetUrl(id: string): string {
    return `${this.datasetsUrl}/${id}`;
  }
}
