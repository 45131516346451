import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-warn',
  templateUrl: './warn.component.html',
  styleUrl: './warn.component.scss',
})
export class WarnComponent {
  @Input()
  text: string | undefined;

  @Input()
  toolTip = '';
}
