import { Component, Input } from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';

@Component({
  selector: 'app-history-current[dataset]',
  templateUrl: './history-current.component.html',
  styleUrl: './history-current.component.scss',
})
export class HistoryCurrentComponent {
  @Input()
  dataset!: Dataset;

  get hasNoParent(): boolean {
    return (
      this.dataset.history.parentDatabase !== null &&
      this.dataset.history.parentDatabase !== undefined &&
      this.dataset.history.parents.length === 0
    );
  }

  get hasNoChild(): boolean {
    return (
      this.dataset.history.childDatabase !== null &&
      this.dataset.history.childDatabase !== undefined &&
      this.dataset.history.children.length === 0
    );
  }
}
