import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeographiesServiceInterface } from '@app/api/__generated__/api/geographies.service';
import { GeographiesDto } from '@app/api/__generated__/model/geographiesDto';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeographyApiService implements GeographiesServiceInterface {
  private readonly geographiesUrl = environment.apiUrl + '/geographies';

  constructor(private httpClient: HttpClient) {}

  getGeographies(query?: string): Observable<GeographiesDto> {
    let queryParams = new HttpParams();

    if (query === 'eol') queryParams = queryParams.set('geographyType', query);
    const httpOptions = { params: queryParams };

    return this.httpClient.get<GeographiesDto>(
      this.geographiesUrl,
      httpOptions
    );
  }
}
