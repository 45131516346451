import { Injectable } from '@angular/core';
import { MethodDto } from '@app/api/__generated__/model/methodDto';
import { MethodIndicatorDto } from '@app/api/__generated__/model/methodIndicatorDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { Method, MethodIndicator } from '../models/method.model';
import {
  ContributionTypeMapperService
} from "@app/modules/reference-data/modules/contribution-type/mappers/contribution-type-mapper.service";

@Injectable({
  providedIn: 'root',
})
export class MethodMapperService extends AbstractMapper<MethodDto, Method> {

  constructor(private readonly contributionTypeMapperService: ContributionTypeMapperService) {
    super();
  }

  from(dto: MethodDto | undefined): Method {
    if (dto === undefined || dto === null) {
      return {} as Method;
    }
    return new Method(
      dto.id,
      dto.name,
      dto.position,
      dto.indicators.map((indicator) => this.mapIndicator(indicator, dto.name))
    );
  }

  mapIndicator(dto: MethodIndicatorDto, methodName: string): MethodIndicator {
    return new MethodIndicator(
      dto.id,
      dto.name,
      dto.unit,
      methodName,
      dto.groups,
      dto.directContributionType !== undefined ? this.contributionTypeMapperService.from(dto.directContributionType) : undefined,
    );
  }
}
