import { Injectable } from '@angular/core';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import { ClassificationDto } from '@app/api/__generated__/model/classificationDto';
import { Classification } from '@app/modules/reference-data/modules/classification/models/classification.model';

@Injectable({
  providedIn: 'root',
})
export class ClassificationMapperService extends AbstractMapper<
  ClassificationDto,
  Classification
> {
  constructor() {
    super();
  }

  from(dto: ClassificationDto | undefined): Classification {
    if (dto === undefined) {
      throw new Error('classification dto is undefined');
    }
    const { system, code, description } = dto;
    return {
      system: this.displaySystem(system),
      displayDescription: this.displayDescription(dto),
      code,
      description,
    } as Classification;
  }

  private displaySystem(system: string): string {
    switch (system) {
      case 'ACTIVITY_CATEGORY':
        return 'Activity category';
      case 'PRODUCTION':
        return 'Production';
      case 'AGRICULTURAL':
        return 'Agricultural';
      default:
        return system;
    }
  }

  private displayDescription(dto: ClassificationDto) {
    switch (dto.system) {
      case 'ACTIVITY_CATEGORY':
        // Code for ACTIVITY_CATEGORY doesn't bring value
        return dto.description;
      default:
        return dto.code + ' - ' + dto.description;
    }
  }
}
