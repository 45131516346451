<div
  *ngIf="amount !== undefined; else notApplicable"
  [class.bar]="showBar"
  [class.small-value]="Math.abs(contributionAmount) <= 0.01"
  [class.negative-value]="contributionAmount < -0.01"
  [style.width]="Math.abs(contributionAmount) * 100 + '%'"
>
  <ng-container *ngIf="showPercentages">
    {{ contributionAmount | percent : "1.2" }}
  </ng-container>
  <ng-container *ngIf="!showPercentages && !unit" >
    {{ amount | displayAmount }}
  </ng-container>
  <ng-container *ngIf="!showPercentages && unit" >
    {{ amount | displayAmount | default: "N/A" }}
    {{ unit | formatUnit }}
  </ng-container>
</div>
<ng-template #notApplicable> N/A</ng-template>
