import { Component, Input } from '@angular/core';

@Component({
  selector: '[exchangeComment][comment][totalNbColumns]',
  templateUrl: './exchange-comment.component.html',
  styleUrl: './exchange-comment.component.scss',
})
export class ExchangeCommentComponent {
  @Input()
  comment!: string | undefined;

  @Input()
  totalNbColumns!: number;

  @Input()
  type: 'info' | 'warn' = 'info';
}
