<ng-container
  *ngIf="text.length > 500; then toggleable; else static"
></ng-container>
<ng-template #toggleable>
  <ng-container *ngIf="isOpened; then opened; else closed"></ng-container>
  <ng-template #opened>
    <span class="text" (click)="isOpened = false">{{
      makeMultiLineTextAsOneLine
    }}</span>
  </ng-template>
  <ng-template #closed>
    <span class="text" (click)="isOpened = true">{{
      makeMultiLineTextAsOneLine | shorten : 500 : "..."
    }}</span>
  </ng-template>
</ng-template>
<ng-template #static>
  {{ text }}
</ng-template>
