import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatasetSearchResult } from '@app/modules/dataset/models/dataset-search.model';
import { ConfirmDialogComponent } from '@app/modules/ui/components/confirm-dialog/confirm-dialog.component';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as datasetActions from '../../store/dataset/basket-dataset.actions';
import * as datasetSelectors from '../../store/dataset/basket-dataset.selectors';
import { BasketDialogService } from '@app/modules/basket/services/basket-dialog.service';

@Component({
  templateUrl: './view-basket.component.html',
  styleUrl: './view-basket.component.scss',
})
export class ViewBasketComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  datasets: DatasetSearchResult[] = [];

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private basketDialogService: BasketDialogService,
  ) {}

  ngOnInit(): void {
    this.store
      .select(datasetSelectors.selectAll)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((datasets) => {
        this.datasets = datasets;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm clear basket',
        message: 'Are you sure you want to clear your basket?',
      },
    });

    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.clearBasket();
      }
    });
  }

  clearBasket(): void {
    this.store.dispatch(datasetActions.emptyBasket());
  }

  openImportDialog(): void {
    this.basketDialogService.openImportDialog();
  }

  openExportDialog(): void {
    this.basketDialogService.openExportDialog();
  }

  openUpgradeDialog(): void {
    this.basketDialogService.openUpgradeDialog();
  }

  get hasBasketContent(): boolean {
    return this.datasets.length > 0;
  }
}
