import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {DatasetWarningModule} from '@app/modules/dataset/modules/dataset-warning/dataset-warning.module';
import {
  ExchangeTabComponent
} from '@app/modules/dataset/modules/exchange/components/exchange-tab/exchange-tab.component';
import {ExchangeMapperService} from '@app/modules/dataset/modules/exchange/mappers/exchange-mapper.service';
import {SankeyModule} from '@app/modules/dataset/modules/exchange/modules/sankey/sankey.module';
import {MaterialModule} from '@app/modules/material/material.module';
import {ReferenceDataModule} from '@app/modules/reference-data/reference-data.module';
import {UiModule} from '@app/modules/ui/ui.module';
import {
  ContributionValueComponent
} from './components/exchange-tab/exchange-table/contribution-value/contribution-value.component';
import {ExchangeTableComponent} from './components/exchange-tab/exchange-table/exchange-table.component';
import {
  ExchangeDetailsSubtotalComponent
} from './components/exchange-tab/exchange-table/exchange-details-subtotal/exchange-details-subtotal.component';
import {
  ExchangeCommentComponent
} from './components/exchange-tab/exchange-table/exchange-comment/exchange-comment.component';
import {
  ExchangeCommentToggleComponent
} from './components/exchange-tab/exchange-table/exchange-comment-toggle/exchange-comment-toggle.component';
import {
  ExchangeLabelWarningComponent
} from './components/exchange-tab/exchange-table/exchange-label-warning/exchange-label-warning.component';
import {
  ExchangeWarningComponent
} from './components/exchange-tab/exchange-table/exchange-warning/exchange-warning.component';
import {
  ContributionNameFilterComponent
} from '@app/modules/dataset/modules/exchange/components/exchange-tab/contribution-name-filter/contribution-name-filter.component';
import {ContributionModule} from '@app/modules/dataset/modules/contribution/contribution.module';
import {
  GroupFilterComponent
} from '@app/modules/dataset/modules/exchange/components/exchange-tab/group-filter/group-filter.component';
import {
  IndicatorFilterComponent
} from '@app/modules/dataset/modules/exchange/components/exchange-tab/indicator-filter/indicator-filter.component';
import {
  ContributionTypeFilterComponent
} from '@app/modules/dataset/modules/exchange/components/exchange-tab/contribution-type-filter/contribution-type-filter.component';
import {
  ContributionSelectionModule
} from '@app/modules/dataset/modules/contribution-selection/contribution-selection.module';
import {NgStringPipesModule} from 'ngx-pipes';
import {
  ContributionFiltersComponent
} from "@app/modules/dataset/modules/exchange/components/exchange-tab/contribution-filters/contribution-filters.component";
import {FlexModule} from "@ngbracket/ngx-layout";
import {
  ExchangeLineRecordComponent
} from "@app/modules/dataset/modules/exchange/components/exchange-line-record/exchange-line-record.component";
import {
  ExchangeLineHeaderComponent
} from "@app/modules/dataset/modules/exchange/components/exchange-line-header/exchange-line-header.component";
import {SharedModule} from "@app/modules/shared/shared.module";


@NgModule({
  declarations: [
    ExchangeLineHeaderComponent,
    ExchangeLineRecordComponent,
    ExchangeTabComponent,
    ExchangeTableComponent,
    ContributionValueComponent,
    ExchangeDetailsSubtotalComponent,
    ExchangeCommentComponent,
    ExchangeCommentToggleComponent,
    ExchangeLabelWarningComponent,
    ExchangeWarningComponent,
    ContributionNameFilterComponent,
    GroupFilterComponent,
    IndicatorFilterComponent,
    ContributionTypeFilterComponent,
    ContributionFiltersComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReferenceDataModule,
    SankeyModule,
    UiModule,
    NgStringPipesModule,
    DatasetWarningModule,
    ReactiveFormsModule,
    ContributionModule,
    ContributionSelectionModule,
    FlexModule,
    FormsModule,
    SharedModule,
  ],
  providers: [ExchangeMapperService, DecimalPipe],
  exports: [ExchangeTabComponent, ContributionValueComponent, ExchangeLineHeaderComponent, ExchangeLineRecordComponent, ExchangeDetailsSubtotalComponent],
})
export class ExchangeModule {}
