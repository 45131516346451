import { Component, Input } from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';
import { HistoryDetail } from '@app/modules/dataset/modules/history/models/history.models';

@Component({
  selector: 'app-history-equivalent-versions',
  templateUrl: './history-equivalent-versions.component.html',
  styleUrl: './history-equivalent-versions.component.scss',
})
export class HistoryEquivalentVersionsComponent {
  @Input()
  dataset!: Dataset;

  isCurrent(detail: HistoryDetail): boolean {
    return detail.productId === this.dataset.id;
  }
}
