import { Pipe, PipeTransform } from '@angular/core';
import { GeographyDto } from '@app/api/__generated__/model/geographyDto';

@Pipe({
  name: 'displayGeography',
})
export class DisplayGeographyPipe implements PipeTransform {
  transform(geography: GeographyDto): string {
    if (geography.name === undefined || geography.shortName === undefined) {
      return '';
    }
    return `${geography.name} - ${geography.shortName}`;
  }
}
