import {IndicatorGroup} from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';
import {MethodIndicator} from '@app/modules/reference-data/modules/method/models/method.model';
import {
  ContributionType,
  ContributionTypeName
} from '@app/modules/dataset/modules/contribution/model/contribution.model';

export interface ContributionSelection {
  group: IndicatorGroup | null;
  indicator: MethodIndicator | null;
  contributionType: ContributionTypeName | null;
  contribution: ContributionType | null;
}

export function emptySelection(): ContributionSelection {
  return {
    group: null,
    indicator: null,
    contributionType: null,
    contribution: null,
  };
}

export function isCompleteSelection(selection: ContributionSelection): boolean {
  return selection.group != null
    && selection.indicator != null
    && selection.contributionType != null
    && selection.contribution != null;
}

export function resetGroup(group: IndicatorGroup | null): ContributionSelection {
  return {...emptySelection(), group}
}

export function resetIndicator(selection: ContributionSelection, indicator: MethodIndicator | null): ContributionSelection {
  return {...emptySelection(), group: selection.group, indicator };
}

export function resetContributionType(selection: ContributionSelection, contributionType: ContributionTypeName | null): ContributionSelection {
  return {...emptySelection(), group: selection.group, indicator: selection.indicator, contributionType};
}

export function selectContribution(selection: ContributionSelection, contribution: ContributionType | null): ContributionSelection {
  return {...selection, contribution};
}
