import { NgModule } from '@angular/core';
import {DatasetsTogglesComponent} from "@app/modules/shared/components/datasets-toggles/datasets-toggles.component";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {FlexModule} from "@ngbracket/ngx-layout";

@NgModule({
  imports: [
    MatRadioGroup,
    MatRadioButton,
    MatSlideToggle,
    FormsModule,
    NgIf,
    FlexModule
  ],
  declarations: [
    DatasetsTogglesComponent
  ],
  exports: [
    DatasetsTogglesComponent,
  ],
})
export class SharedModule {}
