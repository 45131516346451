import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  datasetBasketFeatureKey,
  datasetBasketReducer,
} from './dataset/basket-dataset.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BasketEffects } from '@app/modules/basket/store/dataset/basket-dataset.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(datasetBasketFeatureKey, datasetBasketReducer),
    EffectsModule.forFeature([BasketEffects]),
  ],
  providers: [],
})
export class BasketStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: BasketStoreModule) {
    if (parentModule) {
      throw new Error('BasketStoreModule is already loaded');
    }
  }
}
