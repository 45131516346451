<div class="basket">
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
    <h1>My Basket</h1>
    <button
      mat-raised-button
      class="import-button"
      (click)="openImportDialog()"
    >
      <mat-icon>file_upload</mat-icon>
      Import
    </button>
    <button
      mat-raised-button
      (click)="openExportDialog()"
      [disabled]="!hasBasketContent"
    >
      <mat-icon>file_download</mat-icon>
      Export
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="openUpgradeDialog()"
      [disabled]="!hasBasketContent"
    >
      <mat-icon>upgrade</mat-icon>
      Upgrade
    </button>
    <button
      mat-raised-button
      (click)="openConfirmDialog()"
      [disabled]="!hasBasketContent"
      class="import-button"
    >
      <mat-icon>delete</mat-icon>
      Clear all
    </button>
  </div>
  <div class="basket-content">
    <ng-container *ngIf="hasBasketContent; else basketEmpty">
      <app-basket-dataset-tab></app-basket-dataset-tab>
    </ng-container>
    <ng-template #basketEmpty>No datasets in basket yet</ng-template>
  </div>
</div>
