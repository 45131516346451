import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryTabComponent } from '@app/modules/dataset/modules/history/components/history-tab/history-tab.component';
import { RouterModule } from '@angular/router';
import { HistoryParentsComponent } from './components/history-parents/history-parents.component';
import { HistoryStatusLabelForChildPipe } from './pipes/history-status-label-for-child.pipe';
import { HistoryChildrenComponent } from './components/history-children/history-children.component';
import { HistoryCurrentComponent } from './components/history-current/history-current.component';
import { HistoryStatusLabelForParentPipe } from './pipes/history-status-label-for-parent.pipe';
import { ImpactFactorModule } from '../impact-factor/impact-factor.module';
import { HistoryEquivalentVersionsComponent } from '@app/modules/dataset/modules/history/components/history-equivalent-versions/history-equivalent-versions.component';
import { MatListModule } from '@angular/material/list';
import { NgStringPipesModule } from 'ngx-pipes';

@NgModule({
  declarations: [
    HistoryTabComponent,
    HistoryParentsComponent,
    HistoryChildrenComponent,
    HistoryCurrentComponent,
    HistoryEquivalentVersionsComponent,
    HistoryStatusLabelForParentPipe,
    HistoryStatusLabelForChildPipe,
  ],
  imports: [
    CommonModule,
    ImpactFactorModule,
    RouterModule,
    NgStringPipesModule,
    MatListModule,
  ],
  exports: [HistoryTabComponent],
})
export class HistoryModule {}
