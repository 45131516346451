import { Injectable } from '@angular/core';
import { ImpactIndicatorDto } from '@app/api/__generated__/model/impactIndicatorDto';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import {
  ImpactAssessmentSample,
  ImpactIndicator,
} from '@app/modules/dataset/models/dataset.model';
import { ImpactIndicatorSampleDto } from '@app/api/__generated__/model/impactIndicatorSampleDto';

@Injectable({
  providedIn: 'root',
})
export class IndicatorMapperService extends AbstractMapper<
  ImpactIndicatorDto,
  ImpactIndicator
> {
  from(dto: ImpactIndicatorDto): ImpactIndicator {
    const { id, categoryName, unitName, amount, groups } = dto;
    return {
      id,
      categoryName,
      unitName,
      amount,
      groups,
    };
  }

  mapIndicatorSample(dto: ImpactIndicatorSampleDto): ImpactAssessmentSample {
    const { methodName, indicator } = dto;
    return {
      methodName,
      indicator: this.from(indicator),
    };
  }
}
