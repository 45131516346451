import { Component, Input } from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';

@Component({
  selector: 'app-history-tab[dataset]',
  templateUrl: './history-tab.component.html',
  styles: [],
})
export class HistoryTabComponent {
  @Input()
  dataset!: Dataset;

  get hasParents(): boolean {
    return this.dataset.history.parents.length > 0;
  }

  get hasChildren(): boolean {
    return this.dataset.history.children.length > 0;
  }

  get hasEquivalentVersions(): boolean {
    return this.dataset.history.equivalentVersions.length > 0;
  }
}
