import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterOption } from '@app/modules/landing-page/components/landing-page-search-section/filter/filter.model';

@Component({
  selector: 'app-filter[label]',
  templateUrl: './filter.component.html',
})
export class FilterComponent implements OnChanges {
  @Input()
  label!: string;

  @Input()
  options: Array<FilterOption> = [];
  options$: Observable<Array<FilterOption>> = EMPTY;

  @Input()
  value: Array<string> = [];

  @Output()
  valueChange = new EventEmitter<Array<string>>();

  filter$ = new BehaviorSubject<string>('');

  ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.options$ = combineLatest([of(this.options), this.filter$]).pipe(
        map(([options, filter]) =>
          options.filter((option) =>
            option.label.toLowerCase().includes(filter.toLowerCase())
          )
        )
      );
    }
  }

  onValueChange(value: Array<string>): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  onFilterChange(filter: string): void {
    this.filter$.next(filter);
  }
}
