<h2>{{ usedBy }}</h2>
<table>
  <tr *ngFor="let parent of dataset.parents">
    <td class="label">
      <a
        *ngIf="parent.datasetId && parent.navigable; else onlyLabel"
        routerLinkActive="active"
        [routerLink]="['/datasets', parent.datasetId]"
        >{{ parent.name }}</a
      >
      <ng-template #onlyLabel
        >{{ parent.name }} (no background data available)
      </ng-template>
    </td>
  </tr>
</table>
