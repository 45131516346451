import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { MethodMapperService } from '../../method/mappers/method-mapper.service';
import { IndicatorGroupApiService } from '../api/indicator-group-api.service';
import {
  IndicatorGroup,
  IndicatorGroupStatus,
} from '../models/indicator-group.model';
import { GroupDto } from '@app/api/__generated__/model/groupDto';

@Injectable({
  providedIn: 'root',
})
export class IndicatorGroupService {
  constructor(
    private readonly apiService: IndicatorGroupApiService,
    private readonly methodMapper: MethodMapperService
  ) {}

  getIndicatorGroups(): Observable<IndicatorGroup[]> {
    return this.apiService.getIndicatorGroups().pipe(
      map((groupsDto) =>
        groupsDto.results.map((groupDto: GroupDto) => {
          const { name, indicators, position, status } = groupDto;
          const methodIndicators = indicators.map((indicator) =>
            this.methodMapper.mapIndicator(indicator, name)
          );
          return {
            name,
            position,
            indicators: methodIndicators,
            status: this.map(status),
          };
        })
      )
    );
  }

  private map(status: 'LIVE' | 'OBSOLETE') {
    return status === 'LIVE'
      ? IndicatorGroupStatus.LIVE
      : IndicatorGroupStatus.OBSOLETE;
  }
}
