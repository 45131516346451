<div class="characterization-factors-search">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="search-bar">
      <mat-form-field fxFlex class="search-field" color="primary">
        <mat-label>Search</mat-label>
        <input formControlName="searchTerm" type="text" matInput />
        <button type="submit" matSuffix mat-icon-button aria-label="Search">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <app-filters-group
      [filters]="filters"
      (filterChange)="onSubmit()"
    ></app-filters-group>
  </form>
  <ng-container *ngIf="dataSource.data.length > 0; else noResults">
    <div class="results">
      <div class="export-section">
        <button
          color="primary"
          *ngIf="dataSource.data.length > 0"
          mat-raised-button
          (click)="export()"
        >
          Export results
        </button>
        <i
          *ngIf="dataSource.data.length >= resultsLimit"
          class="export-warning"
        >
          Only the first {{ resultsLimit }} results of your search will be
          exported
        </i>
      </div>
      <i class="results-amount">
        {{ resultsAmount + " " + resultsString }} found
      </i>
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef>Method</th>
          <td class="value" mat-cell *matCellDef="let item">
            {{ item.indicator.method }}
          </td>
        </ng-container>
        <ng-container matColumnDef="indicator">
          <th mat-header-cell *matHeaderCellDef>Indicator</th>
          <td class="value" mat-cell *matCellDef="let item">
            {{ item.indicator.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="substance">
          <th mat-header-cell *matHeaderCellDef>Substance</th>
          <td class="value" mat-cell *matCellDef="let item">
            <app-copiable-id
              size="small"
              [copiableId]="item.substance.id"
              tooltipPosition="right"
            >
              {{ item.substance.name }}
            </app-copiable-id>
          </td>
        </ng-container>
        <ng-container matColumnDef="compartment">
          <th mat-header-cell *matHeaderCellDef>Compartment</th>
          <td class="value" mat-cell *matCellDef="let item">
            {{ item.compartment | compartmentLabel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="subCompartment">
          <th mat-header-cell *matHeaderCellDef>Subcompartment</th>
          <td class="value" mat-cell *matCellDef="let item">
            {{ item.subCompartment | subCompartmentLabel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="factor">
          <th mat-header-cell *matHeaderCellDef>Factor</th>
          <td class="value" mat-cell *matCellDef="let item">
            {{ item.characterisationFactor }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef>Unit</th>
          <td class="value" mat-cell *matCellDef="let item">
            {{ item.indicator.unit | formatUnit }} /
            {{ item.unit | formatUnit }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </ng-container>
  <ng-template #noResults>
    <div *ngIf="firstQuerySent" class="no-results">
      <p fxLayoutAlign="start center">
        <mat-icon class="disabled-text-color">search_off</mat-icon>
        Your search did not match any characterization factors.
      </p>
    </div>
  </ng-template>
</div>
