import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RouterModule } from '@angular/router';
import { DatasetDetailHeaderComponent } from '@app/modules/dataset/modules/dataset-detail/components/dataset-detail-header/dataset-detail-header.component';
import { ImpactFactorsTabComponent } from '@app/modules/dataset/modules/dataset-detail/components/impact-factors-tab/impact-factors-tab.component';
import { InfoTabComponent } from '@app/modules/dataset/modules/dataset-detail/components/info-tab/info-tab.component';
import { DatasetDetailComponent } from '@app/modules/dataset/modules/dataset-detail/pages/dataset-detail/dataset-detail.component';
import { DatasetWarningModule } from '@app/modules/dataset/modules/dataset-warning/dataset-warning.module';
import { MaterialModule } from '@app/modules/material/material.module';
import { ReferenceDataModule } from '@app/modules/reference-data/reference-data.module';
import { UiModule } from '@app/modules/ui/ui.module';
import { HistoryModule } from '../history/history.module';
import { LabelTechnologyLevelPipe } from './pipes/label-technology-level.pipe';
import { ParentModule } from '@app/modules/dataset/modules/parent/parent.module';
import { ExchangeModule } from '@app/modules/dataset/modules/exchange/exchange.module';
import { EmbeddedProductModule } from '../embedded-product/embedded-product.module';
import { ImpactFactorsTableComponent } from '@app/modules/dataset/modules/dataset-detail/components/impact-factors-tab/impact-factors-table/impact-factors-table.component';
import { CdkTableModule } from '@angular/cdk/table';
import { ContributionModule } from '@app/modules/dataset/modules/contribution/contribution.module';

@NgModule({
  declarations: [
    DatasetDetailComponent,
    DatasetDetailHeaderComponent,
    ImpactFactorsTabComponent,
    ImpactFactorsTableComponent,
    InfoTabComponent,
    LabelTechnologyLevelPipe,
  ],
  imports: [
    CommonModule,
    ExchangeModule,
    HistoryModule,
    EmbeddedProductModule,
    ParentModule,
    FlexLayoutModule,
    MaterialModule,
    UiModule,
    ReferenceDataModule,
    DatasetWarningModule,
    RouterModule,
    CdkTableModule,
    ContributionModule,
  ],
  exports: [DatasetDetailComponent],
})
export class DatasetDetailModule {}
