import { Injectable } from '@angular/core';
import { SubstancesApiService } from '@app/modules/reference-data/modules/substance/substance/api/substances-api.service';
import { HierarchicCompartment } from '@app/modules/reference-data/modules/substance/substance/models/hierarchic-compartment.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubstancesService {
  constructor(private substanceApiService: SubstancesApiService) {}

  getCompartments(): Observable<HierarchicCompartment[]> {
    return this.substanceApiService
      .getCompartments()
      .pipe(
        map((c) =>
          c.hierarchicCompartments.map(
            (hc) =>
              new HierarchicCompartment(hc.compartment, hc.subCompartments)
          )
        )
      );
  }
}
