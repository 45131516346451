import { Injectable } from '@angular/core';
import { AbstractMapper } from '@app/modules/core/mappers/abstract-mapper';
import {ContributionTypeDto} from "@app/api/__generated__/model/contributionTypeDto";
import {
  ContributionType,
  ContributionTypeName
} from "@app/modules/dataset/modules/contribution/model/contribution.model";

@Injectable({
  providedIn: 'root',
})
export class ContributionTypeMapperService extends AbstractMapper<ContributionTypeDto, ContributionType> {

  constructor() {
    super();
  }

  from(dto: ContributionTypeDto | undefined): ContributionType {
    if (dto === undefined || dto === null) {
      throw new Error('contribution type dto is undefined');
    }
    return {
      name: dto.name as ContributionTypeName,
      displayName: dto.displayName,
      family: dto.family,
      position: dto.position,
      children: this.fromList(dto.children)
    }
  }

}
