export abstract class AbstractMapper<T, U> {
  abstract from(dto: T | undefined): U;

  fromList(dtoList: T[] | undefined): U[] {
    if (dtoList === undefined) {
      throw new Error('dtoList is undefined');
    }
    return dtoList.map((t: T) => this.from(t));
  }
}
