import { NgModule } from '@angular/core';
import { ActivityTypeModule } from '@app/modules/reference-data/modules/activity-type/activity-type.module';
import { DatabaseModule } from '@app/modules/reference-data/modules/database/database.module';
import { GeographyModule } from '@app/modules/reference-data/modules/geography/geography.module';
import { MethodModule } from '@app/modules/reference-data/modules/method/method.module';
import { UnitModule } from '@app/modules/reference-data/modules/unit/unit.module';
import { SubstanceModule } from '@app/modules/reference-data/modules/substance/substance/substance.module';
import { ClassificationModule } from '@app/modules/reference-data/modules/classification/classification.module';

@NgModule({
  exports: [
    ActivityTypeModule,
    DatabaseModule,
    GeographyModule,
    MethodModule,
    UnitModule,
    SubstanceModule,
    ClassificationModule,
  ],
})
export class ReferenceDataModule {}
