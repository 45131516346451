<div class="toggles" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="placeholder" *ngIf="!withAllocationModeSelection" fxFlex="1 1 50%" fxFlexFill></div>
  <mat-radio-group *ngIf="withAllocationModeSelection" fxFlex="1 1 50%" aria-label="Select an option"
                   [ngModel]="allocationMode"
                   (ngModelChange)="allocationModeChange.emit($event)"
  >
    <label>Quantities for: </label>
    <mat-radio-button color="primary" [value]="AllocationMode.ONE_PRODUCT_UNIT">
      {{referenceAmount}}
      {{referenceUnit}}
      of product
    </mat-radio-button>
    <mat-radio-button color="primary" [value]="AllocationMode.WHOLE_ACTIVITY">
      Activity
    </mat-radio-button>
  </mat-radio-group>
  <mat-slide-toggle fxFlex="1 1 50%" class="percentage"
    *ngIf="withPercentageToggle"
                    color="primary"
                    [ngModel] ="showPercentages"
                    (ngModelChange)="showPercentagesChange.emit($event)"
  >Show percentages
  </mat-slide-toggle>
</div>
