import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.enableSentry) {
  Sentry.init({
    dsn: 'https://eae2e1ba16d34018a90433a22eadf3bd@o461389.ingest.sentry.io/5914687',
    environment: environment.name,
    enabled: environment.enableSentry,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [environment.apiUrl],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
