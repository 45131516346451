import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatasetWarningComponent } from '@app/modules/dataset/modules/dataset-warning/components/dataset-warning/dataset-warning.component';
import { UiModule } from '@app/modules/ui/ui.module';

@NgModule({
  declarations: [DatasetWarningComponent],
  imports: [CommonModule, UiModule],
  exports: [DatasetWarningComponent],
})
export class DatasetWarningModule {}
