import {Injectable} from '@angular/core';
import {BiosphereExchangeDto} from '@app/api/__generated__/model/biosphereExchangeDto';
import {CompartmentDto} from '@app/api/__generated__/model/compartmentDto';
import {SubCompartmentDto} from '@app/api/__generated__/model/subCompartmentDto';
import {TechnosphereExchangeDto} from '@app/api/__generated__/model/technosphereExchangeDto';
import {
  BioExchange,
  Exchange,
  Exchanges,
  ExchangesWithoutProduct,
  ProductExchange,
  TechnoExchange,
} from '@app/modules/dataset/modules/exchange/models/exchange.model';
import {
  EmbeddedProductMapperService
} from '@app/modules/dataset/mappers/embedded-product/embedded-product-mapper.service';
import * as uuid from 'uuid';
import {ContributionMapperService} from "@app/modules/dataset/modules/contribution/mappers/contribution-mapper.service";
import {AllocatedQuantitiesDto} from "@app/api/__generated__/model/allocatedQuantitiesDto";
import {ContributionDto} from '@app/api/__generated__/model/contributionDto';
import {CoProduct} from "@app/modules/dataset/models/dataset.model";
import {ReferenceProductDto} from '@app/api/__generated__/model/referenceProductDto';
import {CoProductDto} from '@app/api/__generated__/model/coProductDto';
import {EmbeddedProductDto} from '@app/api/__generated__/model/embeddedProductDto';

interface ExchangeDtoCommon {
  name: string,
  amount: string | number,
  unit: string,
  comment?: string,
  contributions?: Array<ContributionDto>;
}

@Injectable({
  providedIn: 'root',
})
export class ExchangeMapperService {
  constructor(
    private readonly contributionMapper: ContributionMapperService,
    private readonly embeddedProductMapper: EmbeddedProductMapperService,
  ) {
  }

  public toReferenceProductExchanges(
    allocatedQuantities: AllocatedQuantitiesDto,
    referenceProduct: ReferenceProductDto,
    coProducts: CoProductDto[],
    datasetId: string): Exchanges {
    return {
      ...this.distributeQuantities(allocatedQuantities),
      toProduct: this.mapProductExchange(
        referenceProduct,
        allocatedQuantities.contributions,
        allocatedQuantities.amountOfReferenceProduct,
        coProducts,
        datasetId
      ),
    };
  }

  public toActivityExchanges(
    allocatedQuantities: AllocatedQuantitiesDto,
    referenceProduct: ReferenceProductDto,
    coProducts: CoProductDto[], datasetId: string ): Exchanges {
    return {
      ...this.distributeQuantities(allocatedQuantities),
      toProduct: this.mapProductExchangeFromActivity(
        referenceProduct,
        allocatedQuantities.contributions,
        allocatedQuantities.amountOfReferenceProduct,
        coProducts,
        datasetId
      ),
    };
  }

  public distributeQuantities(dto: AllocatedQuantitiesDto): ExchangesWithoutProduct {
    const biosphereInputs: BioExchange[] = [];
    const biosphereOutputs: BioExchange[] = [];

    const sortBioExchange = (
      a: BiosphereExchangeDto,
      b: BiosphereExchangeDto
    ) => {
      const comparedValue = a.compartment?.localeCompare(b.compartment || "") || 0;
      return comparedValue !== 0 ? comparedValue : a.name.localeCompare(b.name);
    };

    dto.biosphereExchanges
      .sort(sortBioExchange)
      .forEach((dto: BiosphereExchangeDto) => {
        if (dto.compartment === 'NATURAL_RESOURCE') {
          biosphereInputs.push(this.mapBiosphereExchange(dto));
        } else {
          biosphereOutputs.push(this.mapBiosphereExchange(dto));
        }
      });

    const technosphereInputs: TechnoExchange[] = [];
    const technosphereOutputs: TechnoExchange[] = [];

    dto.technosphereExchanges
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((technoSphereExchangeDto: TechnosphereExchangeDto) => {
        if (technoSphereExchangeDto.type === 'AVOIDED_PRODUCTS') {
          technosphereOutputs.push(this.mapTechnosphereExchange(technoSphereExchangeDto, technoSphereExchangeDto.embeddedProducts));
        } else {
          technosphereInputs.push(this.mapTechnosphereExchange(technoSphereExchangeDto, technoSphereExchangeDto.embeddedProducts));
        }
      });

    return {
      fromBiosphere: biosphereInputs,
      toBiosphere: biosphereOutputs,
      fromTechnosphere: technosphereInputs,
      toTechnosphere: technosphereOutputs,
      embeddedProducts: this.embeddedProductMapper.fromList(dto.embeddedProducts)
    };
  }

  private mapCommonAndContributions(
    dto: ExchangeDtoCommon,
    contributions: ContributionDto[]
  ): Exchange {
    return {
      displayId: uuid.v4(),
      name: dto.name,
      amount:
        typeof dto.amount === 'string'
          ? Number.parseFloat(dto.amount)
          : dto.amount,
      unit: dto.unit,
      comment: dto.comment,
      contributions: this.contributionMapper.fromList(contributions),
      flattenContributions: this.contributionMapper.flattenFromList(
        contributions
      ),
      displayedContributions: [],
    };
  }

  private mapBiosphereExchange(dto: BiosphereExchangeDto): BioExchange {
    return {
      ...this.mapCommonAndContributions(dto, dto.contributions),
      id: dto.substanceId,
      type: dto.type,
      compartment: dto.compartment as CompartmentDto,
      subCompartment: dto.subCompartment as SubCompartmentDto,
      displayedContributions: [],
    };
  }

  private mapTechnosphereExchange(
    dto: TechnosphereExchangeDto,
    embeddedProducts: Array<EmbeddedProductDto>
  ): TechnoExchange {
    return {
      ...this.mapCommonAndContributions(dto, dto.contributions),
      id: dto.childDatasetId,
      type: dto.type,
      navigable: dto.navigable,
      embeddedProducts: this.embeddedProductMapper.fromList(embeddedProducts),
      warnings: dto.warnings.map((w) => w.description),
      displayedContributions: [],
    };
  }

  public mapProductExchange(
    dto: ReferenceProductDto,
    contributions: ContributionDto[],
    amount: number,
    coProducts: CoProductDto[], datasetId: string): ProductExchange {
    return {
      ...this.mapCommonAndContributions(dto, contributions),
      id: datasetId,
      displayId: datasetId,
      amount: amount,
      allocation: dto.allocation,
      coProducts: coProducts.map(raw => {
        return {
          ...raw,
          amount: raw.amount ? amount * raw.amount / dto.amount : undefined
        } as CoProduct
      }),
    };
  }

  public mapProductExchangeFromActivity(
    dto: ReferenceProductDto,
    contributions: ContributionDto[],
    amount: number,
    coProducts: CoProductDto[],
    datasetId: string): ProductExchange {
    const coProducts1 = coProducts.map(raw => {
      return {
        ...raw,
        amount: raw.amount ? amount * raw.amount / dto.amount : undefined
      }
    }).map(cp => this.allocate(cp, contributions));
    return {
      ...this.allocate(dto, contributions),
      id: datasetId,
      displayId: datasetId,
      amount: amount,
      allocation: dto.allocation,
      coProducts: coProducts1,
    };
  }

  private allocate(
    dto: CoProductDto,
    contributions: ContributionDto[]
  ): CoProduct {
    const allocatedContributions = this.getAllocatedContributions(contributions, dto)
    const amount = typeof dto.amount === 'string'
      ? Number.parseFloat(dto.amount)
      : dto.amount;
    return {
      id: dto.id || uuid.v4(),
      displayId: dto.id || uuid.v4(),
      name: dto.name,
      amount: amount || 0,
      allocation: dto.allocation,
      unit: dto.unit,
      comment: dto.comment,
      contributions: this.contributionMapper.fromList(allocatedContributions),
      flattenContributions: this.contributionMapper.flattenFromList(
        allocatedContributions
      ),
      displayedContributions: [],
    };
  }

  private getAllocatedContributions(contributions: ContributionDto[], dto: CoProductDto): ContributionDto[] {
    return contributions.map(c => {
      return {...c, amount: c.amount * (dto.allocation || 0) / 100} as ContributionDto
    });
  }

}
