import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterOption } from '@app/modules/landing-page/components/landing-page-search-section/filter/filter.model';
import { GeographyService } from '@app/modules/reference-data/modules/geography/services/geography.service';

@Component({
  selector: 'app-geography-filter',
  templateUrl: './geography-filter.component.html',
})
export class GeographyFilterComponent implements OnInit {
  @Input()
  value: Array<string> = [];

  @Output()
  valueChange = new EventEmitter<Array<string>>();

  options$: Observable<Array<FilterOption>> = EMPTY;

  constructor(private geographyService: GeographyService) {}

  ngOnInit(): void {
    this.options$ = this.geographyService
      .findGeographiesWithDisplayNameAndSort()
      .pipe(
        map((geographies) =>
          geographies.map((geography) => ({
            value: geography.shortName,
            label: geography.displayName,
          }))
        )
      );
  }

  onValueChange(value: Array<string>): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }
}
