import { SubCompartmentDto } from '@app/api/__generated__/model/subCompartmentDto';
import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import { SUB_COMPARTMENTS } from '../models/sub-compartment.model';

export const mapSubCompartmentToFilterOption = (
  sc: SubCompartmentDto
): FilterOption => ({
  key: sc,
  value: SUB_COMPARTMENTS.get(sc) ?? sc,
});
