import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  transform<T, D>(value: T | null | undefined, defaultValue: D): T | D {
    return value != null ? value : defaultValue;
  }
}
