import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DatasetSearchEffects } from './dataset-search.effects';
import {
  datasetSearchFeatureKey,
  datasetSearchReducer,
} from './dataset-search.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(datasetSearchFeatureKey, datasetSearchReducer),
    EffectsModule.forFeature([DatasetSearchEffects]),
  ],
  providers: [],
})
export class DatasetSearchStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: DatasetSearchStoreModule) {
    if (parentModule) {
      throw new Error('DatasetSearchStoreModule is already loaded');
    }
  }
}
