import { ActivityTypeDto } from '@app/api/__generated__/model/activityTypeDto';

export const ACTIVITY_TYPES: Array<{ value: ActivityTypeDto; name: string }> = [
  {
    value: 'MARKET_ACTIVITY',
    name: 'Market activity',
  },
  {
    value: 'MARKET_GROUP',
    name: 'Market group',
  },
  {
    value: 'ORDINARY_TRANSFORMING_ACTIVITY',
    name: 'Transforming activity',
  },
  {
    value: 'PRODUCTION_MIX',
    name: 'Production mix',
  },
];
