import { MethodIndicator } from '../models/method.model';
import { IndicatorGroup } from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';

export const getDistinctSortedIndicatorsFromGroups = (
  groups: IndicatorGroup[]
): MethodIndicator[] => {
  return groups
    .flatMap((ig) => ig.indicators)
    .filter(
      (mi, pos, self) => self.findIndex((mi2) => mi2.name === mi.name) === pos
    )
    .sort((a, b) => a.name.localeCompare(b.name));
};
