import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndicatorGroupsServiceInterface } from '@app/api/__generated__/api/indicatorGroups.service';
import { GroupsDto } from '@app/api/__generated__/model/groupsDto';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndicatorGroupApiService
  implements IndicatorGroupsServiceInterface
{
  private readonly url = environment.apiUrl + '/indicator-groups';

  constructor(private httpClient: HttpClient) {}

  getIndicatorGroups(): Observable<GroupsDto> {
    return this.httpClient.get<GroupsDto>(this.url);
  }
}
