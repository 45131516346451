import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  getFilterOption,
  IndicatorGroup,
} from '@app/modules/reference-data/modules/indicator-group/models/indicator-group.model';
import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';

@Component({
  selector: 'app-group-filter[options][value]',
  templateUrl: './group-filter.component.html',
})
export class GroupFilterComponent implements OnChanges {
  @Input()
  set options(options: Array<IndicatorGroup> | null) {
    this._options = options || [];
    this.filterOptions = this._options.map((option) => getFilterOption(option));
  }

  private _options: Array<IndicatorGroup> = [];
  filterOptions: Array<FilterOption> = [];

  @Input()
  value!: IndicatorGroup | null;

  @Output()
  valueChange = new EventEmitter<IndicatorGroup | null>();

  indicatorGroupFilterCtrl = new FormControl<string | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      this.indicatorGroupFilterCtrl.setValue(this.value?.name ?? null, {
        emitEvent: false,
      });
    }
  }

  onValueChange(): void {
    const group = this._options.find(
      (group) => group.name === this.indicatorGroupFilterCtrl.value
    );
    this.valueChange.emit(group);
  }
}
