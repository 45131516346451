import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page-section',
  template: `
    <section>
      <ng-content></ng-content>
    </section>
  `,
  styles: [
    `
      section {
        padding: var(--section-padding-vertical, max(2vw, 25px)) 0;
        background-color: var(--section-background-color);
        background-image: var(--section-background-image);
        background-size: cover;
      }
    `,
  ],
})
export class LandingPageSectionComponent {}
