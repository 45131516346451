import { Injectable } from '@angular/core';
import {
  DatasetQueryParams,
  DatasetSearchForm,
} from '@app/modules/dataset/models/dataset-search.model';
import { ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchFormMapperService {
  fromQueryParams(queryParams: ParamMap): DatasetSearchForm {
    return {
      searchTerm: queryParams.get('q') ?? '',
      filters: {
        databases: queryParams.getAll('database'),
        geographies: queryParams.getAll('geography'),
        activitiesTypes: queryParams.getAll('activityType'),
        units: queryParams.getAll('unit'),
        isics: queryParams.getAll('isic'),
      },
    };
  }

  toQueryParams(form: DatasetSearchForm): DatasetQueryParams {
    return {
      q: form.searchTerm,
      database: form.filters.databases,
      geography: form.filters.geographies,
      activityType: form.filters.activitiesTypes,
      unit: form.filters.units,
      isic: form.filters.isics,
    };
  }
}
