import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { exportBasket } from '@app/modules/basket/store/dataset/basket-dataset.actions';
import { of } from 'rxjs';
import { DownloadService } from '@app/modules/core/services/download-service';
import { BasketService } from '@app/modules/basket/services/basket.service';
import { NotificationService } from '@app/modules/core/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class BasketEffects {
  exportBasket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportBasket),
        switchMap(({ options }) =>
          this.basketService.exportBasketAsExcel(options).pipe(
            tap(({ blob, filename }) =>
              this.downloadService.download(filename, blob)
            ),
            catchError((err) => {
              this.notificationService.error('Could not export basket', err);
              return of();
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly basketService: BasketService,
    private readonly downloadService: DownloadService,
    private readonly notificationService: NotificationService
  ) {}
}
