import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/modules/material/material.module';
import { PageNotFoundComponent } from '@app/modules/ui/pages/page-not-found/page-not-found.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { TextToggleComponent } from './components/text-toggle/text-toggle.component';
import { WarnComponent } from './components/warn/warn.component';
import { IsYesOrNoPipe } from './pipes/is-yes-or-no.pipe';
import { FiltersGroupModule } from '@app/modules/ui/modules/filters-group/filters-group.module';
import { SelectModule } from '@app/modules/ui/modules/select/select.module';
import { DefaultPipe } from './default.pipe';
import { AmountPipe } from '@app/modules/ui/pipes/amount.pipe';
import { CopiableIdComponent } from './components/copiable-id/copiable-id.component';
import { EmbeddedTypeLabelPipe } from '@app/modules/ui/pipes/embedded-product-label.pipe';
import { NgStringPipesModule } from 'ngx-pipes';

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    NgStringPipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    PageNotFoundComponent,
    TextToggleComponent,
    IsYesOrNoPipe,
    ConfirmDialogComponent,
    WarnComponent,
    DefaultPipe,
    AmountPipe,
    CopiableIdComponent,
    EmbeddedTypeLabelPipe,
  ],
  exports: [
    PageNotFoundComponent,
    TextToggleComponent,
    IsYesOrNoPipe,
    WarnComponent,
    FiltersGroupModule,
    SelectModule,
    DefaultPipe,
    AmountPipe,
    CopiableIdComponent,
    EmbeddedTypeLabelPipe,
  ],
})
export class UiModule {}
