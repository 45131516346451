import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-exchange-label-warning[warnings]',
  templateUrl: './exchange-label-warning.component.html',
})
export class ExchangeLabelWarningComponent {
  @Input()
  warnings!: string[];
}
