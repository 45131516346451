<ng-container *ngIf="tree$ | async as tree; else loading">
  <figure>
    <figcaption>{{ referenceProduct }}<br />{{ indicator.name }}<br/>{{ contributionType.displayName }}</figcaption>
    <svg
      *ngIf="hasData(tree); else noData"
      [appSankey]="tree"
      [formatNodeName]="formatNodeName"
      viewBox="0 0 900 600"
    ></svg>
  </figure>
  <ng-template #noData>No data</ng-template>
</ng-container>
<ng-template #loading>Loading...</ng-template>
