import {
  Component,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output,
} from '@angular/core';
import {Dataset, getExchangeForAllocation} from '@app/modules/dataset/models/dataset.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import {combineLatest, startWith, Subject} from 'rxjs';
import {
  ContributionSelection, emptySelection, isCompleteSelection,
} from 'src/app/modules/dataset/modules/contribution-selection/model/contribution-selection.model';
import {takeUntil} from "rxjs/operators";
import {RumService} from "@app/modules/rum/rum.service";
import {Exchanges, AllocationMode} from "@app/modules/dataset/modules/exchange/models/exchange.model";

@Component({
  selector: 'app-exchanges-tab',
  templateUrl: './exchange-tab.component.html',
  styleUrls: [
    './exchange-tab.component.scss',
    '../../../../common.filters.scss'
  ],
})
export class ExchangeTabComponent implements OnDestroy, OnInit {
  _dataset!: Dataset;

  @Input()
  set dataset(dataset: Dataset) {
    this._dataset = dataset;
    if(this._dataset?.id !== dataset.id) {
      this.datasetChange$.next(dataset);
    }
  }
  get dataset(): Dataset {
    return this._dataset;
  }

  @Output()
  contributionSelectionChange = new EventEmitter<ContributionSelection>();

  private datasetChange$ = new Subject<Dataset>();
  protected selection: ContributionSelection = emptySelection();

  get hasContribution(): boolean {
    return this.selection?.contribution !== null;
  }

  showPercentages = false;
  allocationMode: AllocationMode = AllocationMode.ONE_PRODUCT_UNIT;

  private onDestroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private rumService: RumService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.datasetChange$.pipe(startWith(this._dataset)),
      this.contributionSelectionChange,
    ])
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(([dataset, selection]) => {
      if(isCompleteSelection(selection)) {
        this.rumService.triggerContributionsView(dataset, selection);
      }
    });
  }

  ngOnDestroy(): void {
    this.datasetChange$.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onContributionChange(selection: ContributionSelection): void {
    this.selection = selection;
    this.contributionSelectionChange.emit(selection);
  }

  protected readonly AllocationMode = AllocationMode;
  get exchanges(): Exchanges {
    return getExchangeForAllocation(this.dataset, this.allocationMode);
  }
}
