import { Injectable } from '@angular/core';
import { ACTIVITY_TYPES } from '@app/modules/reference-data/modules/activity-type/models/activity-types.model';
import { Classification } from '@app/modules/reference-data/modules/classification/models/classification.model';
import {
  Database,
  getDatabaseKey,
} from '@app/modules/reference-data/modules/database/models/database.model';
import { Unit } from '@app/modules/reference-data/modules/unit/models/unit.model';
import {
  DatasetSearchFiltersOptions,
  Facet,
  GeographyWithDisplayName,
  KeyCountMap,
  KeyValueCount,
  KeyValueCountGroup,
  KeyValueGroupMap,
  SearchFilterOptionMap,
} from '../models/dataset-search.model';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchMapperService {
  mapFacetsToFilterOptions(
    facets: Facet[],
    filterOptionMap: SearchFilterOptionMap
  ): DatasetSearchFiltersOptions {
    return {
      databases: this.mapDatabaseFacetsToFilterOptions(facets, filterOptionMap),
      geographies: this.mapAnyFacetToFilterOptions(
        BucketKey.GEOGRAPHY,
        facets,
        filterOptionMap['geography']
      ),
      activitiesTypes: this.mapAnyFacetToFilterOptions(
        BucketKey.ACTIVITY_TYPE,
        facets,
        filterOptionMap['activityType']
      ),
      units: this.mapAnyFacetToFilterOptions(
        BucketKey.UNIT,
        facets,
        filterOptionMap['unit']
      ),
      isics: this.mapAnyFacetToFilterOptions(
        BucketKey.ISIC,
        this.filterIsic(facets),
        filterOptionMap['isic']
      ),
    };
  }

  // TODO : remove when done on back-end side
  filterIsic(facets: Facet[]): Facet[] {
    const buckets = facets
      .filter((facet) => facet.key === BucketKey.ISIC)
      .flatMap((facet) => facet.buckets)
      .filter((bucket) => bucket.bucketName.startsWith('ISIC - '));

    return [{ key: BucketKey.ISIC, buckets: buckets }];
  }

  mapToSearchFilterOptionsMap(
    databases: Database[],
    geographies: GeographyWithDisplayName[],
    units: Unit[],
    isics: Classification[]
  ): SearchFilterOptionMap {
    const filterOptions = this.mapToSearchFilterOptions(
      databases,
      geographies,
      units,
      isics
    );
    return {
      database: this.reduceToKeyValueGroupMap(filterOptions.databases),
      geography: this.reduceToKeyValueMap(filterOptions.geographies),
      activityType: this.reduceToKeyValueMap(filterOptions.activitiesTypes),
      unit: this.reduceToKeyValueMap(filterOptions.units),
      isic: this.reduceToKeyValueMap(filterOptions.isics),
    };
  }

  mapToSearchFilterOptions(
    databases: Database[],
    geographies: GeographyWithDisplayName[],
    units: Unit[],
    classifications: Classification[]
  ): DatasetSearchFiltersOptions {
    return {
      databases: databases.map((database) => ({
        key: database.key,
        value: database.displayName,
        group: database.obsolete ? 'Obsolete databases' : 'Default databases',
        filteredAttributes: [database.name, database.version],
      })),
      geographies: geographies.map((geography) => ({
        key: geography.shortName,
        value: geography.displayName,
      })),
      activitiesTypes: ACTIVITY_TYPES.map((activityType) => ({
        key: activityType.value,
        value: activityType.name,
      })),
      units: units.map((unit) => ({
        key: unit.name,
        value: unit.formattedName,
      })),
      isics: classifications.map((classification) => ({
        key: classification.system + ' - ' + classification.code,
        value: classification.code + ' - ' + classification.description,
      })),
    };
  }

  private reduceToKeyValueMap(objects: KeyValueCount[]): KeyValueGroupMap {
    const keyValueMap: KeyValueGroupMap = {};
    return objects.reduce(
      (obj, pair) => ((obj[pair.key] = { value: pair.value }), obj),
      keyValueMap
    );
  }

  private reduceToKeyValueGroupMap(
    objects: KeyValueCountGroup[]
  ): KeyValueGroupMap {
    const keyValueGroupMap: KeyValueGroupMap = {};
    return objects.reduce(
      (obj, pair) => (
        (obj[pair.key] = { value: pair.value, group: pair.group }), obj
      ),
      keyValueGroupMap
    );
  }

  private mapDatabaseFacetsToFilterOptions(
    facets: Facet[],
    filterOptionMap: SearchFilterOptionMap
  ): KeyValueCountGroup[] {
    const databaseFilterOptionMap = filterOptionMap['database'];
    const keys = Object.keys(databaseFilterOptionMap);
    const keyCount: KeyCountMap = {};
    facets
      .filter((facet) => facet.key === BucketKey.DATABASE)
      .flatMap((facet) => facet.buckets)
      .forEach((bucket) => {
        keyCount[getDatabaseKey(bucket)] = bucket.docCount;
      });
    return keys
      .map((key) => ({
        key: key,
        value: databaseFilterOptionMap[key].value,
        docCount: keyCount[key],
        group: databaseFilterOptionMap[key].group!,
        filteredAttributes: key.split(':'),
      }))
      .sort((a, b) => this.sortDatabaseByCount(a, b));
  }

  private mapAnyFacetToFilterOptions(
    bucketKey: BucketKey,
    facets: Facet[],
    keyValueGroupMap: KeyValueGroupMap
  ): KeyValueCount[] {
    const keys = Object.keys(keyValueGroupMap);
    const keyCount: KeyCountMap = {};
    facets
      .filter((facet) => facet.key === bucketKey)
      .flatMap((facet) => facet.buckets)
      .forEach((bucket) => {
        keyCount[bucket.bucketName] = bucket.docCount;
      });
    return keys
      .map((key) => ({
        key: key,
        value: keyValueGroupMap[key].value,
        docCount: keyCount[key],
      }))
      .sort((a, b) => this.sortByCount(a, b));
  }

  private sortByCount(a: KeyValueCount, b: KeyValueCount): number {
    if (a.docCount == undefined && b.docCount == undefined) {
      return a.value.localeCompare(b.value);
    } else if (a.docCount != undefined && b.docCount == undefined) {
      return -1;
    } else if (a.docCount == undefined && b.docCount != undefined) {
      return 1;
    } else if (a.docCount != undefined && b.docCount != undefined) {
      return b.docCount - a.docCount;
    }
    return 0;
  }

  private sortDatabaseByCount(a: KeyValueCountGroup, b: KeyValueCountGroup): number {
    if (a.group.localeCompare(b.group) != 0) {
      return a.group.localeCompare(b.group);
    }
    return this.sortByCount(a,b);
  }
}

export const enum BucketKey {
  DATABASE = 'description.database.fullName.keyword',
  GEOGRAPHY = 'description.geography.shortName.keyword',
  ACTIVITY_TYPE = 'description.activityType.keyword',
  UNIT = 'referenceProduct.unit.keyword',
  ISIC = 'description.classifications.systemCode.keyword',
}
