import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CharacterizationFactorSearchEffects } from './characterization-factor-search.effects';
import {
  searchFeatureKey,
  characterizationFactorSearchReducer,
} from './characterization-factor-search.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(
      searchFeatureKey,
      characterizationFactorSearchReducer
    ),
    EffectsModule.forFeature([CharacterizationFactorSearchEffects]),
  ],
  providers: [],
})
export class CharacterizationFactorSearchStoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule?: CharacterizationFactorSearchStoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CharacterizationFactorSearchStoreModule is already loaded'
      );
    }
  }
}
