import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Auth0ApiService } from '@app/modules/auth/api/auth0-api.service';
import { environment } from '@env/environment';
import { NotificationService } from '@app/modules/core/services/notification.service';
import { Observable, take } from 'rxjs';
import { User } from '@app/modules/auth/models/user.model';
import { UserMapperService } from '@app/modules/auth/mappers/user-mapper.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  constructor(
    private authService: AuthService,
    private auth0ApiService: Auth0ApiService,
    private userMapperService: UserMapperService,
    private notificationService: NotificationService,
  ) {}

  getUser(): Observable<User> {
    return this.authService.idTokenClaims$.pipe(
      map((token) => this.userMapperService.fromIdToken(token)),
    );
  }

  getUserCompany(): Observable<string> {
    return this.getUser().pipe(map((user) => user.company));
  }

  logout(): void {
    this.authService.logout({
      logoutParams: { returnTo: environment.logoutUrl },
    });
  }

  changePassword(): void {
    this.auth0ApiService
      .changePwd()
      .pipe(take(1))
      .subscribe((res) => this.notificationService.success(res));
  }
}
