<h1 mat-dialog-title>Export basket to Excel</h1>
<div mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="start center">
    <app-select-search
      label="Indicator groups"
      class="filter"
      [multiple]="true"
      [control]="groupFilterCtrl"
      [list]="(groupDefinitions$ | async) || []"
      displayedField="key"
      valueField="key"
      (valueChange)="onMethodChange()"
    ></app-select-search>
    <div
      *ngIf="(groupDefinitions$ | async)?.length === 0"
      fxLayout="column"
      fxLayoutAlign="start center"
      class="primary-color"
    >
      <p>Basket cannot be exported because no methods were found.</p>
      <p>
        This probably means you have some obsolete datasets in your basket. Try
        removing or upgrading them.
      </p>
    </div>
    <div fxLayout="column">
      <mat-checkbox
        color="primary"
        [checked]="options.withExchangeContribution"
        (change)="onExchangeContributionChange()"
        class="mat-dialog-content-scrollbar-fix"
        >include exchanges
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [checked]="options.withSplitGasContribution"
        (change)="onSplitGasContributionChange()"
        class="mat-dialog-content-scrollbar-fix"
        >include gas split
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [checked]="options.withSplitActivityContribution"
        (change)="onSplitActivityContributionChange()"
        class="mat-dialog-content-scrollbar-fix"
        >include activity category and FLAG split
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [checked]="options.withEmbeddedProducts"
        (change)="onEmbeddedProductChange()"
        class="mat-dialog-content-scrollbar-fix"
        >include embedded product
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [checked]="options.withObsoleteFlagContribution"
        (change)="onFlagContributionChange()"
        class="mat-dialog-content-scrollbar-fix"
        >include obsolete FLAG split
      </mat-checkbox>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    [disabled]="(loading$ | async) === true"
    mat-raised-button
    color="primary"
    (click)="onExport()"
    cdkFocusInitial
  >
    Export
    <mat-icon *ngIf="(loading$ | async) === true">
      <mat-spinner diameter="20"></mat-spinner>
    </mat-icon>
  </button>
</div>
