import { Component, OnDestroy, OnInit } from '@angular/core';
import { removeDataset } from '@app/modules/basket/store/dataset/basket-dataset.actions';
import { selectAll } from '@app/modules/basket/store/dataset/basket-dataset.selectors';
import { DatasetSearchResult } from '@app/modules/dataset/models/dataset-search.model';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-basket-dataset-tab',
  templateUrl: './basket-dataset-tab.component.html',
  styleUrl: './basket-dataset-tab.component.scss',
})
export class BasketDatasetTabComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  datasets: DatasetSearchResult[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(selectAll)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((datasets) => {
        return (this.datasets = datasets);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  removeDataset(dataset: DatasetSearchResult): void {
    this.store.dispatch(removeDataset({ dataset }));
  }
}
