import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UpgradeDatasetsRequestDto } from '@app/api/__generated__/model/upgradeDatasetsRequestDto';
import { DatasetUpgradeApiService } from '@app/modules/dataset/api/dataset-upgrade-api.service';
import { DatasetSearchResultMapperService } from '@app/modules/dataset/mappers/dataset-search-result/dataset-search-result-mapper.service';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs';
import {
  addAllDatasets,
  emptyBasket,
} from '../../store/dataset/basket-dataset.actions';
import { selectDatasetsIds } from '../../store/dataset/basket-dataset.selectors';
import {map, tap} from "rxjs/operators";
import {RumService} from "@app/modules/rum/rum.service";
import {DatasetMapperService} from "@app/modules/dataset/mappers/dataset/dataset-mapper.service";
import {Dataset} from "@app/modules/dataset/models/dataset.model";

@Component({
  templateUrl: './upgrade-datasets-dialog.component.html',
  styleUrl: './upgrade-datasets-dialog.component.scss',
})
export class UpgradeDatasetsDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<UpgradeDatasetsRequestDto>,
    private datasetApiService: DatasetUpgradeApiService,
    private datasetSearchResultMapperService: DatasetSearchResultMapperService,
    private datasetMapperService: DatasetMapperService,
    private store: Store<AppState>,
    private rumService: RumService,
  ) {}

  isFetchingDatasets = false;
  upgradedDatasets: Dataset[] = [];

  doUpdate(stepper: MatStepper): void {
    this.store
      .select(selectDatasetsIds())
      .pipe(
        take(1),
        switchMap((ids) => {
          this.isFetchingDatasets = true;
          return this.datasetApiService.upgradeDatasets({ ids }).pipe(take(1));
        }),
        map(res => this.datasetMapperService.fromList(res.datasets)),
        tap(datasets => {
          this.rumService.triggerBasketUpgradeEvent(datasets);
        })
      )
      .subscribe(datasets => {
        this.isFetchingDatasets = false;
        this.upgradedDatasets = datasets;
        stepper.next();
      });
  }

  importAndClose(): void {
    this.store.dispatch(emptyBasket());
    this.store.dispatch(
      addAllDatasets({
        datasets: this.datasetSearchResultMapperService.fromDatasets(
          this.upgradedDatasets,
        ),
      }),
    );
    this.dialogRef.close(true);
  }
}
