<div [formGroup]="form">
  <app-filter
    *ngFor="let filter of filters"
    [filter]="filter"
    [form]="form"
    [refreshOnValueChange]="refreshOnValueChange"
    (filterChange)="onFilterChange()"
  ></app-filter>
</div>
<div class="filter-chips" fxLayoutAlign="start center">
  <ng-container *ngFor="let filter of filters">
    <app-chips-group
      *ngIf="filter.value$.value.length > 0"
      [items]="filter.value$.value"
      [groupName]="filter.label"
      displayField="value"
      (removed)="removeFilter(filter, $event)"
    ></app-chips-group>
  </ng-container>
  <button
    mat-button
    mat-stroked-button
    *ngIf="hasMultipleFilters"
    class="clear-all"
    (click)="removeAllFilters()"
  >
    Clear all
    <mat-icon>cancel</mat-icon>
  </button>
</div>
