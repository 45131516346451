import {ActivityTypeDto} from '@app/api/__generated__/model/activityTypeDto';
import {AllocationMode, Exchange, Exchanges} from '@app/modules/dataset/modules/exchange/models/exchange.model';
import {Parent} from '@app/modules/dataset/modules/parent/models/parent.model';
import {Database} from '@app/modules/reference-data/modules/database/models/database.model';
import {Geography} from '@app/modules/reference-data/modules/geography/models/geography.model';
import {Classification} from '../../reference-data/modules/classification/models/classification.model';
import {History} from '../modules/history/models/history.models';
import {Contribution, ContributionTypeName} from "@app/modules/dataset/modules/contribution/model/contribution.model";
import {AssessmentGroup} from "@app/modules/dataset/models/assessment.model";
import {EmbeddedProduct, EmbeddedProductType, toLabel} from "@app/modules/dataset/models/embedded.product.model";
import {Assessment, ExchangeHeader, ExchangeRecord} from "@app/modules/dataset/models/exchange.model";

export interface Dataset {
  id: string;
  description: DatasetDescription;
  referenceProduct: ReferenceProduct;
  impactIndicatorSamples: Array<ImpactAssessmentSample>;
  activity?: Activity;
  parents: Array<Parent>;
  flattenContributions: Contribution[]; // temporally flatten contributions before refactoring exchanges tab
  history: History;
  isFlag: boolean;
  isLuc: boolean;
  displayedIndicatorId?: string,
  displayedContributionType?: ContributionTypeName,
}

export interface DatasetDescription {
  activityName: string;
  activityType: ActivityTypeDto;
  includedActivitiesStart?: string;
  includedActivitiesEnd?: string;
  synonyms?: Array<string>;
  technologyLevel?: TechnologyLevel;
  productionVolume?: number;
  casNumber?: string;
  modelling?: Modelling;
  author?: Author;
  database: Database;
  generalComment?: string;
  geography: Geography;
  timePeriod?: TimePeriod;
  classifications: Map<string, Classification[]>
  isic: string | undefined;
}

export interface ReferenceProduct extends ExchangeTable{
  name: string;
  unit: string;
  exchanges: Exchanges;
  referenceProductSection: ExchangeSection;
  coProductSection: ExchangeSection;
  assessmentGroups: Array<AssessmentGroup>;
}

export interface ExchangeTable {
  fromTechnosphereSection: ExchangeSection;
  toTechnosphereSection: ExchangeSection;
  fromBiosphereSection: ExchangeSection;
  toBiosphereSection: ExchangeSection;
}

export interface ExchangeSection {
  header: ExchangeHeader;
  records: Array<ExchangeRecord>;
  subtotal?: Subtotal;
}

export interface SectionOptions {
  displayedKey: string | undefined,
  expandedCommentId: string | undefined,
  showPercentages: boolean,
  totalAssessment: number | undefined,
  isGreyed: boolean,
  unit: string | undefined,
}

export const defaultSectionOptions = {
  displayedKey: undefined,
  expandedCommentId: undefined,
  showPercentages: false,
  totalAssessment: undefined,
  isGreyed: false,
  unit: undefined,
}

export interface Subtotal {
  title: string;
  assessments: Array<Assessment>;
  showBar: boolean,
}

export interface Activity extends ExchangeTable{
  exchanges: Exchanges;
  assessmentGroups: Array<AssessmentGroup>;
  contributions: Contribution[];
  flattenContributions: Contribution[];
  coProductSection: ExchangeSection;
}

export interface Author {
  name: string;
  email: string;
}

export interface Modelling {
  systemModel?: string;
  samplingProcedure?: string;
  extrapolation?: string;
  percent?: number;
}

export enum TechnologyLevel {
  UNDEFINED = 'UNDEFINED',
  NEW = 'NEW',
  MODERN = 'MODERN',
  CURRENT = 'CURRENT',
  OLD = 'OLD',
  OUTDATED = 'OUTDATED',
}

export interface CoProduct extends Exchange {
  id: string;
  allocation?: number;
  comment?: string;
}

export interface ImpactAssessmentSample {
  methodName: string;
  indicator: ImpactIndicator;
}

export interface ImpactIndicator {
  id: string;
  categoryName: string;
  unitName: string;
  amount: number;
  groups: Array<string>;
}

export interface TimePeriod {
  startDate: string;
  endDate: string;
}

export function getExchangeForAllocation(dataset: Dataset, allocationMode: AllocationMode): Exchanges {
  switch (allocationMode) {
    case AllocationMode.ONE_PRODUCT_UNIT:
      return dataset.referenceProduct.exchanges;
    default:
      return dataset.activity!.exchanges;
  }
}

export function getEmbeddedProductTypes(dataset: Dataset): EmbeddedProductType[] {
  return dataset.referenceProduct.exchanges.embeddedProducts.map(e => e.type);
}

export function hasEmbeddedProducts(dataset: Dataset): boolean {
  return dataset.referenceProduct.exchanges.embeddedProducts.length > 0
}

export function mapContributionToAssessment(contribution: Contribution, methodIndicatorId: string) : Assessment {
  return {
    displayName: contribution.displayName,
    key: "contribution-"  + methodIndicatorId,
    amount: contribution.amount,
  }
}

export function mapEmbeddedToAssessment(embedded: EmbeddedProduct) : Assessment {
  return {
    displayName: toLabel(embedded.type),
    key: "embedded-"  + embedded.type,
    amount: embedded.amount,
  }
}
