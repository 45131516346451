import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-toggle[text]',
  templateUrl: './text-toggle.component.html',
  styleUrl: './text-toggle.component.scss',
})
export class TextToggleComponent {
  @Input()
  text!: string;

  get makeMultiLineTextAsOneLine(): string {
    return this.text.replaceAll('<br />', ' ');
  }

  isOpened = false;
}
