import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FiltersGroupComponent } from '@app/modules/ui/modules/filters-group/components/filters-group/filters-group.component';
import {
  DatasetSearchFilters,
  DatasetSearchForm,
} from '@app/modules/dataset/models/dataset-search.model';
import { Filter } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import { delay, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { isBlank } from '@app/modules/core/utils/string-utils';
import * as fromSearch from '@app/modules/dataset/store/dataset-search';
import { SearchTipsDialogComponent } from '@app/modules/dataset/components/search-tips-dialog/search-tips-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-datasets-search-form',
  templateUrl: './datasets-search-form.component.html',
  styleUrl: './datasets-search-form.component.scss',
})
export class DatasetsSearchFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output()
  formSubmit = new EventEmitter<DatasetSearchForm>();

  @Output()
  showResultsDetailsChange = new EventEmitter<boolean>();

  defaultShowResultsDetails = true;

  searchForm!: UntypedFormGroup;

  @ViewChild(FiltersGroupComponent, { static: true })
  filtersGroup!: FiltersGroupComponent<keyof DatasetSearchForm>;

  readonly filters: Array<Filter<keyof DatasetSearchFilters>> = [
    new Filter('geographies', 'Geography'),
    new Filter('databases', 'Database', ['name', 'version']),
    new Filter('activitiesTypes', 'Activity type'),
    new Filter('units', 'Unit'),
    new Filter('isics', 'ISIC Classification'),
  ];

  private onDestroy$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchTerm: [''],
      filters: this.filtersGroup.getForm(),
    });

    this.store.dispatch(fromSearch.loadDatasetFilters());

    this.store
      .select(fromSearch.selectFiltersOptions())
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((results) =>
        this.filters.map((filter) => {
          const options = results[filter.key];
          filter.options$.next(options);
        }),
      );
  }

  ngAfterViewInit(): void {
    this.store
      .select(fromSearch.selectForm())
      .pipe(delay(0))
      .subscribe((formState: DatasetSearchForm) =>
        this.searchForm.setValue(formState),
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit(): void {
    const form = this.searchForm.getRawValue() as DatasetSearchForm;
    if (isBlank(form.searchTerm)) {
      return;
    }
    this.formSubmit.emit(form);
  }

  openSearchHelpDialog(): void {
    this.dialog.open(SearchTipsDialogComponent, {
      width: '60%',
    });
  }

  onShowDetailsChange(event: MatSlideToggleChange): void {
    this.showResultsDetailsChange.emit(event.checked);
  }
}
