import { Injectable } from '@angular/core';
import { DatasetSearchApiService } from '@app/modules/dataset/api/dataset-search-api.service';
import { DatasetSearchResults } from '@app/modules/dataset/models/dataset-search.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatasetSearchResultMapperService } from '../mappers/dataset-search-result/dataset-search-result-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchService {
  constructor(
    private datasetSearchApiService: DatasetSearchApiService,
    private datasetSearchResultMapper: DatasetSearchResultMapperService
  ) {}

  searchDatasets(
    q?: string,
    geography?: Array<string>,
    database?: Array<string>,
    isic?: Array<string>,
    cpc?: Array<string>,
    activityType?: Array<string>,
    unit?: Array<string>
  ): Observable<DatasetSearchResults> {
    return this.datasetSearchApiService
      .searchDatasets(q, geography, database, isic, cpc, activityType, unit)
      .pipe(
        map((datasets) => this.datasetSearchResultMapper.fromResults(datasets))
      );
  }
}
