import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatabaseApiService } from '@app/modules/reference-data/modules/database/api/database-api.service';
import { map } from 'rxjs/operators';
import { DatabaseMapperService } from '../mappers/database-mapper.service';
import { Database } from '../models/database.model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(
    private databaseApiService: DatabaseApiService,
    private mapper: DatabaseMapperService
  ) {}

  findLiveDatabasesAndSort(): Observable<Database[]> {
    return this.findLiveDatabases().pipe(
      map((databases) => {
        databases.forEach((db) => this.sortBackgroundDatabases(db));

        const latestDatabases = databases
          .filter((d) => !d.obsolete)
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        const obsoleteDatabases = databases
          .filter((d) => d.obsolete)
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
        return latestDatabases.concat(obsoleteDatabases);
      })
    );
  }

  findLiveNonObsoleteDatabasesAndSort(): Observable<Database[]> {
    return this.findLiveDatabases().pipe(
      map((databases) => {
        databases.forEach((db) => this.sortBackgroundDatabases(db));
        return databases
          .filter((d) => !d.obsolete)
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      })
    );
  }

  private findLiveDatabases(): Observable<Database[]> {
    return this.databaseApiService.getDatabases().pipe(
      map((databases) => this.mapper.fromList(databases.results)),
      map((databases) => databases.filter((database) => database.live))
    );
  }

  private sortBackgroundDatabases(db: Database): Database {
    db.background.sort((a: Database, b: Database) => {
      const nameComparison = a.name.localeCompare(b.name);
      return nameComparison !== 0
        ? nameComparison
        : a.version.localeCompare(b.version);
    });

    return db;
  }
}
