import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@app/modules/core/services/notification.service';
import { assert } from '@app/modules/core/utils/assert-utils';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-copiable-id',
  templateUrl: './copiable-id.component.html',
  styleUrl: './copiable-id.component.scss',
})
export class CopiableIdComponent {
  @Input()
  copiableId: string | undefined;

  @Input()
  size: 'small' | 'large' = 'large';

  @Input()
  tooltipPosition: TooltipPosition = 'above';

  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
  ) {}

  copyIdToClipboard(): void {
    assert(this.copiableId !== undefined);
    this.clipboard.copy(this.copiableId);
    this.notificationService.success(
      `Copied ${this.copiableId} to the clipboard`,
    );
  }
}
