import { Component, Input } from '@angular/core';
import { Dataset } from '@app/modules/dataset/models/dataset.model';

@Component({
  selector: 'app-parent-tab[dataset]',
  templateUrl: './parent-tab.component.html',
  styleUrl: './parent-tab.component.scss',
})
export class ParentTabComponent {
  @Input()
  dataset!: Dataset;

  get usedBy(): string {
    const size = this.dataset.parents.length;
    if (size < 1) {
      return 'Not used by any dataset';
    }
    if (size == 1) {
      return 'Used by 1 dataset';
    }
    return `Used by ${size} datasets`;
  }
}
