import { FilterOption } from '@app/modules/ui/modules/filters-group/models/filters-group.model';
import { FormatUnitPipe } from '@app/modules/reference-data/modules/unit/pipes/format-unit.pipe';
import {
  ContributionType, ContributionTypeName, findContributionTypesByFamily,
} from "@app/modules/dataset/modules/contribution/model/contribution.model";


export class Method {
  id: string;
  name: string;
  position: number;
  factors: Array<MethodIndicator>;

  constructor(
    id: string,
    name: string,
    position: number,
    factors: Array<MethodIndicator>,
  ) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.factors = factors;
  }
}

export class MethodIndicator {
  readonly id: string;
  readonly name: string;
  readonly unit: string;
  readonly method: string;
  readonly groups: string[];
  readonly directContributionType?: ContributionType;

  constructor(
    id: string,
    name: string,
    unit: string,
    method: string,
    groups: string[],
    directContributionType?: ContributionType
  ) {
    this.id = id;
    this.name = name;
    this.unit = unit;
    this.method = method;
    this.groups = groups;
    this.directContributionType = directContributionType;
  }

  findContributionTypesByFamily(contributionTypeName: ContributionTypeName): Array<ContributionType> {
    return this.directContributionType !== undefined ?
      this.directContributionType.children
        .map(c => findContributionTypesByFamily(c, contributionTypeName)).flat() : []
  }

  get displayName(): string {
    const unitPipe = new FormatUnitPipe();
    return `${this.name} - ${unitPipe.transform(this.unit) ?? ''}`;
  }

  get getFilterOption(): FilterOption {
    return { key: this.name, value: this.name };
  }
}

export const METHOD_EF_3_0_1 = 'EF 3.0.1';
export const METHOD_EF_3_0_2 = 'EF 3.0.2 QUANTIS DEFAULT';
export const METHOD_QUANTIS_DEFAULT = 'EF 3.1';

export const INDICATOR_CLIMATE_CHANGE = 'Climate change';
export const INDICATOR_ACIDIFICATION = 'Acidification';
export const INDICATOR_WATER_USE = 'Water use';
